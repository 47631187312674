<template>
  <div>
    <div class="commonCard scroll-horizontal" style="display: flex; flex-direction: column">
      <div
        class="recommend-box recommendation-box relative card-radius"
        style="padding: 12px; overflow: hidden"
        :style="`background: ${backgroundColor}`"
      >
        <h2
          class="flex fs-12 dark-text fw-600"
          style="line-height: 14px"
          unselectable="on"
          :title="label1"
        >
          <span v-if="timer" style="margin-right: 3px"
            ><TimerSvg :bgColor="backgroundColor === '#EBFFE7' ? '#37BC16' : '#642C90'" /></span
          >{{ label1?.length > 20 ? label1.slice(0, 20) + ".." : label1 }}
        </h2>
        <p class="fs-10 fw-600 black-text2" style="opacity: 0.6; line-height: 24px" :title="label2">
          {{ label2?.length > 20 ? label2.slice(0, 20) + ".." : label2 }}
        </p>
        <img class="practice-image" :src="subjectIcon ? subjectIcon : randomImage" />
        <div class="flex flex-between items-baseline">
          <p class="fs-10 fw-600 black-text2" style="opacity: 0.6" :title="label3">
            {{ label3?.length > 16 ? label3.slice(0, 16) + ".." : label3 }}
          </p>
          <button
            class="yellow-btn"
            style="width: 51px; margin-right: 5px"
            @click="buttonEvent(data)"
          >
            {{ buttonName }}
          </button>
        </div>
        <div v-if="progressBar" class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`height: ${parseInt(data.gaugeValue * 100)}%`"
            v-bind:aria-valuenow="parseInt(data.gaugeValue * 100)"
            aria-valuemin="0"
            aria-valuemax="100"
            :class="[
              {
                transparent:
                  parseInt(data.gaugeValue * 100) == 0 || parseInt(data.gaugeValue * 100) == '',
              },
              { red: parseInt(data.gaugeValue * 100) <= 50 && parseInt(data.gaugeValue * 100) > 0 },
              {
                green:
                  parseInt(data.gaugeValue * 100) <= 100 && parseInt(data.gaugeValue * 100) > 50,
              },
            ]"
          ></div>
        </div>
      </div>
      <div v-if="reason" class="flex flex-between" style="margin-bottom: 2px; margin-top: 5px">
        <div
          v-if="data.reason"
          style="display: flex; justify-content: space-between; width: 100%; margin-top: 4px"
        >
          <div v-if="data.reason.Overall" class="flex">
            <OverallSvg :bgColor="data.reason.Overall.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Overall.color}`"
              >{{ data.reason.Overall ? data.reason.Overall.value : "" }}</span
            >
          </div>
          <div v-if="data.reason.Accuracy" class="flex">
            <AccuracySvg :bgColor="data.reason.Accuracy.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Accuracy.color}`"
              >{{ data.reason.Accuracy ? data.reason.Accuracy.value : "" }}</span
            >
          </div>
          <div v-if="data.reason.Performance" class="flex">
            <PerformanceSvg :bgColor="data.reason.Performance.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Performance.color}`"
              >{{ data.reason.Performance ? data.reason.Performance.value : "" }}</span
            >
          </div>
          <div v-if="data.reason.Expertise" class="flex">
            <ExpertiseSvg :bgColor="data.reason.Expertise.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Expertise.color}`"
              >{{ data.reason.Expertise ? data.reason.Expertise.value : "" }}</span
            >
          </div>
          <div v-if="data.reason.Effort" class="flex">
            <EffortSvg :bgColor="data.reason.Effort.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Effort.color}`"
              >{{ data.reason.Effort ? data.reason.Effort.value : "" }}</span
            >
          </div>
          <div v-if="data.reason.Speed" class="flex">
            <SpeedSvg :bgColor="data.reason.Speed.color" />
            <span
              class="fw-600 fs-10"
              style="line-height: 18px"
              :style="`color: ${data.reason.Speed.color}`"
              >{{ data.reason.Speed ? data.reason.Speed.value : "" }}</span
            >
          </div>
        </div>
        <div
          v-if="data.questionCount || data.duration"
          class="flex"
          style="display: flex; justify-content: space-between; width: 100%; margin-top: 4px"
        >
          <p class="black-text fs-12" style="margin-left: 10px">{{ data.questionCount }} Q</p>
          <p class="black-text fs-12" style="margin-right: 10px">{{ data.duration }}min</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiple from "../assets/Multiple.png";
import AccuracySvg from "./AccuracySvg";
import PerformanceSvg from "./PerformanceSvg";
import ExpertiseSvg from "./ExpertiseSvg";
import EffortSvg from "./EffortSvg";
import SpeedSvg from "./SpeedSvg";
import OverallSvg from "./OverallSvg";
import TimerSvg from "./TimerSvg";

export default {
  data() {
    return {
      randomImage: Multiple,
    };
  },
  components: {
    AccuracySvg,
    PerformanceSvg,
    ExpertiseSvg,
    EffortSvg,
    SpeedSvg,
    OverallSvg,
    TimerSvg,
  },
  methods: {
    buttonEvent(data) {
      this.$emit("cardData", data);
    },
  },
  props: {
    label1: {
      type: String,
      default: null,
    },
    label2: {
      type: String,
      default: null,
    },
    subjectIcon: {
      type: String,
      default: null,
    },
    buttonName: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    label3: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
    },
    progressBar: {
      type: Boolean,
      default: false,
    },
    reason: {
      type: Boolean,
      default: true,
    },
    timer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin: 0;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.dark-text {
  color: #333333 !important;
}

.light-text {
  color: #000000 !important;
  opacity: 0.3;
}

.relative {
  position: relative !important;
}

.lightPink-bgc {
  background: #ffecdb;
}

.card-radius {
  border-radius: 5px;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.commonCard {
  width: 170px;
  margin-right: 15px;
  /* margin-bottom: 15px; */
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

.recommend-box {
  width: 170px;
  height: 80px;
  border-radius: 6px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.yellow-btn {
  width: 85px;
  height: 24px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  z-index: 20;
}

.progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.recommendation-box .progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}
.black-text2 {
  color: #000000de;
}
</style>
