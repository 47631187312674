<template>
  <div>
    <div class="learn-video-main-header" :style="'background-color:' + mainHeaderColor">
      <div class="top-header">
        <div class="row">
          <div class="col l3 m4 class-section">
            <h3 class="breadcrumb-heading"><span>Learn </span>/ {{ learnTitle }}</h3>
            <h6 class="class-heading">Class</h6>
            <div class="classes-btn">
              <div v-for="className in classList" :key="className.id">
                <button
                  class="chip class-btn"
                  :class="[
                    { active: activeClass === className.id },
                    { active: defaultClassActive === className.id },
                  ]"
                  @click="selectedClass(className.id)"
                >
                  {{ className.displayName }}
                </button>
              </div>
            </div>
          </div>
          <div class="col l9 m8 subject-section">
            <h6 class="class-heading">Subject</h6>
            <div class="subject-list">
              <div
                class="subject-btn"
                v-for="(subject, index) in uniqueSubjects"
                :key="subject.subjectId"
              >
                <button
                  class="chip class-btn"
                  :class="[
                    { active: activeSubjectId === subject.subjectId },
                    { active: defaultSubjectActive[0] === subject.subjectId },
                  ]"
                  @click="selectedUniqueSubject(subject.subjectId, index + 1)"
                >
                  {{ subject.subjectName }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chapter-navbar">
        <div class="row">
          <div class="col s10 chapter-nav-content">
            <div class="hamburger" @click="showChapterList = !showChapterList">
              <span class="material-icons">{{ showChapterList ? "close" : "menu" }}</span>
            </div>
            <h4 class="chapter-name">
              {{ ConceptChapterNameChange ? ConceptChapterNameChange : showChapterName }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="chapter-list" v-if="showChapterList" v-click-outside="onClickOutside">
      <ul>
        <li
          v-for="(chapter, index) in selectedChapterList"
          :class="{
            active: ConceptChapterNameChange
              ? chapter.ChapterName === ConceptChapterNameChange
              : chapter.ChapterName === showChapterName,
          }"
          :key="index"
          class="chapter-list-name"
          :style="`border-left-color: ${mainHeaderColor} !important;`"
          @click="selectChapter(chapter)"
        >
          <span class="truncate" :title="chapter.ChapterName">{{ chapter.ChapterName }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import vClickOutside from "v-click-outside";
import MobileAPI from "../Mobileapi";

// Vue.use(vClickOutside);

export default {
  name: "LearnHeader",
  data() {
    return {
      courseId: -1,
      classList: [],
      activeClass: null,
      defaultClassActive: "",
      defaultSubjectActive: "",
      uniqueSubjects: [],
      activeSubjectId: null,
      totalChapterList: [],
      selectedChapterList: [],
      showChapterName: "",
      showChapterList: false,
      focusOutTrigger: false,
      mainHeaderColor: "#f9a825",
    };
  },
  props: {
    learnTitle: String,
    ConceptChapterNameChange: String,
  },
  created() {
    const user = this.$store.getters.user;
    // console.log("user: ", user);
    this.baseUrl = user.DomainName;
    this.showChapterName = "";
    this.$emit("userDetail", user);
    this.$store.dispatch("showLoader", true);
    MobileAPI.getStudentDetails(
      user.UserId,
      (response) => {
        const student = response.data;
        this.courseId = student.courseId;
        MobileAPI.getCourseClass(student.courseId, (resp) => {
          this.classList = resp.data;
          // console.log("Class: ", this.classList);
          this.defaultClassActive = this.classList.map((classListData) => classListData.id);
          this.defaultClassActive = this.defaultClassActive[0];
          // console.log("firsests", this.defaultClassActive);
          if (this.defaultClassActive) {
            this.uniqueSubjects = [];
            this.totalChapterList = [];
            MobileAPI.getcourseClassSubjectChapterForUser(
              user.UserId,
              {},
              (activeClassResponse) => {
                this.totalChapterList = activeClassResponse.data;
                const uniObjes = Array.from(new Set(this.totalChapterList.map((a) => a.SubjectId)));
                this.defaultSubjectActive = uniObjes;
                // console.log("chapterlist: ", this.totalChapterList);
                this.uniqueSubjects = uniObjes.map((b) => ({
                  subjectId: b,
                  subjectName: this.totalChapterList.find((s) => s.SubjectId === b).SubjectName,
                }));
                if (this.totalChapterList !== []) {
                  this.selectedChapterList = this.totalChapterList.filter(
                    (chapter) => chapter.SubjectId === this.uniqueSubjects[0].subjectId,
                  );
                  this.$emit("chapterData", this.selectedChapterList[0]);
                  this.showChapterName = this.selectedChapterList[0].ChapterName;
                }
                // console.log("total chapter: ", this.selectedChapterList);
                this.$emit("chapterList", this.selectedChapterList);
              },
            );
          }
        });
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
  },
  methods: {
    selectedClass(classId) {
      this.showChapterList = false;
      this.activeClass = classId;
      this.activeSubjectId = null;
      this.uniqueSubjects = [];
      this.defaultClassActive = "";
      this.defaultSubjectActive = "";
      this.selectedChapterList = [];
      this.showChapterName = "";
      this.totalChapterList = [];
      this.mainHeaderColor = "#f9a825";
      this.$emit("bgColor", this.mainHeaderColor);
      this.$emit("subjectIndex", 1);
      this.$emit("activeClass", this.activeClass);
      this.$store.dispatch("showLoader", true);
      MobileAPI.getcourseClassSubjectChapter(
        this.courseId,
        classId,
        (response) => {
          this.totalChapterList = response.data;
          const uniObjes = Array.from(new Set(this.totalChapterList.map((a) => a.SubjectId)));
          this.defaultSubjectActive = uniObjes;
          this.uniqueSubjects = uniObjes.map((b) => ({
            subjectId: b,
            subjectName: this.totalChapterList.find((s) => s.SubjectId === b).SubjectName,
          }));
          if (this.totalChapterList !== []) {
            this.selectedChapterList = this.totalChapterList.filter(
              (chapter) => chapter.SubjectId === this.uniqueSubjects[0].subjectId,
            );
            this.$emit("chapterData", this.selectedChapterList[0]);
            this.showChapterName = this.selectedChapterList[0].ChapterName;
          }
          this.$emit("chapterList", this.selectedChapterList);
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectedUniqueSubject(selectedSubjectId, index) {
      this.activeSubjectId = selectedSubjectId;
      this.defaultSubjectActive = "";
      this.selectedChapterList = [];
      this.showChapterName = "";
      this.mainHeaderColor = "#f9a825";
      this.showChapterList = false;
      //   console.log("second", this.defaultSubjectActive);
      //   console.log("index: ", index);
      if (index === 1) {
        this.mainHeaderColor = "#f9a825";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 2) {
        this.mainHeaderColor = "#4cb4aa";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 3) {
        this.mainHeaderColor = "#64b5f6";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 4) {
        this.mainHeaderColor = "#e47272";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 5) {
        this.mainHeaderColor = "#7885ca";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 6) {
        this.mainHeaderColor = "#ffd54f";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else if (index === 7) {
        this.mainHeaderColor = "#4ccfe0";
        this.$emit("bgColor", this.mainHeaderColor);
        this.$emit("subjectIndex", index);
      } else {
        this.mainHeaderColor = "#f9a825";
        this.$emit("bgColor", this.mainHeaderColor);
      }
      if (this.totalChapterList !== []) {
        this.selectedChapterList = this.totalChapterList.filter(
          (chapter) => chapter.SubjectId === selectedSubjectId,
        );
        this.$emit("chapterData", this.selectedChapterList[0]);
        this.showChapterName = this.selectedChapterList[0].ChapterName;
      }
      this.$emit("chapterList", this.selectedChapterList);

      //   console.log("activeChapterListIdAf: ", this.selectedChapterList);
    },
    selectChapter(chapter) {
      this.showChapterName = chapter.ChapterName;
      this.showChapterList = false;
      this.$emit("chapterData", chapter);
    },
    hamburgerTrigger() {
      //   console.log("hamburgerTrigger");
      if (this.showChapterList === false) {
        this.showChapterList = true;
      } else {
        this.showChapterList = false;
      }
    },
    onClickOutside() {
      if (this.showChapterList === true) {
        this.showChapterList = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0px !important;
}
.learn-video-main-header {
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.subject-list,
.classes-btn {
  display: flex;
  flex-wrap: wrap;
}

.top-header {
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
}

.class-section,
.subject-section {
  margin-top: 18px;
}

.subject-section {
  margin-bottom: 20px;
}

.breadcrumb-heading {
  font-size: 20px;
  color: #21201f;
  margin: 0;
  font-weight: 600;
}

.breadcrumb-heading span {
  font-size: 16px;
  text-transform: capitalize;
}

.class-heading {
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.subject-section .class-heading {
  padding-top: 21px;
}

.class-btn {
  padding: 0px 20px;
  border: none;
  cursor: pointer;
  font-weight: normal;
}

.class-section .class-btn.active {
  background-color: #584f52;
  color: white;
}

.class-section .class-btn {
  background-color: #ececec;
  color: #21201f;
  font-size: 14px;
}

.class-section .class-btn:hover {
  background-color: #584f52;
  color: #fff;
  transition: all 0.3s ease-in;
}

.subject-btn .class-btn {
  background-color: #ececec;
  color: #21201f;
}

.subject-btn .class-btn:hover {
  background-color: #f9a825;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn .class-btn.active {
  background-color: #f9a825;
  color: #fff;
}
.subject-btn:nth-child(1) .class-btn:hover {
  background-color: #f9a825;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(1) .class-btn.active {
  background-color: #f9a825;
  color: #fff;
}
.subject-btn:nth-child(2) .class-btn:hover {
  background-color: #4cb4aa;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(2) .class-btn.active {
  background-color: #4cb4aa;
  color: #fff;
}
.subject-btn:nth-child(3) .class-btn:hover {
  background-color: #64b5f6;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(3) .class-btn.active {
  background-color: #64b5f6;
  color: #fff;
}
.subject-btn:nth-child(4) .class-btn:hover {
  background-color: #e47272;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(4) .class-btn.active {
  background-color: #e47272;
  color: #fff;
}
.subject-btn:nth-child(5) .class-btn:hover {
  background-color: #7885ca;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(5) .class-btn.active {
  background-color: #7885ca;
  color: #fff;
}
.subject-btn:nth-child(6) .class-btn:hover {
  background-color: #ffd54f;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(6) .class-btn.active {
  background-color: #ffd54f;
  color: #fff;
}
.subject-btn:nth-child(7) .class-btn:hover {
  background-color: #4ccfe0;
  color: #fff;
  transition: all 0.3s ease-in;
}
.subject-btn:nth-child(7) .class-btn.active {
  background-color: #4ccfe0;
  color: #fff;
}

.learn-video-main-header.active {
  background-color: #f9a825;
}

.chapter-navbar {
  text-align: left;
  color: white;
  // margin-top: -20px;
  // height: 45px;
  height: 35px;
}

.chapter-navbar .row {
  margin-bottom: 0;
}

.chapter-navbar .hamburger {
  cursor: pointer;
  position: relative;
  top: -3px;
  height: 20px;
}

.chapter-nav-content {
  display: flex;
  position: relative;
  top: 0;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
}

.chapter-name {
  margin-left: 13px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  // top: -5px;
}

.chapter-list {
  height: 300px;
  width: 320px;
  overflow-y: scroll;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
}

.chapter-list::-webkit-scrollbar {
  width: 8px;
}

.chapter-list::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.chapter-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.chapter-list ul {
  margin: 0;
}

.chapter-list ul .chapter-list-name {
  list-style: none;
  display: block;
  color: #8f8c8c;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.chapter-list ul .chapter-list-name:hover {
  color: #584f52;
}

.chapter-list .chapter-list-name.active {
  border-left: 10px solid #f9a825;
  color: #584f52;
}
.hamburger label {
  cursor: pointer;
  color: white;
  background: antiquewhite;
  position: relative;
  position: absolute;
  width: 100%;
}
.hamburger input {
  width: 23px;
  margin-top: 5px;
  position: absolute;
}
.daily-practice {
  background-color: rgb(48, 146, 48);
  color: white;
  border: none;
  height: 30px;
  font-size: 600 !important;
}

// Responsive
@media only screen and (max-width: 600px) {
  .subject-section {
    margin-top: 0;
  }
}

@media only screen and (max-width: 992px) {
  .chapter-list {
    margin-left: 12px;
  }
}
</style>
