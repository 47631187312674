<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-if -->
<template>
  <div class="container font-open-sans">
    <br />
    <div class="navName row s12 m12">
      <span>Assignment</span>
    </div>
    <div>
      <div class="row header-row scroll-horizontal, .scroll-horizontal-bar">
        <div class="col s2 m2 fs-16 fw-600 flex items-center">
          <img :src="require(`../assets/instituteCalendar.svg`)" class="icon-bullet" /><span
            class="pl-5"
            >Date</span
          >
        </div>
        <div class="col s3 m3 fs-16 fw-600 flex items-center">
          <img :src="require(`../assets/instituteBookmark.svg`)" class="icon-bullet" /><span
            class="pl-5"
            >Description</span
          >
        </div>
        <div class="col s2 m2 fs-16 fw-600 flex items-center">
          <img :src="require(`../assets/homeworkTitle.svg`)" class="icon-bullet" /><span
            class="pl-5"
            >Type</span
          >
        </div>
        <div class="col s2 m2 fs-16 fw-600 flex items-center flex-center">
          <img :src="require(`../assets/instituteQuestion.svg`)" class="icon-bullet" /><span
            class="pl-5"
            >Questions</span
          >
        </div>
        <div class="col s3 m3 fs-16 fw-600 flex items-center flex-center">
          <img :src="require(`../assets/instituteStatus.svg`)" class="icon-bullet" /><span
            class="pl-5"
            >Status</span
          >
        </div>
      </div>
      <div class="scroll-verticle-bar scroll-bar">
        <div v-if="assignmentList.length > 0">
          <div
            v-for="(assignment, index) in assignmentList"
            :key="index"
            class="table-row row p-15-0 scroll-horizontal, .scroll-horizontal-bar"
            :class="getTestStatusText(assignment)"
          >
            <div class="col s2 nowrap">
              {{ formatDate(assignment.CreatedOn) }}
            </div>
            <div class="col s3 fw-400 fs-16">
              <span class="truncate" :title="`${assignment.Description}`">{{
                assignment.Description
              }}</span>
            </div>
            <div class="col s2 fw-600 fs-16 ta-center">
              <div v-if="assignment.PdfUrl">
                <a :href="assignment.PdfUrl" class="fileURL">File</a>
              </div>
              <div v-else>Question</div>
            </div>
            <div class="col s2 flex items-center flex-center fw-600 fs-16 color-purple">
              <div v-if="!assignment.PdfUrl">{{ assignment.TotalQuestions }}</div>
              <div v-else>-</div>
            </div>
            <div class="col s3 align-center">
              <div v-if="getTestStatusText(assignment) === 'Completed'" class="d-grid">
                <span class="fw-600 fs-16 color-green">Completed</span>
                <a class="fs-13 flex flex-center flex-align" @click="viewReport(assignment)">
                  <img :src="require(`../assets/eye.svg`)" class="eye-icon" />
                  <span class="fw-400 pl-5"> View Report</span>
                </a>
              </div>
              <div v-else-if="getTestStatusText(assignment) === 'Start'">
                <span class="fw-600 fs-16">
                  <button class="btn-btn startButton" @click="start(assignment)">Start</button>
                </span>
              </div>
              <div v-else-if="getTestStatusText(assignment) === 'Resume'">
                <a @click="resume(assignment)">
                  <span class="fw-600 fs-16 color-blue">Resume</span>
                </a>
              </div>
              <div v-else-if="getTestStatusText(assignment) === 'File'">
                <a @click="view(assignment)">
                  <span class="fw-600 fs-16">View Assignment</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-center" style="margin-bottom: 20px">
            <img
              :src="require(`../assets/no-result-found.png`)"
              alt=""
              style="width: 200px; opacity: 0.7"
            />
            <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
              No assignments available.
            </p>
          </div>
        </div>
      </div>
      <div v-if="listLength > 0" class="pagination flex flex-between d-block">
        <div>
          <span class="show-index fw-500 fs-15 ws-nowrap">
            <span>Showing </span>
            <span>{{ startPageNumber }}</span>
            <span> - </span>
            <span>{{ endPageNumber }}</span>
            <span> of </span>
            <span>{{ totalQnsList }}</span>
            <span> Assignments</span>
          </span>
        </div>
        <div class="flex flex-wrap margin-bottom-10-325" style="gap: 3px">
          <a
            @click="previousPage()"
            class="navigation-btn prev-btn"
            :class="{ disable: currentPage < 2 }"
            title="Previous"
          >
            <span class="material-icons material-symbols-outlined fs-20 pointer">west</span>
          </a>
          <a
            @click="nextPage()"
            class="navigation-btn"
            :class="{ disable: listLength < 20 && currentPage != endPageNumber }"
            title="Next"
          >
            <span class="material-icons material-symbols-outlined fs-20 pointer">east</span>
          </a>
        </div>
      </div>
      <Modal
        :show="showCampaignModal"
        @close="showCampaignModal = false"
        :showHeader="false"
        :showCloseBtn="true"
        :width="'50%'"
      >
        <template v-slot:body>
          <div>
            <div class="modal-header document-upload-header">
              <span style="font-size: 20px; padding: 5px; font-weight: 600">Assignment</span>
            </div>
            <br />
            <!-- <a :href="this.pdfURL">Click the Link to Download the Uploaded File</a> -->
            <iframe
              width="100%"
              style="height: 500px"
              :src="`data:application/pdf;base64,${encodeURI(fileContent)}`"
            ></iframe>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import Vue from "vue";
import { useToast } from "vue-toastification";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

const toast = useToast();

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("DD MMM YYYY");
//   }
//   return null;
// });

export default {
  data() {
    return {
      assignmentList: [],
      ExamSessionId: null,
      showCampaignModal: false,
      fileContent: "",
      currentPage: 1,
      listLength: 0,
      totalQnsList: 0,
      startPageNumber: 1,
      endPageNumber: 20,
      totalCount: 0,
      next: true,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  created() {
    this.getAssignments();
  },
  activated() {
    this.getAssignments();
  },
  components: {
    Modal,
  },
  methods: {
    getAssignments() {
      const user = this.$store.getters.user;
      // console.log("user: ", user);
      this.$store.dispatch("showLoader", true);
      const apiObj = { pageNo: this.currentPage };
      MobileAPI.getAssignmentTest(user.UserId, apiObj, (resp) => {
        this.assignmentList = resp.data;
        this.$store.dispatch("showLoader", false);
        if (this.next) {
          this.totalCount += this.assignmentList.length;
        }
        this.totalQnsList = this.assignmentList[0]?.TotalTest;
        if (this.totalCount === this.totalQnsList) {
          this.endPageNumber = this.totalCount;
        }
        this.listLength = this.assignmentList.length;
      });
    },
    nextPage() {
      this.next = true;
      this.currentPage += 1;
      this.startPageNumber = this.endPageNumber + 1;
      this.endPageNumber += 20;
      this.getAssignments();
    },
    previousPage() {
      this.next = false;
      this.currentPage -= 1;
      this.endPageNumber -= this.listLength;
      this.startPageNumber -= 20;
      this.getAssignments();
    },
    getTestStatusText(assignment) {
      let action = "";
      if (
        assignment.AssignmentType === "Question" ||
        assignment.AssignmentType === "Auto Assignment"
      ) {
        if (assignment.Status === "Completed") {
          action = "Completed";
        }
        if (assignment.Status === "Pause" || assignment.Status === "InProgress") {
          action = "Resume";
        }
        if (assignment.AssignmentType === "") {
          action = "File";
        }
        if (assignment.Status === null) {
          action = "Start";
        }
      }
      return action;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD MMM YYYY");
      }
      return null;
    },
    subjectiveAssignmentReport() {
      toast.info("Report will be avaible once the Assignment is Evaluated");
    },
    viewReport(assignmentData) {
      // console.log("asssss: ", assignmentData);
      this.$router.push({
        name: "TestReport",
        params: {
          testType: 4,
          testUserId: assignmentData.ExamSessionId,
        },
      });
    },
    resume(assignmentData) {
      const dateTime = new Date().toISOString();
      this.$store.dispatch("showLoader", true);
      MobileAPI.startExam(
        {
          ExamId: assignmentData.ExamId,
          UserId: assignmentData.UserId,
          StartedOn: dateTime,
          TerminalId: 0,
        },
        (response) => {
          if (response.responseCode === 200) {
            this.ExamSessionId = response.data[0].ExamSessionId;
            this.$router.push({
              name: "ConductTest",
              params: {
                testUserId: this.ExamSessionId,
                testType: 4,
              },
            });
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    view(assignmentData) {
      this.$store.dispatch("showLoader", true);
      MobileAPI.getAssignmentUrl(assignmentData.PdfAssignmentId, (response) => {
        this.pdfURL = response.data.FileURL;
        this.fileContent = response.data.FileContent;
        this.showCampaignModal = true;
        this.$store.dispatch("showLoader", false);
      });
    },
    start(assignmentData) {
      const dateTime = new Date().toISOString();
      this.$store.dispatch("showLoader", true);
      MobileAPI.startExam(
        {
          ExamId: assignmentData.ExamId,
          UserId: assignmentData.UserId,
          StartedOn: dateTime,
          TerminalId: 0,
        },
        (response) => {
          if (response.responseCode === 200) {
            this.ExamSessionId = response.data[0].ExamSessionId;
            this.$router.push({
              name: "ConductTest",
              params: { testUserId: this.ExamSessionId, testType: 4 },
            });
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
  },
};
</script>

<style scoped>
.icon-bullet {
  width: 28px;
}
.p-15-0 {
  padding: 15px 0px;
}

.navigation-btn.disable {
  pointer-events: none;
}

.navigation-btn {
  width: 30px;
  height: 30px;
  line-height: 38px;
  text-align: center;
  background-color: white;
  color: #8c8585;
  border: 1px solid #333333 !important;
  border-radius: 3px;
}

.navigation-btn.currentPage {
  border-color: #3751ff !important;
  color: #3751ff;
}
.pagination {
  padding: 13.15px;
  margin-top: 10px !important;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pagination {
  margin-bottom: 10px;
}

.scroll-verticle,
.scroll-verticle-bar {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-bar {
  max-height: calc(100vh - 100px);
}

.font-open-sans {
  font-family: "Open Sans";
  font-style: normal;
}

.align-center {
  text-align: center;
}

.d-grid {
  display: grid;
}

.pl-5 {
  padding-left: 5px;
}

.mtb {
  margin: 7.4px 0px !important;
}

.row {
  margin-bottom: 10px;
}

.Start {
  border-left: 4px solid #ff9421;
  border-radius: 4px 0px 0px 4px;
}

.Completed {
  border-left: 4px solid #16a085;
  border-radius: 4px 0px 0px 4px;
}

.expired {
  border-left: 4px solid #ef727a;
  border-radius: 4px 0px 0px 4px;
}

.File {
  border-left: 4px solid #2c83df;
  border-radius: 4px 0px 0px 4px;
}

.Resume {
  border-left: 4px solid #ff9421;
  border-radius: 4px 0px 0px 4px;
}

.color-red {
  color: #ef727a;
}
.color-blue {
  color: #2c83df;
}

.color-green {
  color: #16a085;
}

.color-orange {
  color: #ff9421;
}

.color-purple {
  color: #642c90;
}

button:focus {
  outline: none;
  background-color: #dde4eb;
}

.resume-btn {
  background: linear-gradient(90deg, #2c83df 35%, rgb(58, 137, 210) 100%);
  font-weight: 600;
  border-color: #2c83df;
  color: #ffffff;
}

.resume-btn:hover {
  cursor: pointer;
}

.startButton {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%) !important;
  font-family: "Open Sans";
  border: none;
  border-color: #fbad26;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.startButton:hover {
  cursor: pointer;
}

.btn-btn {
  width: 90px;
  height: 36px;
  border-radius: 5px;
  border: none !important;
  font-size: 14px;
}

.header-row {
  background-color: #e0e4f0;
  border-radius: 4px 4px 0px 0px;
  padding: 13.15px;
  justify-content: space-between;
}

.icon-bullet {
  width: 28px;
}

.prev-btn {
  margin-right: 20px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
  border-collapse: separate;
  border-spacing: 0 10px;
  background-color: white;
}

.navName {
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #333333;
}
.flex-align {
  align-items: center;
}

/* @media screen and (max-width: 811px) {
  .ta-center{
      text-align: center;
    }
} */
@media screen and (max-width: 810px) {
  .ta-center {
    text-align: center;
  }
  .container {
    width: 810px;
  }

  .scroll-horizontal,
  .scroll-horizontal-bar {
    display: flex;
    overflow-x: auto !important;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .scroll-horizontal::-webkit-scrollbar {
    height: 0px;
  }

  .scroll-horizontal-bar::-webkit-scrollbar {
    height: 3px;
  }

  .scroll-horizontal::-webkit-scrollbar-track,
  .scroll-horizontal-bar::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
  }

  .scroll-horizontal::-webkit-scrollbar-thumb,
  .scroll-horizontal-bar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
}
.eye-icon {
  width: 16px;
  height: 16px;
}

.ff-openSans {
  font-family: "Open Sans";
  font-style: normal;
}

.lblTest {
  margin-left: 10px;
}

table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  font-weight: 600;
}

table.qtbl thead tr {
  background-color: #e0e4f0;
  color: #333333;
}

table.qtbl thead tr td {
  border-radius: 0;
}
.scroll-bar {
  max-height: calc(100vh - 270px);
}
table.qtbl thead tr td:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtbl tbody tr {
  background-color: #ffffff;
}

.tbldata {
  color: black;
  padding: 16px;
}

.linkbutton {
  font-size: 0.875rem;
  color: #338510;
  border: 2px solid #338510;
  background: #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.heading {
  /* font-family: Open Sans;
    font-style: normal; */
  font-weight: 600;
  font-size: 20px;
  line-height: 1.563rem;
  color: #333333;
}

.linkbutton:hover,
.linkbutton:focus {
  color: #fff;
  background-color: #338510;
  border-color: #338510;
  outline: none;
  text-decoration: none;
}

table.qtbl tbody tr td:first-child {
  border-left: 7px solid #e47272;
}

.tbody tr td {
  padding: 10px 5px;
}

.tbody tr td:first-child {
  padding-left: 15px !important;
}

.buttonborder {
  width: 190px;
  border: 1px solid #fbad26;
  height: 41px;
  font-size: 15px;
}

.fileURL {
  color: #7ab7b4;
  font-size: 15px;
  transition: 0.3s all ease;
}

.fileURL:hover {
  text-decoration: underline;
}

.assignment-modal-body {
  height: 500px;
  width: 850px !important;
  /* overflow-x: scroll; */
  /* overflow-y: scroll; */
}

.document-upload-header {
  background-color: #642c90;
  color: #ffffff;
  height: 35px;
  display: flex;
  justify-content: space-between;
}
</style>
