<template>
  <div class="container">
    <div class="row main live">
      <div class="card-panel">
        <div>
          <div class="row">
            <div class="col s12 m6">
              <h5 class="light">{{ isEditableContent ? "Edit Question" : "Add Question" }}</h5>
            </div>
            <div class="col s12 m6 hide">
              <div
                class="chip coursechip"
                :class="{ selected: customQuestionType === 1 }"
                @click="
                  addQuestion(1);
                  customQuestionType = 1;
                "
                style="margin-top: 8px"
              >
                Upload Image
              </div>
              <div
                class="chip coursechip"
                :class="{ selected: customQuestionType === 2 }"
                @click="
                  addQuestion(2);
                  customQuestionType = 2;
                "
              >
                Add Text
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="customQuestion">
            <div class="row">
              <div class="col s12">
                <h6 class="light" style="margin-bottom: 20px">Courses</h6>
                <div
                  v-for="course in filterCourse"
                  :key="`ac${course.AffiliationCourseId}`"
                  class="chip coursechip"
                  :class="{ selected: course.selected }"
                  @click="selectCourse(course.AffiliationCourseId)"
                >
                  {{ course.CourseName }}
                </div>

                <h6 class="light" style="margin: 30px 0">Subjects</h6>
                <div
                  v-for="subject in filterSubject"
                  :key="`as${subject.AffiliationSubjectId}`"
                  class="chip coursechip"
                  :class="{ selected: subject.selected }"
                  @click="selectSubject(subject.AffiliationSubjectId)"
                >
                  {{ subject.SubjectName }}
                </div>
                <div class="row" v-if="!selectedCourse">
                  <div class="col s12 center-align materialize-red-text">
                    Please select a course
                  </div>
                </div>
                <h6 class="light" style="margin-top: 30px">Topics</h6>
                <div
                  v-for="topic in filterTopic"
                  :key="`t${topic.TopicId}`"
                  class="chip coursechip"
                  :class="{ selected: topic.selected === true }"
                  @click="selectTopic(topic.TopicId)"
                >
                  {{ topic.SubjectTopicName }}
                </div>
                <div class="row" v-if="!selectedSubjects.length">
                  <div class="col s12 center-align materialize-red-text">
                    Please select a subject
                  </div>
                </div>
                <div style="margin: 20px 0 10px 0px">Chapters</div>
                <div
                  v-for="chapter in chapters"
                  :key="chapter.ChapterId"
                  class="chip coursechip"
                  :class="{ selected: chapter.selected }"
                  @click="selectChapter(chapter.ChapterId)"
                >
                  {{ chapter.ChapterName }}
                </div>
                <div class="row" v-if="!selectedTopics.length">
                  <div class="col s12 center-align materialize-red-text">
                    Please select Chapters
                  </div>
                </div>
              </div>
              <div class="col s12 m8" style="margin: 20px 0 10px 0px">
                <div style="margin: 10px 0">Question Type</div>
                <!-- <div :key="`cqtpart-${selectedPartTab}-1`" class="chip coursechip" :class="{selected: parts[selectedPartTab].questionType === 1, 'disabled': parts[selectedPartTab].questionType !== 1}">Objective</div> -->
                <button
                  class="chip coursechip border-none"
                  :class="{ selected: question.isSelected }"
                  v-for="(question, index) in questionTypeList"
                  :key="index"
                  @click="onQuestiontypeSelect(index, question.typeId)"
                >
                  {{ question.typeName }}
                </button>
                <!-- <div>
                          </div> -->
              </div>
              <div class="col s12 m4">
                <div style="margin: 10px 0">Difficulty Level</div>
                <div
                  class="chip coursechip"
                  :class="{ selected: customQuestion.level === 1 }"
                  @click="customQuestion.level = 1"
                >
                  Easy
                </div>
                <div
                  class="chip coursechip"
                  :class="{ selected: customQuestion.level === 2 }"
                  @click="customQuestion.level = 2"
                >
                  Medium
                </div>
                <div
                  class="chip coursechip"
                  :class="{ selected: customQuestion.level === 3 }"
                  @click="customQuestion.level = 3"
                >
                  Difficult
                </div>
              </div>
            </div>
            <div class="row" v-if="!isEditableContent">
              <div class="col s12 m8" style="margin: 20px 0 10px 0px">
                <div style="margin: 10px 0">Input Type</div>
                <div
                  class="chip coursechip hide"
                  :class="{ selected: customQuestion.latex === 1 }"
                  @click="customQuestion.latex = 1"
                >
                  Latex
                </div>
                <div
                  class="chip coursechip"
                  :class="{ selected: customQuestion.latex === 2 }"
                  @click="customQuestion.latex = 2"
                >
                  Image
                </div>
              </div>
            </div>
            <div class="row" v-if="isEditableContent">
              <div class="col s12 m8" style="margin: 20px 0 10px 0px">
                <div style="margin: 10px 0">Input Type</div>
                <div
                  class="chip coursechip hide"
                  v-if="isEditableContent && isQueLatexType"
                  :class="{ selected: customQuestion.latex === 1 }"
                  @click="customQuestion.latex = 1"
                >
                  Latex
                </div>
                <div
                  class="chip coursechip"
                  v-if="isEditableContent && isQueImgType"
                  :class="{ selected: customQuestion.latex === 2 }"
                  @click="customQuestion.latex = 2"
                >
                  Image
                </div>
              </div>
            </div>
            <div
              class="flex items-center flex-center"
              style="background-color: red; color: white; font-style: italic"
            >
              <h6>
                Warning: Please upload an image with max dimensions of 800 x 400 pixels to ensure
                proper display.
              </h6>
            </div>
            <div v-if="customQuestionType === 1">
              <h6>Question</h6>
              <a class="right" @click="openEditor('questionText')" v-if="customQuestion.latex === 1"
                >Editor<i class="material-icons">text_fields</i></a
              >
              <div v-if="customQuestion.latex === 1">
                <textarea
                  v-if="!isEditableContent"
                  placeholder="Enter Question here..."
                  id="questionText"
                  v-model="qcontent"
                  rows="10"
                ></textarea>
                <textarea
                  v-if="isEditableContent && isQueLatexType"
                  placeholder="Enter Question here..."
                  id="questionText"
                  v-model="qcontent"
                  rows="10"
                ></textarea>
                <button @click="updateQcontent">Preview Latex</button>
              </div>
              <div v-if="customQuestion.latex === 2">
                <div v-if="!isEditableContent || (isEditableContent && !editQuestionUrl)"></div>
                <quill-editor v-model:value="QueImgContent" :options="editorOption" />
                <!-- <vue-editor v-if="!isEditableContent || (isEditableContent && !editQuestionUrl)" v-model="QueImgContent" :editor-toolbar="customToolbar" class="custom-q" style="margin-top: 20px;"></vue-editor> -->
                <!-- <vue-cropper v-if="!isEditableContent || (isEditableContent && !editQuestionUrl)" ref="cropper2" :src="QueImgContent" :viewMode="2" style="max-width: 800px; width: 100%; margin-top: 20px;" /> -->
                <a
                  v-if="isEditableContent && isQueImgType && editQuestionUrl"
                  class="btn-floating btn-small right red"
                  title="Remove"
                  @click="QuestionEdit()"
                  ><i class="material-icons">edit</i></a
                >
                <vue-cropper
                  v-if="isEditableContent && editQuestionUrl"
                  v-show="editQuestionUrl"
                  ref="cropper2"
                  :src="editQuestionUrl"
                  :viewMode="2"
                  style="max-width: 800px; width: 100%; margin-top: 20px"
                />
              </div>
              <br />

              <div v-if="customQuestion.latex === 1">
                Preview is shown here:
                <p class="custom-q pre-formatted">
                  <vue-mathjax
                    v-if="isEditableContent && isQueLatexType"
                    :formula="qcontent"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                  <vue-mathjax
                    v-if="!isEditableContent"
                    :formula="qcontent"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                </p>
              </div>
              <div v-if="customQuestion.latex === 2">
                Upload Preview is shown here:
                <vue-mathjax
                  class="custom-q pre-formatted"
                  v-if="isEditableContent && isQueImgType"
                  :formula="QueImgContent"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
                <vue-mathjax
                  class="custom-q pre-formatted"
                  v-if="!isEditableContent && QueImgContent !== ''"
                  :formula="QueImgContent"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </div>

              <div
                class="switch"
                style="margin-top: 30px"
                v-if="
                  customQuestion.questionType === 1 ||
                  customQuestion.questionType === 3 ||
                  customQuestion.questionType === 5
                "
              >
                <label>
                  <input
                    type="checkbox"
                    class="filled-in"
                    v-model="customQuestion.imageHasOptions"
                  />
                  <span class="black-text">Does image have answer options</span>
                </label>
              </div>

              <div class="row" v-if="customQuestion.questionType !== 2">
                <div class="col s12 m7">
                  <br /><br />
                  <div
                    v-if="customQuestion.questionType === 1 || customQuestion.questionType === 5"
                  >
                    <h6>Options</h6>
                    <br />
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="A"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>A</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 1"
                          v-model="customQuestion.answers[0]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="B"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>B</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 2"
                          v-model="customQuestion.answers[1]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="C"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>C</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 3"
                          v-model="customQuestion.answers[2]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="D"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>D</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 4"
                          v-model="customQuestion.answers[3]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="helper-text">Please select correct answer from the options</div>
                  </div>
                  <div v-if="customQuestion.questionType === 3">
                    <h6>Options</h6>
                    <br />
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[0]"
                            :id="customQuestion.answers[0]"
                            v-model="customQuestion.checkboxOption.A"
                          />
                          <span><b>A</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 1"
                          v-model="customQuestion.answers[0]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[1]"
                            :id="customQuestion.answers[1]"
                            v-model="customQuestion.checkboxOption.B"
                          />
                          <span><b>B</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 2"
                          v-model="customQuestion.answers[1]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[2]"
                            :id="customQuestion.answers[2]"
                            v-model="customQuestion.checkboxOption.C"
                          />
                          <span><b>C</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 3"
                          v-model="customQuestion.answers[2]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[3]"
                            :id="customQuestion.answers[3]"
                            v-model="customQuestion.checkboxOption.D"
                          />
                          <span><b>D</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 4"
                          v-model="customQuestion.answers[3]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="helper-text">Please select correct answer from the options</div>
                    <br />
                  </div>
                  <div v-if="customQuestion.questionType === 4">
                    <h6>Correct Answer</h6>
                    <br />
                    <div>
                      <input
                        type="text"
                        placeholder="Enter correct answer here..."
                        v-model="customQuestion.correctAnswer"
                        @input="validateInputValue"
                        class="browser-default"
                      /><br />
                      <span class="helper-text">&bullet; Write max upto 2 decimal</span>
                    </div>
                  </div>
                  <div v-if="customQuestion.questionType === 7">
                    <h6>Correct Answer</h6>
                    <br />
                    <div>
                      <input
                        type="text"
                        placeholder="Enter correct answer here..."
                        @input="validateMTFValue"
                        v-model="customQuestion.correctAnswer"
                        class="browser-default"
                      /><br />
                      <span class="helper-text"
                        >&bullet; Answer must be in the form "A-1,B-2,C-3,D-4," where "A,B,C,D"
                        stand for the choice index and "1,2,3,4" for the corresponding index's
                        answer.</span
                      >
                      <p v-if="!isMtFValueCorrect" class="fw-400 fs-12 red-font">
                        Format doesn't match
                      </p>
                    </div>
                  </div>
                  <div v-if="customQuestion.questionType === 8">
                    <h6>Answer</h6>
                    <br />
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="A"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>A</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 1"
                          v-model="customQuestion.answers[0]"
                          autocomplete="off"
                          class="browser-default"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="B"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>B</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 2"
                          v-model="customQuestion.answers[1]"
                          autocomplete="off"
                          class="browser-default"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />
              <div class="row" v-if="!isEditableContent">
                <div class="col s12 m8" style="margin: 20px 0 10px 0px">
                  <div style="margin: 10px 0">Input Type</div>
                  <div
                    class="chip coursechip hide"
                    :class="{ selected: customQuestion.latexSolution === 1 }"
                    @click="customQuestion.latexSolution = 1"
                  >
                    Latex
                  </div>
                  <div
                    class="chip coursechip"
                    :class="{ selected: customQuestion.latexSolution === 2 }"
                    @click="customQuestion.latexSolution = 2"
                  >
                    Image
                  </div>
                </div>
              </div>
              <div class="row" v-if="isEditableContent">
                <div class="col s12 m8" style="margin: 20px 0 10px 0px">
                  <div style="margin: 10px 0">Input Type</div>
                  <div
                    class="chip coursechip"
                    v-if="isEditableContent && isAnsLatexType"
                    :class="{ selected: customQuestion.latexSolution === 1 }"
                    @click="customQuestion.latexSolution = 1"
                  >
                    Latex
                  </div>
                  <div
                    class="chip coursechip"
                    v-if="isEditableContent && isAnsImgType"
                    :class="{ selected: customQuestion.latexSolution === 2 }"
                    @click="customQuestion.latexSolution = 2"
                  >
                    Image
                  </div>
                </div>
              </div>
              <h6 v-if="editSolutionUrl || !isEditableContent">Upload Solution (Optional)</h6>

              <a
                class="right"
                @click="openEditor('solutionText')"
                v-if="customQuestion.latexSolution === 1"
                >Editor<i class="material-icons">text_fields</i></a
              >
              <div v-if="customQuestion.latexSolution === 1">
                <textarea
                  v-if="!isEditableContent"
                  placeholder="Enter Question here..."
                  id="solutionText"
                  v-model="scontent"
                  rows="10"
                ></textarea>
                <textarea
                  v-if="isEditableContent && isAnsLatexType"
                  placeholder="Enter Question here..."
                  id="solutionText"
                  v-model="scontent"
                  rows="10"
                ></textarea>
                <button @click="updateScontent">Preview Latex</button>
              </div>
              <div v-if="customQuestion.latexSolution === 2">
                <quill-editor
                  v-if="!isEditableContent || (isEditableContent && !editSolutionUrl)"
                  v-model:value="AnsImgContent"
                  :options="editorOption"
                />
                <!-- <vue-cropper v-if="!isEditableContent || (isEditableContent && !editSolutionUrl)" ref="cropper2" :src="QueImgContent" :viewMode="2" style="max-width: 800px; width: 100%; margin-top: 20px;" /> -->
                <a
                  v-if="isEditableContent && isAnsImgType && editSolutionUrl"
                  class="btn-floating btn-small right red"
                  title="Remove"
                  @click="SolutionEdit()"
                  ><i class="material-icons">edit</i></a
                >
                <vue-cropper
                  v-if="isEditableContent && isAnsImgType"
                  v-show="editSolutionUrl"
                  ref="cropper2"
                  :src="editSolutionUrl"
                  :viewMode="2"
                  style="max-width: 800px; width: 100%; margin-top: 20px"
                />
              </div>
              <br />

              <div v-if="customQuestion.latexSolution === 1">
                Preview is shown here:
                <p class="custom-s pre-formatted">
                  <!-- <vue-mathjax v-if="isEditableContent && isAnsLatexType" :formula="scontent" :options="VueMathJaxOptions" :safe="false"/> -->
                  <vue-mathjax :formula="scontent" :options="VueMathJaxOptions" :safe="false" />
                </p>
              </div>
              <div v-if="customQuestion.latexSolution === 2 && AnsImgContent !== ''">
                Upload Preview is shown here:
                <vue-mathjax
                  class="custom-s pre-formatted"
                  v-if="isEditableContent && isAnsImgType"
                  :formula="AnsImgContent"
                  :options="VueMathJaxOptions"
                  :safe="false"
                  style="width: 200px"
                />
                <div style="width: 100%">
                  <vue-mathjax
                    class="custom-s pre-formatted"
                    v-if="!isEditableContent"
                    :formula="AnsImgContent"
                    :options="VueMathJaxOptions"
                    :safe="false"
                    style=""
                  />
                </div>
              </div>
            </div>
            <div v-if="customQuestionType === 2">
              <div class="row">
                <div class="col s12 m7">
                  <textarea
                    placeholder="Enter Question here..."
                    v-model="customQuestion.text"
                    rows="10"
                  ></textarea>
                </div>
                <div class="col s12 m5">
                  <div
                    v-if="customQuestion.questionType === 1 || customQuestion.questionType === 5"
                  >
                    <h6>Options</h6>
                    <br />
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="A"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>A</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 1"
                          v-model="customQuestion.answers[0]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="B"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>B</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 2"
                          v-model="customQuestion.answers[1]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="C"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>C</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 3"
                          v-model="customQuestion.answers[2]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="with-gap"
                            name="customOption"
                            type="radio"
                            value="D"
                            v-model="customQuestion.correctAnswer"
                          />
                          <span><b>D</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 4"
                          v-model="customQuestion.answers[3]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="helper-text">Please select correct answer from the options</div>
                  </div>
                  <div v-if="customQuestion.questionType === 3">
                    <h6>Options</h6>
                    <br />
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[0]"
                            :id="customQuestion.answers[0]"
                            v-model="customQuestion.checkboxOption.A"
                          />
                          <span><b>A</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 1"
                          v-model="customQuestion.answers[0]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[1]"
                            :id="customQuestion.answers[1]"
                            v-model="customQuestion.checkboxOption.B"
                          />
                          <span><b>B</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 2"
                          v-model="customQuestion.answers[1]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[2]"
                            :id="customQuestion.answers[2]"
                            v-model="customQuestion.checkboxOption.C"
                          />
                          <span><b>C</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 3"
                          v-model="customQuestion.answers[2]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s2">
                        <label>
                          <input
                            class="filled-in"
                            name="customOption"
                            type="checkbox"
                            :value="customQuestion.answers[3]"
                            :id="customQuestion.answers[3]"
                            v-model="customQuestion.checkboxOption.D"
                          />
                          <span><b>D</b></span>
                        </label>
                      </div>
                      <div class="col s10">
                        <input
                          type="text"
                          placeholder="Option 4"
                          v-model="customQuestion.answers[3]"
                          autocomplete="off"
                          class="browser-default"
                        />
                      </div>
                    </div>
                    <div class="helper-text">Please select correct answer from the options</div>
                    <br />
                  </div>
                  <div v-if="customQuestion.questionType === 4">
                    <h6>Correct Answer</h6>
                    <br />
                    <div>
                      <input
                        type="text"
                        placeholder="Enter correct answer here..."
                        v-model="customQuestion.correctAnswer"
                        @input="validateInputValue"
                        class="browser-default"
                      /><br />
                      <span class="helper-text">&bullet; Write max upto 2 decimal</span>
                    </div>
                  </div>
                  <div v-if="customQuestion.questionType === 7">
                    <h6>Correct Answer</h6>
                    <br />
                    <div>
                      <input
                        type="text"
                        placeholder="Enter correct answer here..."
                        @input="validateMTFValue"
                        v-model="customQuestion.correctAnswer"
                        class="browser-default"
                      /><br />
                      <span class="helper-text"
                        >&bullet; Answer should be in the form of "A-1,B-2,C-3,D-4", here "A,B,C,D"
                        denots the option index and "1,2,3,4" denots answer of that particular
                        index.</span
                      >
                      <p v-if="!isMtFValueCorrect" class="fw-400 fs-12 red-font">
                        Format doesn't match
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12 m7">
                  <small>PREVIEW</small><br /><br />
                  <div class="qpreview" ref="txtQuestionPreview">
                    <b>Question</b><br />
                    {{ customQuestion.text }}
                    <div
                      v-if="
                        customQuestion.questionType === 1 ||
                        customQuestion.questionType === 3 ||
                        customQuestion.questionType === 5
                      "
                    >
                      <br />
                      <b>Options</b><br />
                      <span
                        >A. <span>{{ customQuestion.answers[0] }}</span
                        ><br
                      /></span>
                      <span
                        >B. <span>{{ customQuestion.answers[1] }}</span
                        ><br
                      /></span>
                      <span
                        >C. <span>{{ customQuestion.answers[2] }}</span
                        ><br
                      /></span>
                      <span
                        >D. <span>{{ customQuestion.answers[3] }}</span
                        ><br
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12 m7">
                  <strong>Solution (Optional)</strong><br /><br />
                  <textarea
                    ref="txtSolution"
                    placeholder="Enter solution here..."
                    v-model="customQuestion.solutionText"
                    rows="10"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <a @click="resetData()" class="btn grey pointer" style="margin-right: 10px">Reset</a>
          <button
            class="waves-effect waves-red btn pointer"
            @click="saveCustomQuestion()"
            v-if="!isEditableContent"
          >
            SAVE
          </button>
          <button
            class="waves-effect waves-red btn pointer"
            @click="editCustomQuestion()"
            v-if="isEditableContent"
          >
            Edit
          </button>
          <!-- <button class="waves-effect waves-red btn" @click="saveCustomQuestion()" :class="{'disabled' : !validateSaveCustomQuestion}">SAVE</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import VueCropper from "vue-cropperjs";
import { quillEditor } from "vue3-quill";
import moment from "moment";
import API from "../Api";
import Mobileapi from "../Mobileapi";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        jax: ["input/TeX", "output/HTML-CSS"],
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
          processEnvironments: true,
        },
        "HTML-CSS": {
          fonts: ["TeX"],
        },
      },
      testId: 0,
      qcontent: "",
      scontent: "",
      QueImgContent: "",
      AnsImgContent: "",
      editorOption: {
        placeholder: "Add question or solution image/text",
        modules: {
          toolbar: [
            [
              {
                header: [false, 1, 2, 3, 4, 5, 6],
              },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              {
                align: "",
              },
              {
                align: "center",
              },
              {
                align: "right",
              },
              {
                align: "justify",
              },
            ],
            ["blockquote", "code-block"],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              {
                list: "check",
              },
            ],
            [
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ],
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ],
            ["image"],
            ["clean"],
          ],
        },
      },
      courses: [],
      batches: [],
      subjects: [],
      topics: [],
      chapters: [],
      totalQuestions: 10,
      totalMarks: 10,
      selectedDuration: 30,
      excludeUsedQuestions: 0,
      startDate: moment().add(15, "minutes").toDate(),
      endDate: moment().add(7, "days").toDate(),
      title: "",
      parts: [
        {
          questionType: 1,
          noOfQuestions: 10,
          marksPerQuestion: 1,
          negativeMarks: 0,
          allowedAttempts: 10,
          difficultyLevelId: 1,
          questions: [],
          questionDetails: [],
          customQuestions: [],
          subjects: [],
          topics: [],
          chapters: [],
        },
      ],
      questionGeneration: 0,
      selectedPartTab: 0,
      selectedTopicForQs: null,
      selectedSubjectForQs: null,
      selectedChapterForQs: null,
      showAddQuestionModal: false,
      customQuestionType: 1,
      customQuestion: {
        questionType: 1,
        level: 1,
        latex: 2,
        latexSolution: 2,
        imageHasOptions: false,
        text: "",
        answers: [],
        correctAnswer: "",
        checkboxOption: {
          A: false,
          B: false,
          C: false,
          D: false,
        },
        solutionText: "",
      },
      imgSrc: "",
      imgSrc2: "",
      affiliationId: 0,
      EditAffiliationCourseId: null,
      AffiliationCourseId: null,
      editSubjectId: null,
      editTopicId: null,
      editChapterId: null,
      editQuestionId: null,
      userDetails: [],
      editQuestionUrl: null,
      editSolutionUrl: null,
      isEditableContent: false,
      isEditable: false,
      isQueLatexType: false,
      isQueImgType: false,
      isAnsLatexType: false,
      isAnsImgType: false,

      //   new variables
      questionTypeList: [
        {
          typeId: 1,
          typeName: "Objective",
          isSelected: true,
        },
        {
          typeId: 2,
          typeName: "Subjective",
          isSelected: false,
        },
        {
          typeId: 3,
          typeName: "Multiple Choice",
          isSelected: false,
        },
        {
          typeId: 4,
          typeName: "Numerical",
          isSelected: false,
        },
        {
          typeId: 5,
          typeName: "Assertion and Reason",
          isSelected: false,
        },
        // {
        //   typeId: 6,
        //   typeName: "Comprehension",
        //   isSelected: false,
        // },
        {
          typeId: 7,
          typeName: "MatchTheColumn",
          isSelected: false,
        },
        {
          typeId: 8,
          typeName: "True False",
          isSelected: false,
        },
      ],
      selectedQuestionTypeId: 1,
      isMtFValueCorrect: true,
    };
  },
  activated() {
    this.userDetails = this.$store.getters.user;
    if (Number(this.$route.params.editQuestionId)) {
      this.editQuestionId = Number(this.$route.params.editQuestionId);
      this.EditAffiliationCourseId = Number(this.$route.params.editCourseId);
      this.editSubjectId = Number(this.$route.params.editSubjectId);
      this.editTopicId = JSON.parse(this.$route.params.editTopicId);
      this.editChapterId = JSON.parse(this.$route.params.editChapterId);
      this.$store.dispatch("showLoader", true);
      API.getInstituteTestMeta(
        {},
        (response1) => {
          this.courses = response1.courses.map((obj) => ({
            ...obj,
            selected: obj.AffiliationCourseId === this.EditAffiliationCourseId,
          }));

          API.getInstituteTestMeta(
            {
              affiliationCourseId: this.selectedCourse.AffiliationCourseId,
              subjects: this.editSubjectId,
              topics: this.editTopicId.map((obj) => obj.TopicId).join(","),
            },
            (response2) => {
              this.subjects = response2.subjects.map((obj) =>
                obj.SubjectId === this.editSubjectId
                  ? {
                      ...obj,
                      selected: true,
                    }
                  : {
                      ...obj,
                      selected: false,
                    },
              );
              this.topics = response2.topics.map((obj) => ({
                ...obj,
                selected: this.editTopicId.some((item2) => obj.TopicId === item2.TopicId),
              }));
              this.chapters = response2.chapters.map((obj) => ({
                ...obj,
                selected: this.editChapterId.some((item2) => obj.ChapterId === item2.ChapterId),
              }));
              this.$store.dispatch("showLoader", false);
              // const topic = (this.topics.map((item) => this.editTopicId.map((item2) => item.TopicId === item2.TopicId))).join();
              // this.refinechapters();
              // this.batches = response.batches.map((obj) => ({
              //   ...obj,
              //   selected: selectedBatches.includes(obj.Id),
              // }));
              // if (itres.excludeUsedQuestions) {
              //   this.excludeUsedQuestions = 1;
              // } else {
              //   this.excludeUsedQuestions = 0;
              // }
              // this.totalQuestions = itres.totalQuestions;
              // this.totalMarks = itres.totalMarks;
              // this.selectedDuration = itres.duration;
              // this.startDate = moment(itres.startDateTime, "DD-MM-YYYY h:mma").toDate();
              // this.endDate = moment(itres.endDateTime, "DD-MM-YYYY h:mma").toDate();
              // this.$nextTick(() => {
              //   this.title = itres.title;
              // });
              // this.parts = itres.parts;
              // this.questionGeneration = 1;
              this.editQuestion(this.editQuestionId);
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    } else {
      API.getInstituteTestMeta(
        {},
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.courses = response.courses.map((obj) => ({
            ...obj,
            selected: false,
          }));
          this.batches = response.batches.map((obj) => ({
            ...obj,
            selected: false,
          }));
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
      API.getInstituteTest(
        {
          testId: this.testId,
        },
        (itres) => {
          this.$store.dispatch("showLoader", false);

          this.courses = this.courses.map((obj) =>
            obj.AffiliationCourseId === itres.AffiliationCourseId
              ? {
                  ...obj,
                  selected: true,
                }
              : {
                  ...obj,
                  selected: false,
                },
          );

          const selectedSubjects = itres.subjects
            .split(",")
            .filter((x) => x)
            .map(Number);
          const selectedTopics = itres.topics
            .split(",")
            .filter((x) => x)
            .map(Number);
          const selectedChapters = itres.chapters
            .split(",")
            .filter((x) => x)
            .map(Number);
          const selectedBatches = itres.batches
            .split(",")
            .filter((x) => x)
            .map(Number);

          this.$store.dispatch("showLoader", true);
          API.getInstituteTestMeta(
            {
              affiliationCourseId: itres.affiliationCourseId,
              subjects: itres.subjects,
              topics: itres.topics,
            },
            (response) => {
              this.$store.dispatch("showLoader", false);

              this.subjects = response.subjects.map((obj) => ({
                ...obj,
                selected: selectedSubjects.includes(obj.SubjectId),
              }));
              this.topics = response.topics.map((obj) => ({
                ...obj,
                selected: selectedTopics.includes(obj.TopicId),
              }));
              this.chapters = response.chapters.map((obj) => ({
                ...obj,
                selected: selectedChapters.includes(obj.ChapterId),
              }));
              this.refinechapters();
              this.batches = response.batches.map((obj) => ({
                ...obj,
                selected: selectedBatches.includes(obj.Id),
              }));
              if (itres.excludeUsedQuestions) {
                this.excludeUsedQuestions = 1;
              } else {
                this.excludeUsedQuestions = 0;
              }
              this.totalQuestions = itres.totalQuestions;
              this.totalMarks = itres.totalMarks;
              this.selectedDuration = itres.duration;
              this.startDate = moment(itres.startDateTime, "DD-MM-YYYY h:mma").toDate();
              this.endDate = moment(itres.endDateTime, "DD-MM-YYYY h:mma").toDate();
              this.$nextTick(() => {
                this.title = itres.title;
              });
              this.parts = itres.parts;
              this.questionGeneration = 1;
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    }
  },
  components: {
    // "vue-mathjax": VueMathjax,
    // Modal,
    VueCropper,
    quillEditor,
  },
  computed: {
    filterCourse() {
      return [...new Map(this.courses.map((item) => [item.CourseName, item])).values()];
    },
    filterSubject() {
      return [...new Map(this.subjects.map((item) => [item.SubjectName, item])).values()];
    },
    filterTopic() {
      return [...new Map(this.topics.map((item) => [item.SubjectTopicName, item])).values()];
    },
    selectedCourse() {
      return this.courses.find((obj) => obj.selected);
    },
    selectedSubjects() {
      return this.subjects.filter((obj) => obj.selected);
    },
    selectedTopics() {
      return this.topics.filter((obj) => obj.selected);
    },
    selectedChapters() {
      return this.chapters.filter((obj) => obj.selected);
    },
    selectedBatches() {
      return this.batches.filter((obj) => obj.selected);
    },
    validateSaveCustomQuestion() {
      if (!this.selectedSubjectForQs || !this.selectedTopicForQs || !this.selectedChapterForQs) {
        return false;
      }
      if (this.customQuestionType === 1 && this.qcontent) {
        if (
          (this.customQuestion.questionType === 1 ||
            this.customQuestion.questionType === 3 ||
            this.customQuestion.questionType === 5) &&
          this.customQuestion.correctAnswer
        ) {
          if (!this.customQuestion.imageHasOptions) {
            if (this.customQuestion.answers.length !== 4) {
              return false;
            }
            for (let i = 0; i < 4; i += 1) {
              if (this.customQuestion.answers[i].length === 0) {
                return false;
              }
            }
          }
          return true;
        }
        if (this.customQuestion.questionType === 2) {
          return true;
        }
        if (this.customQuestion.questionType === 4 && this.customQuestion.correctAnswer) {
          return true;
        }
      }
      if (this.customQuestionType === 2 && this.customQuestion.text) {
        if (
          (this.customQuestion.questionType === 1 ||
            this.customQuestion.questionType === 3 ||
            this.customQuestion.questionType === 5) &&
          this.customQuestion.correctAnswer &&
          this.customQuestion.answers.length === 4
        ) {
          for (let i = 0; i < 4; i += 1) {
            if (this.customQuestion.answers[i].length === 0) {
              return false;
            }
          }
          return true;
        }
        if (this.customQuestion.questionType === 2) {
          return true;
        }
        if (this.customQuestion.questionType === 4 && this.customQuestion.correctAnswer) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    startDate(newVal) {
      this.title = `${this.selectedCourse.CourseName}: ${this.selectedSubjects
        .map((obj) => obj.SubjectName)
        .join("-")} - ${moment(newVal).format("D MMM")}, ${this.selectedDuration}mins`;
    },
    selectedDuration(newVal) {
      this.title = `${this.selectedCourse.CourseName}: ${this.selectedSubjects
        .map((obj) => obj.SubjectName)
        .join("-")} - ${moment(this.startDate).format("D MMM")}, ${newVal}mins`;
    },
    customQuestion: {
      handler(newData) {
        if (newData.questionType === 3) {
          this.customQuestion.correctAnswer = Object.entries(newData.checkboxOption).reduce(
            (ans, [key, val]) => ans + (val ? key : ""),
            "",
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    // new methods
    onQuestiontypeSelect(index, typeId) {
      this.questionTypeList.forEach((e) => {
        e.isSelected = false;
      });
      this.selectedQuestionTypeId = Number(typeId);
      this.customQuestion.questionType = Number(typeId);
      this.questionTypeList[index].isSelected = true;
      if (typeId === 8) {
        this.customQuestion.answers = ["True", "False"];
      } else {
        this.customQuestion.answers = [];
      }
      this.customQuestion.correctAnswer = "";
      this.customQuestion.checkboxOption.A = false;
      this.customQuestion.checkboxOption.B = false;
      this.customQuestion.checkboxOption.C = false;
      this.customQuestion.checkboxOption.D = false;
      this.customQuestion.imageHasOptions = false;
    },
    validateInputValue() {
      const decimalRegex = /^-?\d*(\.\d{0,2})?$/;
      const stringAnswer = this.customQuestion.correctAnswer.toString();
      if (
        !decimalRegex.test(stringAnswer) ||
        (stringAnswer.includes("-") && stringAnswer.indexOf("-") !== 0)
      ) {
        this.customQuestion.correctAnswer = stringAnswer.substring(0, stringAnswer.length - 1);
      }
    },
    validateMTFValue() {
      this.isMtFValueCorrect = true;
      const decimalRegex = /^([A-Z]-[0-9](,[A-Z]-[0-9]){0,4})$/;
      // const decimalRegex = /^[A-Z]-[1-4](,[A-Z]-[1-4]){3}$/;
      const stringAnswer = this.customQuestion.correctAnswer.toString();
      if (!decimalRegex.test(stringAnswer)) {
        // this.customQuestion.correctAnswer = stringAnswer.substring(0, stringAnswer.length - 1);
        this.isMtFValueCorrect = false;
      }
    },

    updateQcontent() {
      const input = document.getElementById("questionText");
      this.qcontent = input.value;
    },
    updateScontent() {
      const input = document.getElementById("solutionText");
      this.scontent = input.value;
    },
    openEditor(mathTextInput11) {
      const win = window.open(
        `../VisualMathEditor/VisualMathEditor.html?runlocal&codeType=Latex&encloseAllFormula=true&saveOptionInCookies=true&textarea=${mathTextInput11}`,
        "VisualMathEditor",
        " height=580,width=780,top=100,left=100,status=no,toolbar=no,menubar=no,location=no,resizable=no,scrollbars=no,modal=no,dependable=no",
      );
      win.focus();
    },
    selectCourse(AffiliationCourseId) {
      this.subjects = [];
      this.topics = [];
      this.chapters = [];
      this.selectedTopicForQs = null;
      this.selectedSubjectForQs = null;
      this.selectedChapterForQs = null;
      this.AffiliationCourseId = null;
      this.courses = this.courses.map((obj) =>
        obj.AffiliationCourseId === AffiliationCourseId
          ? {
              ...obj,
              selected: true,
            }
          : {
              ...obj,
              selected: false,
            },
      );
      this.$store.dispatch("showLoader", true);
      this.AffiliationCourseId = AffiliationCourseId;
      API.getInstituteTestMeta(
        {
          affiliationCourseId: AffiliationCourseId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.subjects = response.subjects.map((obj) => ({
            ...obj,
            selected: false,
          }));
          this.batches = response.batches.map((obj) => ({
            ...obj,
            selected: false,
          }));
          this.chapters = [];
          this.topics = [];
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectSubject(AffiliationSubjectId) {
      this.topics = [];
      this.chapters = [];
      this.selectedSubjectForQs = null;
      this.selectedChapterForQs = null;
      this.selectedTopicForQs = null;
      // const subject = this.subjects.find((obj) => obj.AffiliationSubjectId === AffiliationSubjectId);
      // subject.selected = !subject.selected;

      this.subjects = this.subjects.map((obj) =>
        obj.AffiliationSubjectId === AffiliationSubjectId
          ? {
              ...obj,
              selected: true,
            }
          : {
              ...obj,
              selected: false,
            },
      );
      const subject = this.subjects.find(
        (obj) => obj.AffiliationSubjectId === AffiliationSubjectId,
      );
      this.selectedSubjectForQs = subject.SubjectId;
      const selectedSubjectsStr = this.selectedSubjects.map((obj) => obj.SubjectId).join(",");
      const selectedTopicsStr = this.selectedTopics
        .filter((t) => this.selectedSubjects.findIndex((s) => s.SubjectId === t.SubjectId) >= 0)
        .map((obj) => obj.TopicId)
        .join(",");

      this.$store.dispatch("showLoader", true);
      API.getInstituteTestMeta(
        {
          affiliationCourseId: this.selectedCourse.AffiliationCourseId,
          subjects: selectedSubjectsStr,
          topics: selectedTopicsStr,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.topics = response.topics.map((obj) => ({
            ...obj,
            selected:
              this.selectedTopics.findIndex(
                (t) => t.TopicId === obj.TopicId && t.SubjectId === obj.SubjectId,
              ) >= 0,
          }));
          this.chapters = response.chapters.map((obj) => ({
            ...obj,
            selected:
              this.selectedChapters.findIndex(
                (t) =>
                  t.ChapterId === obj.ChapterId &&
                  t.SubjectId === obj.SubjectId &&
                  t.TopicId === obj.TopicId,
              ) >= 0,
          }));
          this.refinechapters();
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );

      this.title = `${this.selectedCourse.CourseName}: ${this.selectedSubjects
        .map((obj) => obj.SubjectName)
        .join("-")} - ${moment(this.startDate).format("D MMM")}, ${this.selectedDuration}mins`;
    },
    selectTopic(topicId) {
      this.chapters = [];
      this.selectedChapterForQs = null;
      this.selectedTopicForQs = null;
      this.topics = this.topics.map((obj) =>
        obj.TopicId === topicId
          ? {
              ...obj,
              selected: true,
            }
          : {
              ...obj,
              selected: false,
            },
      );
      this.selectedTopicForQs = topicId;
      this.$store.dispatch("showLoader", true);
      Mobileapi.getListOfChapters({ TopicId: topicId }, (resp) => {
        this.chapters = resp.data;
        this.$store.dispatch("showLoader", false);
      });
    },
    selectAllTopicsAndChapters(event) {
      if (event.target.checked) {
        this.topics = this.topics.map((obj) => ({
          ...obj,
          selected: true,
        }));
        const selectedSubjectsStr = this.selectedSubjects.map((obj) => obj.SubjectId).join(",");
        const selectedTopicsStr = this.selectedTopics.map((obj) => obj.TopicId).join(",");
        this.$store.dispatch("showLoader", true);
        API.getInstituteTestMeta(
          {
            affiliationCourseId: this.selectedCourse.AffiliationCourseId,
            subjects: selectedSubjectsStr,
            topics: selectedTopicsStr,
          },
          (response) => {
            this.$store.dispatch("showLoader", false);
            this.chapters = response.chapters.map((obj) => ({
              ...obj,
              selected: true,
            }));
            this.topics = this.topics.map((obj) => ({
              ...obj,
              selected: true,
            }));
            this.refinechapters();
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      } else {
        this.topics = this.topics.map((obj) => ({
          ...obj,
          selected: false,
        }));
        this.chapters = [];
      }
    },
    selectChapter(chapterId) {
      this.selectedChapterForQs = null;
      // const chapter = this.chapters.find((obj) => obj.ChapterId === chapterId);
      // chapter.selected = !chapter.selected;
      this.chapters = this.chapters.map((obj) =>
        obj.ChapterId === chapterId
          ? {
              ...obj,
              selected: true,
            }
          : {
              ...obj,
              selected: false,
            },
      );
      this.selectedChapterForQs = chapterId;

      // this.selectedTopicForQs = chapter.TopicId;
    },
    resetData() {
      window.location.reload();
    },
    addQuestion(quesType) {
      this.customQuestionType = quesType;
      this.customQuestion.questionType = this.selectedQuestionTypeId;
      this.customQuestion.level = 1;
      this.customQuestion.imageHasOptions = true;
      this.customQuestion.text = "";
      this.customQuestion.answers = [];
      this.customQuestion.checkboxOption.A = false;
      this.customQuestion.checkboxOption.B = false;
      this.customQuestion.checkboxOption.C = false;
      this.customQuestion.checkboxOption.D = false;
      this.customQuestion.solutionText = "";
      this.customQuestion.correctAnswer = "";
      this.imgSrc = "";
      this.imgSrc2 = "";
      this.qcontent = "";
      this.scontent = "";

      // this.selectedSubjectForQs = 0;
      // this.selectedTopicForQs = 0;
      // this.selectedChapterForQs = 0;
    },
    refinechapters() {
      for (let i = 0; i < this.chapters.length; i += 1) {
        if (this.chapters[i].AvailableQuestionTypes === null) {
          this.chapters.splice(i, 1);
        }
      }
    },
    selectImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        if (!input.files[0].type.startsWith("image/")) {
          window.M.toast({
            html: "Please select only image, JPG or PNG",
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imgSrc = e.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("green")) {
        event.currentTarget.classList.remove("grey");
        event.currentTarget.classList.add("green");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("grey");
      event.currentTarget.classList.remove("green");
    },
    drop(event) {
      event.preventDefault();
      // this.$refs.file.files = event.dataTransfer.files;

      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imgSrc = e.target.result;
          this.$refs.cropper.replace(e.target.result);
        };
        reader.readAsDataURL(event.dataTransfer.files[0]);
      }

      // Clean up
      event.currentTarget.classList.add("grey");
      event.currentTarget.classList.remove("green");
    },
    selectImage2(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        if (!input.files[0].type.startsWith("image/")) {
          window.M.toast({
            html: "Please select only image, JPG or PNG",
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imgSrc2 = e.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper2.replace(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    drop2(event) {
      event.preventDefault();

      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imgSrc2 = e.target.result;
          this.$refs.cropper2.replace(e.target.result);
        };
        reader.readAsDataURL(event.dataTransfer.files[0]);
      }

      // Clean up
      event.currentTarget.classList.add("grey");
      event.currentTarget.classList.remove("green");
    },
    saveCustomQuestion() {
      // if (this.customQuestionType === 1) {
      const quesImgData = "";
      const solutionImgData = "";
      this.customQuestion.answers = this.customQuestion.answers.filter(
        (val) => val.trim() !== "" && val !== null && val !== undefined,
      );
      const data = {
        questionLatex: this.qcontent,
        solutionLatex: this.scontent,
        quesImgData,
        solutionImgData,
        questionType: this.customQuestion.questionType,
        subject: this.selectedSubjectForQs,
        topic: this.selectedTopicForQs,
        chapter: this.selectedChapterForQs,
        answers: this.customQuestion.answers,
        correctAnswer: this.customQuestion.correctAnswer,
        imageHasOptions: this.customQuestion.imageHasOptions,
        level: this.customQuestion.level === 3 ? 2 : this.customQuestion.level,
      };
      let el1 = "";
      let el2 = "";
      if (this.customQuestion.latex === 2 || this.customQuestion.latex === 1) {
        el1 = document.querySelector(".custom-q");
      } else {
        el1 = document.querySelector(".custom-q .ql-container");
      }
      if (this.customQuestion.latexSolution === 2 || this.customQuestion.latexSolution === 1) {
        el2 = document.querySelector(".custom-s");
      } else {
        el2 = document.querySelector(".custom-s .ql-container");
      }

      // const el2 = document.querySelector(".custom-s .ql-container");
      const options = {
        type: "dataURL",
      };
      if (!this.AffiliationCourseId) {
        window.M.toast({
          html: "Please select course",
        });
        return;
      }
      if (!this.selectedSubjectForQs) {
        window.M.toast({
          html: "Please select subject",
        });
        return;
      }
      if (!this.selectedTopicForQs) {
        window.M.toast({
          html: "Please select topic",
        });
        return;
      }
      if (!this.selectedChapterForQs) {
        window.M.toast({
          html: "Please select chapter",
        });
        return;
      }
      if (this.QueImgContent.trim() === "") {
        window.M.toast({
          html: "Please add question",
        });
        return;
      }
      if (!this.isMtFValueCorrect && this.customQuestion.questionType === 7) {
        window.M.toast({
          html: "Answer should be in the form of 'A-1,B-2,C-3,D-4'",
        });
        return;
      }
      if (
        !this.customQuestion.imageHasOptions &&
        this.customQuestion.answers.length < 4 &&
        this.customQuestion.questionType !== 4 &&
        this.customQuestion.questionType !== 7 &&
        this.customQuestion.questionType !== 2 &&
        this.customQuestion.questionType !== 8
      ) {
        window.M.toast({
          html: "Please add all the answer options.",
        });
        return;
      }
      if (
        this.customQuestion.correctAnswer === "" &&
        this.customQuestion.questionType !== 4 &&
        this.customQuestion.questionType !== 3 &&
        this.customQuestion.questionType !== 7 &&
        this.customQuestion.questionType !== 2
      ) {
        window.M.toast({
          html: "Please select the correct answer.",
        });
        return;
      }
      if (
        this.customQuestion.correctAnswer === "" &&
        (this.customQuestion.questionType === 4 ||
          this.customQuestion.questionType === 7 ||
          this.customQuestion.questionType === 8)
      ) {
        window.M.toast({
          html: "Please add the correct answer.",
        });
        return;
      }
      if (
        this.customQuestion.questionType === 3 &&
        !Object.values(this.customQuestion.checkboxOption).some((value) => value === true)
      ) {
        window.M.toast({
          html: "Please select the correct answer.",
        });
        return;
      }
      if (
        this.AffiliationCourseId &&
        this.selectedSubjectForQs &&
        this.selectedTopicForQs &&
        this.selectedChapterForQs
      ) {
        html2canvas(el1, options).then((canvas) => {
          data.quesImgData = canvas.toDataURL("image/jpeg", 0.8);
          if (this.AnsImgContent) {
            html2canvas(el2, options).then((canvas2) => {
              data.solutionImgData = canvas2.toDataURL("image/jpeg", 0.8);
              this.$store.dispatch("showLoader", true);
              API.addInstituteTestQuestion(
                data,
                (response) => {
                  this.$store.dispatch("showLoader", false);
                  if (response.errors.length) {
                    window.M.toast({
                      html: response.errors[0],
                    });
                  } else {
                    this.showAddQuestionModal = false;
                    window.M.toast({
                      html: "Question added to test",
                    });
                    // window.location.reload();
                    // this.parts[this.selectedPartTab].questions.push(response.question.QuestionId);
                    // this.parts[this.selectedPartTab].customQuestions.push(response.question);
                  }
                },
                () => {
                  this.$store.dispatch("showLoader", false);
                },
              );
            });
          } else {
            this.$store.dispatch("showLoader", true);
            API.addInstituteTestQuestion(
              data,
              (response) => {
                this.$store.dispatch("showLoader", false);
                if (response.errors.length) {
                  window.M.toast({
                    html: response.errors[0],
                  });
                } else {
                  this.showAddQuestionModal = false;
                  window.M.toast({
                    html: "Question added to test",
                  });
                  // window.location.reload();
                  // this.parts[this.selectedPartTab].questions.push(response.question.QuestionId);
                  // this.parts[this.selectedPartTab].customQuestions.push(response.question);
                }
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
          }
          this.courses.forEach((e) => {
            e.selected = false;
          });
          //       selectedCourse() {
          //   return this.courses.find((obj) => obj.selected);
          // },
          // selectedSubjects() {
          //   return this.subjects.filter((obj) => obj.selected);
          // },
          // selectedTopics() {
          //   return this.topics.filter((obj) => obj.selected);
          // },
          // selectedChapters() {
          //   return this.chapters.filter((obj) => obj.selected);
          // },
          // selectedBatches() {
          //   return this.batches.filter((obj) => obj.selected);
          // },
          this.AffiliationCourseId = null;
          this.selectedTopicForQs = null;
          this.selectedSubjectForQs = null;
          this.selectedChapterForQs = null;
          this.selectedCourse = "";
          this.customQuestion.correctAnswer = "";
          this.customQuestion.imageHasOptions = "";
          this.qcontent = "";
          this.scontent = "";
          this.quesImgData = "";
          this.solutionImgData = "";
          this.QueImgContent = "";
          this.AnsImgContent = "";
          this.subjects = [];
          this.topics = [];
          this.chapters = [];
          this.questionTypeList.forEach((e) => {
            e.isSelected = false;
          });
          this.questionTypeList[0].isSelected = true;
          this.customQuestion = {
            questionType: 1,
            level: 1,
            latex: 2,
            latexSolution: 2,
            imageHasOptions: false,
            text: "",
            answers: [],
            correctAnswer: "",
            checkboxOption: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            solutionText: "",
          };
        });
      }
    },
    editCustomQuestion() {
      this.$store.dispatch("showLoader", true);
      const questionDiagramURL = "";
      const fullSolutionURL = "";
      this.customQuestion.answers = this.customQuestion.answers.filter(
        (val) => val.trim() !== "" && val !== null && val !== undefined,
      );
      const data = {
        // questionLatex: this.qcontent,
        // solutionLatex: this.scontent,
        // quesImgData,
        // questionType: this.customQuestion.questionType,
        // subject: this.selectedSubjectForQs,
        // topic: this.selectedTopicForQs,
        // chapter: this.selectedChapterForQs,
        // answers: this.customQuestion.answers,
        // correctAnswer: this.customQuestion.correctAnswer,
        // imageHasOptions: this.customQuestion.imageHasOptions,
        // level: this.customQuestion.level === 3 ? 2 : this.customQuestion.level,

        questionId: this.editQuestionId,
        qlatex: this.qcontent,
        questionDiagramURL,
        optionA: this.customQuestion.answers[0],
        optionB: this.customQuestion.answers[1],
        optionC: this.customQuestion.answers[2],
        optionD: this.customQuestion.answers[3],
        answerOption: this.customQuestion.correctAnswer,
        fullSolutionURL,
        solLatex: this.scontent,
      };
      let el1 = "";
      let el2 = "";
      if (this.customQuestion.latex === 2 || this.customQuestion.latex === 1) {
        el1 = document.querySelector(".custom-q");
      } else {
        el1 = document.querySelector(".custom-q .ql-container");
      }
      if (this.customQuestion.latexSolution === 2 || this.customQuestion.latexSolution === 1) {
        el2 = document.querySelector(".custom-s");
      } else {
        el2 = document.querySelector(".custom-s .ql-container");
      }

      // const el2 = document.querySelector(".custom-s .ql-container");
      const options = {
        type: "dataURL",
      };
      html2canvas(el1, options).then((canvas) => {
        data.questionDiagramURL = canvas.toDataURL("image/jpeg", 0.8);

        if (this.AnsImgContent) {
          html2canvas(el2, options).then((canvas2) => {
            data.fullSolutionURL = canvas2.toDataURL("image/jpeg", 0.8);
            this.$store.dispatch("showLoader", true);
            Mobileapi.updateCustomQuestion(
              data,
              (response) => {
                this.$store.dispatch("showLoader", false);
                if (response.errors.length) {
                  window.M.toast({
                    html: response.errors[0],
                  });
                } else {
                  this.showAddQuestionModal = false;
                  window.M.toast({
                    html: "Question updated to test",
                  });
                  // this.parts[this.selectedPartTab].questions.push(response.question.QuestionId);
                  // this.parts[this.selectedPartTab].customQuestions.push(response.question);
                }
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
          });
        } else {
          this.$store.dispatch("showLoader", true);
          Mobileapi.updateCustomQuestion(
            data,
            (response) => {
              this.$store.dispatch("showLoader", false);
              if (response.errors.length) {
                window.M.toast({
                  html: response.errors[0],
                });
              } else {
                this.showAddQuestionModal = false;
                window.M.toast({
                  html: "Question updated to test",
                });
                // this.parts[this.selectedPartTab].questions.push(response.question.QuestionId);
                // this.parts[this.selectedPartTab].customQuestions.push(response.question);
              }
            },
            () => {
              this.$store.dispatch("showLoader", false);
            },
          );
        }
      });
    },
    QuestionEdit() {
      this.editQuestionUrl = "";
      this.editSolutionUrl = "";
      this.qcontent = "";
      this.customQuestion.correctAnswer = "";
      this.customQuestion.answers[0] = "";
      this.customQuestion.answers[1] = "";
      this.customQuestion.answers[2] = "";
      this.customQuestion.answers[3] = "";
    },
    SolutionEdit() {
      this.editQuestionUrl = "";
      this.editSolutionUrl = "";
      this.scontent = "";
    },
    editQuestion(editQuestionId) {
      this.isEditableContent = true;
      this.isEditable = true;
      Mobileapi.getEntireDataOfQuestion(
        { QuestionId: editQuestionId, UserId: this.userDetails.UserId },
        (response) => {
          const responseData = response.data;
          this.editQuestionUrl = responseData.QuestionDiagramURL;
          this.editSolutionUrl = responseData.FullSolutionURL;
          this.qcontent = responseData.Q_LATEX;
          this.scontent = responseData.Sol_LATEX;
          this.customQuestion.correctAnswer = responseData.AnswerOption;
          this.customQuestion.answers[0] = responseData.AnswerOption_A;
          this.customQuestion.answers[1] = responseData.AnswerOption_B;
          this.customQuestion.answers[2] = responseData.AnswerOption_C;
          this.customQuestion.answers[3] = responseData.AnswerOption_D;
          // this.customQuestionType = responseData.QuestionTypeId;
          if (this.qcontent) {
            this.isQueLatexType = true;
            this.isQueImgType = false;
            this.customQuestion.latex = 1;
          } else {
            this.isQueImgType = true;
            this.isQueLatexType = false;
            this.customQuestion.latex = 2;
            // this.QueImgContent = responseData.QuestionDiagramURL;
          }
          if (this.scontent) {
            this.isAnsImgType = false;
            this.isAnsLatexType = true;
            this.customQuestion.latexSolution = 1;
          } else {
            this.isAnsImgType = true;
            this.isAnsLatexType = false;
            this.customQuestion.latexSolution = 2;
            // this.AnsImgContent = responseData.FullSolutionURL;
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  padding: 16px;
}
.red-font {
  color: #f44336;
}

.create-test .collapsible-header-info {
  font-size: 0.9rem;
  opacity: 0.75;
  margin-top: 10px;
}

.create-test .collapsible-body {
  background-color: #fff;
}

.create-test .collapsible-body h6 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin: 20px 0;
  font-size: 14px;
}

.create-test .collapsible-body > h6:first-child {
  margin-top: 0;
}

.coursechip,
.durationchip {
  cursor: pointer;
}

.coursechip.selected,
.coursechip.selected:hover,
.durationchip.selected,
.durationchip.selected:hover {
  background-color: #584f52;
  color: #fff;
}

.helper-text {
  font-size: 11px;
  color: #888;
}

.vue-daterange-picker {
  width: 100%;
}

.coursechip:hover,
.durationchip:hover {
  background-color: #88797e;
  color: #fff;
}

.coursechip.disabled:hover {
  background-color: #e4e4e4;
  color: #0009;
  cursor: not-allowed;
}

.collapsible-body.parts label,
.collapsible-body.questionGeneration label {
  margin-right: 16px;
}

.part-details {
  margin-top: 20px;
}

.part {
  background-color: #f6f6f6;
  padding: 16px;
  margin-bottom: 30px;
}

.questions-container {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ededed80;
  background-color: #f6f6f6;
}

.questions-container .card {
  cursor: pointer;
}

.questions-container .card .card-image {
  border-left: 8px solid #9e9e9e;
  color: #9e9e9e;
}

.questions-container .card.selected .card-image {
  border-left: 8px solid #4caf50;
  color: #4caf50;
}

.questions-container .card i {
  display: none;
}

.questions-container .card.selected i {
  display: inline-block;
  position: absolute;
  color: #4caf50;
  right: 0px;
  bottom: 0;
  padding: 10px;
}

.tabs {
  background: #e4e4e4;
  border-radius: 40px;
}

.tabs .tab a,
.tabs .tab a:focus,
.tabs .tab a:focus.active {
  color: #000;
  background: transparent;
}

.tabs .tab a.active,
.tabs .tab a.active:hover {
  color: #fff;
  background: #584f52;
}

.tabs .tab a:hover {
  color: #fff;
  background: #88797e;
}

.tabs .tab {
  border-right: 1px solid #cdcdcd;
}

.tabs .tab:last-child {
  border-right: none;
}

.addQuestion {
  cursor: pointer;
}

.customQuestion textarea,
.customQuestion .qpreview {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  min-height: 280px;
}

.customQuestion .qpreview {
  background-color: #f6f6f6;
  white-space: pre-line;
  overflow-y: auto;
}

.file-selector {
  border: 1px solid #ddd;
  padding: 10px;
  height: 100px;
  text-align: center;
}

.file-selector input[type="file"] {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
}

.file-selector label {
  display: block;
  cursor: pointer;
  font-size: 1rem;
  color: #888;
}

.file-selector label span {
  text-decoration: underline;
}

.ql-editor {
  background-color: #f6f6f6;
}

.ovly {
  position: absolute;
  top: 0;
  background-color: #bbbbbbe6;
  height: 100%;
  width: 100%;
}

.pre-formatted {
  white-space: pre-wrap;
  /* 👈 this is the important part */
  border: 1.5px dotted;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
}

.createTestStartEndDateTimePicker {
  padding: 10px !important;
  height: 44px;
  border-radius: 2px;
  font-size: 1rem;
  margin-top: -7px;
}

// new styles added
.border-none {
  border: none;
}
</style>
