<template>
<div>
    <h5 class="mergeInstituteTestTitle">Merge Institute Test</h5>
    <div class="mergeInstituteTestContainer">
        <div class="mergeInstituteTest_section">
            <div class="mergeInstituteTestContent">
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Course</h6>
                    <select class="browser-default dropd" @change="onCourseChange($event)" v-model="selectedCourse" style="border-radius: 4px; height: 40px;">
                        <option value="0" disabled selected>--Select--</option>
                        <option v-for="course in Courses" :key="course.courseId" :value="course.courseId">
                            {{ course.courseName }}
                        </option>
                    </select>
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle" style="">Institute Test</h6>
                    <VueMultiselect class="browser-default dropd" select-Label="" selected-Label="" deselect-Label="" v-model="selectedInstituteTest" :options="InstituteTestList" :multiple="true" :limit=1 track-by="InstituteTestId" :custom-label="customLabel" :close-on-select="false" @select=onSelectInstituteTest($event) @remove=onRemoveInstituteTest($event)>
                        <template v-slot:beforeList>
                            <div class="row" style="margin-top: 5px">
                                <a class="multiSelectButtons waves-effect waves-light btn " @click="selectKscNone"><span style="display: flex; justify-content: center;">None</span></a>&nbsp;
                                <a class="multiSelectButtons waves-effect waves-light btn" @click="selectKscAll"><span>All </span></a>
                            </div>
                        </template>
                    </VueMultiselect>
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Test Name</h6>
                    <input id="title" type="text" v-model="title" class="browser-default" style="border-radius: 4px; height: 40px;">
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Duration</h6>
                    <div v-for="d in durations" :key="`dur${d.value}`" class="chip durationchip" :class="{selected: d.value === selectedDuration}" @click="selectedDuration = d.value">{{ d.text }}</div>
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Start DateTime</h6>
                    <v-date-picker v-model="startDate" mode="dateTime" :min-date="minDate" :minute-increment="5" is-required style="border-radius: 4px;">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input type="text" class="browser-default" :value="inputValue" v-on="inputEvents" readonly style="height: 40px;" />
                        </template>
                    </v-date-picker>
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">End DateTime</h6>
                    <v-date-picker v-model="endDate" mode="dateTime" :max-date="maxDate" :minute-increment="5" is-required style="border-radius: 4px;">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input type="text" class="browser-default" :value="inputValue" v-on="inputEvents" readonly style="height: 40px;" />
                        </template>
                    </v-date-picker>
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Total Questions</h6>
                    <input id="noOfQs" readonly type="number" class="browser-default validate" min="10" max="200" v-model.number="totalQuestions" @change="totalMarks = totalQuestions;" style="border-radius: 4px; height: 40px;">
                </div>
                <div class="mergeInstituteTest_subContent">
                    <h6 class="subTitle">Total Marks</h6>
                    <input id="totalMarks" readonly type="number" class="browser-default validate" :min="totalQuestions" :max="totalQuestions * 6" v-model.number="totalMarks" style="border-radius: 4px;height: 40px;">
                    <span class="helper-text">Total marks should not be more than {{totalQuestions * 6}}</span>
                </div>
                <div>
                    <h6 class="subTitle" style="padding-bottom: 5px;">Batches</h6>
                    <!-- <div v-for="batch in batches" :key="`batch${batch.Id}`" class="chip coursechip" :class="{selected: batch.Id === selectedBatch}" @click="selectedBatch = batch.Id">{{ batch.BatchName }}</div> -->
                    <div v-for="batch in batches" :key="`batch${batch.Id}`" class="chip coursechip" :class="{selected: batch.selected}" @click="batch.selected = !batch.selected; selectAllBatch = false">{{ batch.Name }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 right-align" v-if="InstituteTestList.length > 0">
                    <button class="btn-small waves-effect waves-light" v-on:click="CreateNewTest()"> Merge Test</button>
                </div>
            </div>
        </div>
        <div>
            <h6 class="mergeTest_title">Merge test in the sequence</h6>
            <div class="mergeInstituteTest_rightsection">
                <div v-for="(data, index) in instituteTestSortedArray" :key="data.InstituteTestId" :value="data.InstituteTestId">
                    <div class="sortedTestList" :class="{ active: this.selectedIndex === index}">
                        <div class="sortedListData" style="">{{ data.Title }}</div>
                        <div class="sortButtons">
                            <div class="sortButton_up">
                                <i class="material-icons" @click="reArrangeSelectedInstituteTestList(index,index-1)" :disabled="index==0" style="cursor: pointer; font-size: 25px;">arrow_drop_up</i>
                            </div>
                            <div class="sortButton_down">
                                <i class="material-icons" @click="reArrangeSelectedInstituteTestList(index,index+1)" style="cursor: pointer;">arrow_drop_down</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal2 :show="mergeTest" @close="mergeTest = false" :showHeader="false">
        <template v-slot:body class="assignment-modal-body">
            <h5 class="mergeStatus" :class="{mergesuccess: this.mergeSuccess === true}">{{this.mergeTestResponse}}</h5>
            <button class="mergeStatusButton" style="" :class="{mergesuccess: this.mergeSuccess === true}" @click="this.mergeTest = false">OK</button>
        </template>
    </Modal2>
</div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import Moment from "moment";
import Api from "../Api";
import Mobileapi from "../Mobileapi";
import Modal2 from "../components/Model2.vue";

export default {
  components: {
    // CourseList: () => import("../components/CourseList.vue"),
    VueMultiselect,
    Modal2,
  },
  data() {
    return {
      selectedCourse: "",
      Courses: [],
      batches: [],
      InstituteTestList: [],
      selectedInstituteTest: [],
      courseId: 0,
      affiliationId: 0,
      selectedCourseId: 0,
      // selectedCourse: 0,
      startDate: Moment().add(15, "minutes").toDate(),
      endDate: Moment().add(7, "days").toDate(),
      title: "",
      minDate: Moment().startOf("day").toDate(),
      maxDate: Moment().add(3, "months").toDate(),
      durations: [{
        text: "30m",
        value: 30,
      },
      {
        text: "45m",
        value: 45,
      },
      {
        text: "60m",
        value: 60,
      },
      // { text: "1hr 15m", value: 75 },
      {
        text: "1hr 30m",
        value: 90,
      },
      // { text: "1hr 45m", value: 105 },
      {
        text: "2hrs",
        value: 120,
      },
      {
        text: "3hrs",
        value: 180,
      },
      ],
      selectedDuration: 0,
      selectedBatch: 0,
      totalQuestions: 0,
      totalMarks: 0,
      InstituteTestIdNew: "",
      selectedKSCes: [],
      isSortClicked: false,
      selectedIndex: null,
      mergeTest: false,
      mergeTestResponse: "",
      mergeSuccess: "",
    };
  },
  created() {
    this.$store.dispatch("showLoader", true);
    // const user = this.$store.getters.user;
    Api.getDataOfAffiliationCourses({},
      (resp) => {
        this.$store.dispatch("showLoader", false);
        this.Courses = resp.data;
      },
      () => {
        this.$store.dispatch("showLoader", false);
      });
    const user = this.$store.getters.user;
    this.affiliationId = parseInt(user.AffiliationId);
  },
  computed: {
    instituteTestSortedArray() {
      if (this.selectedInstituteTest.length > 0) {
        this.editInstitutetestId();
      }
      return this.selectedInstituteTest;
    },
  },
  methods: {
    editInstitutetestId() {
      for (let i = 0; i < this.selectedInstituteTest.length; i += 1) {
        this.selectedInstituteTest[i].index = i + 1;
        let theorycount = "";
        const count = "a";
        let aCount = "";
        for (let j = 0; j < this.selectedInstituteTest[i].index; j += 1) {
          aCount += count;
        }
        theorycount = aCount + this.selectedInstituteTest[i].InstituteTestId;
        this.selectedInstituteTest[i].testing = count;
        this.selectedInstituteTest[i].sortOrder = theorycount;
      }
    },
    reArrangeSelectedInstituteTestList(from, to) {
      this.instituteTestSortedArray.splice(to, 0, this.instituteTestSortedArray.splice(from, 1)[0]);
      this.selectedIndex = to;
    },
    onCourseChange(event) {
      this.InstituteTestList = [];
      this.selectedInstituteTest = [];
      this.selectedCourseId = Number(event.target.value);
      if (this.selectedCourseId > 0) {
        this.bindBatches();
        this.bindInstituteTest();
      }
    },
    bindInstituteTest() {
      this.InstituteTestList = [];
      this.$store.dispatch("showLoader", true);
      Mobileapi.getInstituteTestDraft({
        AffiliationId: this.affiliationId,
        CourseId: this.selectedCourseId,
      }, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.data.length > 0) {
          this.InstituteTestList = response.data[0];
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      });
    },
    bindBatches() {
      this.batches = [];
      this.$store.dispatch("showLoader", true);
      Mobileapi.getBatches({
        AffiliationId: this.affiliationId,
        CourseId: this.selectedCourseId,
      }, (response) => {
        this.$store.dispatch("showLoader", false);
        // this.batches = response.data;
        if (response.data.length > 0) {
          this.batches = response.data.map((obj) => ({
            ...obj,
            selected: false,
          }));
          this.batches[0].selected = true;
        }
        // this.InstituteTestList = this.InstituteTestList.map((v) => ({ ...v, checked: false }));
      },
      () => {
        this.$store.dispatch("showLoader", false);
      });
    },
    selectedBatches() {
      const sBatches = this.batches.filter((obj) => obj.selected);
      return sBatches.map((obj) => obj.Id).join(",");
    },

    customLabel(option) {
      return `${option.Title}`;
    },

    onSelectInstituteTest(option) {
      const index = this.InstituteTestList.findIndex((item) => item.Title === option.Title);
      this.InstituteTestList[index].checked = true;
      //   this.InstituteTestList[index].serialNo = index;
      this.InstituteTestList[index].testing = "";
      this.totalQuestions += parseInt(this.InstituteTestList[index].QuestionCount);
      this.totalMarks += parseInt(this.InstituteTestList[index].TotalMarks);
      this.selectedDuration += parseInt(this.InstituteTestList[index].Duration);
      if (this.InstituteTestList[index].BatchIds !== null) {
        const instTestBatches = this.InstituteTestList[index].BatchIds.split(",").filter((x) => x).map(Number);
        this.batches.map((obj) => ({
          ...obj,
          selected: instTestBatches.includes(obj.Id),
        }));
      }
      //   this.batches = response.data.map((obj) => ({ ...obj, selected: false })); //
      this.batches[0].selected = true;
    },

    onRemoveInstituteTest(option) {
      const index = this.InstituteTestList.findIndex((item) => item.Title === option.Title);
      this.InstituteTestList[index].checked = false;
      this.totalQuestions -= parseInt(this.InstituteTestList[index].QuestionCount);
      this.totalMarks -= parseInt(this.InstituteTestList[index].TotalMarks);
      this.selectedDuration -= parseInt(this.InstituteTestList[index].Duration);
    },
    selectKscAll() {
      for (let i = 0; i < this.InstituteTestList.length; i += 1) {
        this.InstituteTestList[i].checked = true;
        this.InstituteTestList[i].serialNo = i;
        this.totalQuestions += parseInt(this.InstituteTestList[i].QuestionCount);
        this.totalMarks += parseInt(this.InstituteTestList[i].TotalMarks);
        this.selectedDuration += parseInt(this.InstituteTestList[i].Duration);
      }
      this.selectedInstituteTest = this.InstituteTestList;
    },
    selectKscNone() {
      for (let i = 0; i < this.InstituteTestList.length; i += 1) {
        this.InstituteTestList[i].checked = false;
      }
      this.selectedInstituteTest = [];
    },
    CreateNewTest() {
      if (this.selectedInstituteTest.length <= 1) {
        this.mergeTest = true;
        this.mergeTestResponse = "Select more than 1 institute test to merge.";
        // window.M.toast({
        //   html: "",
        // });
        return;
      }
      if (this.title.trim().length <= 0) {
        this.mergeTest = true;
        this.mergeTestResponse = "Please enter title.";
        // window.M.toast({
        //   html: "Please enter title.",
        // });
        return;
      }
      if (this.selectedBatches() === null || this.selectedBatches().length <= 0) {
        this.mergeTest = true;
        this.mergeTestResponse = "Atleast one batch is mandatory for merging the test.";
        // window.M.toast({
        //   html: "Atleast one batch is mandatory for merging the test.",
        // });
        return;
      }
      this.$store.dispatch("showLoader", true);
      const paramsData = {
        affiliationId: this.affiliationId,
        courseId: this.selectedCourseId,
        affiliationCourseId: this.Courses.filter((element) => element.courseId === this.selectedCourse)[0].affiliationCourseId,
        title: this.title,
        totalQuestions: this.totalQuestions,
        totalMarks: this.totalMarks,
        // instituteTestIds: this.instituteTestSortedArray.map((element) => element.InstituteTestId).join(","),
        // instituteTestIds: this.selectedInstituteTest.map((element) => element.InstituteTestId).join(","),
        instituteTestIds: this.instituteTestSortedArray.map((element) => element.sortOrder).join(","),
        // sortOrder1: this.selectedInstituteTest.map((element) => element.sortOrder).join(","),
        startDateTime: Moment(this.startDate).format("DD-MM-YYYY h:mma"),
        endDateTime: Moment(this.endDate).format("DD-MM-YYYY h:mma"),
        duration: this.selectedDuration,
        batchIds: this.selectedBatches(),
      };
      Mobileapi.addInstituteTestMerge(paramsData, (response) => {
        this.$store.dispatch("showLoader", false);
        if (parseInt(response.responseCode) === 200) {
          this.mergeTest = true;
          this.mergeSuccess = true;
          this.mergeTestResponse = "Merged the institute tests successfully.";
          window.location.reload();
        } else {
          this.mergeTest = true;
          this.mergeTestResponse = "Failed to merge institute test.";
        }
      });
    },
  },
};
</script>

<style scoped>
@import 'https://unpkg.com/vue-multiselect@2.0.2/dist/vue-multiselect.min.css';

.checkbox-label {
    display: block;
}

.test {
    position: absolute;
    right: 1vw;
}

.coursechip,
.durationchip {
    cursor: pointer;
}

.coursechip.selected,
.coursechip.selected:hover,
.durationchip.selected,
.durationchip.selected:hover {
    background-color: #3751FF;
    color: #fff;
}

.mergeInstituteTestTitle {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 30px;
}

.mergeInstituteTestContainer {
    display: flex;
    gap: 10px;
    background-color: white;
    margin-bottom: 20px;
}

.mergeInstituteTest_section {
    max-width: 900px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
}

.subTitle {
    font-size: 14px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 8px;
}

.mergeTest_title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 35px;
}

.mergeInstituteTest_rightsection {
    max-height: 590px;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.sortedTestList {
    display: flex;
    background: white;
    border: 1px solid #E0E4F0;
    min-height: 50px;
    margin-bottom: 10px;
    border-radius: 4px;
    margin-right: 20px;
}

.sortedListData {
    font-size: 14px;
    font-weight: 400;
    max-width: 350px;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
}

.sortButtons {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
}

/* .sortButton_up{
} */
/* .sortButton_up:hover {
    background: red;
    border-radius: 80%;
    width: 50px;
    height: 50px;
} */

.sortedTestList.active {
    border: 1px solid #16A085 !important;
}

.mergeInstituteTestContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 14px;
    margin-top: 11px;
}

.mergeInstituteTest_subContent {
    max-width: 470px;
    width: 100%;
    margin-bottom: 15px;
}

.mergeStatus {
    padding: 20px;
    /* margin-right: 15px; */
    font-size: 16px;
    font-weight: 600;
    color: #FF7675;
    min-width: 350px;
}

.mergeStatusButton {
    float: right;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    background: #FF7675;
    color: white;
    border-radius: 4px;
    border: 1px solid #FF7675;
}

.mergeStatus.mergesuccess {
    color: #16A085 !important;
}

.mergeStatusButton.mergesuccess {
    background: #16A085 !important;
    border: 1px solid #16A085 !important;
}

@media screen and (max-width: 850px) {
    .mergeInstituteTestContent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 14px;
        margin-top: 11px;
    }
}

@media screen and (max-width: 480px) {
    .mergeInstituteTestContainer {
        display: flex;
        flex-direction: column;
    }

    .mergeTest_title {
        padding-left: 20px;
    }

    .sortedTestList {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

::-webkit-scrollbar {
    display: none;
}
</style>
