<template>
  <div class="subject-coverage flex subject-coverage-responsive">
    <div class="left-section white card-border card-radius" v-if="subjectListData.length > 0">
      <div class="flex flex-between">
        <button
          class="start-practice-button flex flex-center tooltip"
          data-tooltip="I am a tooltip"
          v-if="isPracticeReady === false"
        >
          Start Practice<span class="tooltiptext practice"
            >Select 1 or more chapters to practice</span
          >
        </button>
        <button
          v-if="isPracticeReady === true && this.isPastYear === false"
          class="start-practice-button flex flex-center"
          :class="{ ready: isPracticeReady === true }"
          @click="checkForPendingSession()"
        >
          Start Practice
        </button>
        <button
          v-if="isPracticeReady === true && this.isPastYear === true"
          class="start-practice-button flex flex-center"
          :class="{ ready: isPracticeReady === true }"
          @click="startPracticeSession()"
        >
          Start Practice
        </button>
        <div
          v-if="showClassChangeOption === true"
          class="flex"
          style="cursor: pointer"
          @click="changeClass()"
        >
          <span
            class="flex fs-12 fw-600"
            style="margin-top: 7px; color: #3751ff; padding-right: 7px"
            >{{ dashboardClassName }}</span
          ><img
            style="height: 16px; width: 16px; margin-top: 8px"
            :src="require(`../assets/class-change.svg`)"
          />
        </div>
      </div>
      <span v-if="showErrorStatement === true" class="fs-12 fw-600 skyblue-text"
        >Please change the selection made</span
      >
      <div
        class="subject-select card-border card-radius"
        v-if="subjectListData.length > 0"
        v-click-outside="onClickOutside"
      >
        <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showSubjectList = false"
          >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
            >expand_more</i
          ></a
        >
        <h1 class="fs-20 fw-700 black-text" style="margin-top: 4px">{{ selectedSub }}</h1>
        <div
          style="width: 41%; height: 63px; position: absolute; top: 3px; right: 0; overflow: hidden"
        >
          <img
            :src="selectedSubIconUrl ? selectedSubIconUrl : randomImage"
            alt=""
            class="subject-img"
          />
        </div>
        <div class="subject-list scroll-verticle" v-if="!showSubjectList">
          <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showSubjectList = true"
            >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
              >expand_less</i
            ></a
          >
          <div class="sublist">
            <p v-for="(item, index) in subjectListData" :key="index" @click="selectSubject(item)">
              {{ item.subjectName }}
            </p>
          </div>
        </div>
      </div>
      <div class="chapter-wise-detail" v-if="Object.keys(chapterwiseFilterList).length > 0">
        <div class="flex flex-between items-center" style="margin-bottom: 11px">
          <h2 class="secondary-header">Select Chapters</h2>
          <a
            class="fs-11 fw-600 skyblue-text flex items-center flex-between"
            @click="showChapterDetail = !showChapterDetail"
            style="margin-right: 3px"
            >Details<i class="material-icons fs-15" style="margin-left: 3px">{{
              showChapterDetail ? "expand_less" : "expand_more"
            }}</i></a
          >
        </div>
        <div class="scroll-horizontal flex" v-dragscroll>
          <a
            v-for="(chapterFilter, index) in Object.values(chapterwiseFilterList)"
            :key="index"
            class="gray-btn1"
            style="margin-right: 10px"
            :class="chapterFilter.isDefault ? 'active' : ''"
            @click="filterChapterCards(chapterFilter, index)"
            >{{ chapterFilter.displayName }}</a
          >
        </div>
      </div>
      <div class="instruction-line">
        Click the cards to select up to <strong> three </strong> chapters for practice!
      </div>
      <div class="chapter-wise-content scroll-verticle" v-if="chapterwiseCardsList.length > 0">
        <div
          class="chapter-wise-card card-border card-radius"
          v-for="(card, index) in chapterwiseCardsList"
          :value="card.courseChapterId"
          :key="index"
          style="margin-bottom: 9px"
          :class="{
            selected: selectedChapterList.includes(card.chapterId),
            increaseHeight: showChapterDetail === true,
          }"
          @click="onChapterSelect(card.chapterId, card.courseChapterId, card.chapterName)"
        >
          <div class="flex flex-between items-center" style="height: 16px">
            <h3 class="fs-12 fw-600 black-text truncate" style="line-height: 16px">
              {{ card.chapterName }}
            </h3>
            <div v-if="selectedChapterList.includes(card.chapterId)">
              <img style="height: 18px; width: 18px" :src="require(`../assets/tick1.svg`)" />
            </div>
            <!-- <a class="yellow-btn" style="width: 94px;">Practice Now</a> -->
          </div>
          <h4
            v-if="showChapterDetail === true"
            class="progress-value fs-11 fw-700"
            style="color: #ee9b05"
          >
            {{ (card.gaugeValue * 100).toFixed(0) }}%
          </h4>
          <div class="progress1">
            <!-- <div class="progress-bar1 red" :style="`width: ${card.gaugeValue*100}%`"  aria-valuemin="0" aria-valuemax="100"> -->
            <div
              class="progress-bar1"
              role="progressbar"
              :style="`width: ${parseInt((card.gaugeValue * 100).toFixed(0))}%`"
              v-bind:aria-valuenow="parseInt((card.gaugeValue * 100).toFixed(0))"
              aria-valuemin="0"
              aria-valuemax="100"
              :class="[
                {
                  transparent:
                    parseInt((card.gaugeValue * 100).toFixed(0)) == 0 ||
                    parseInt((card.gaugeValue * 100).toFixed(0)) == '',
                },
                {
                  red:
                    parseInt((card.gaugeValue * 100).toFixed(0)) <= 50 &&
                    parseInt((card.gaugeValue * 100).toFixed(0)) > 0,
                },
                {
                  green:
                    parseInt((card.gaugeValue * 100).toFixed(0)) <= 100 &&
                    parseInt((card.gaugeValue * 100).toFixed(0)) > 50,
                },
              ]"
            ></div>
            <!-- </div> -->
          </div>
          <div
            class="flex flex-between"
            style="padding-right: 3px; padding-bottom: 12px"
            v-if="showChapterDetail === true"
          >
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <QuestionIconSvg :bgColor="card.Attempted.color" style="margin-right: 10px" />
              <span :style="`color: ${card.Attempted.color}`">{{ card.Attempted.value }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <EffortSvg :bgColor="card.TimeTaken.color" style="margin-right: 10px" />
              <span style="line-height: 18px" :style="`color: ${card.TimeTaken.color}`">{{
                card.TimeTaken ? card.TimeTaken.value : ""
              }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <AccuracySvg :bgColor="card.Accuracy.color" style="margin-right: 10px" />
              <span style="line-height: 18px" :style="`color: ${card.Accuracy.color}`">{{
                card.Accuracy ? card.Accuracy.value : ""
              }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <SpeedSvg :bgColor="card.AvgTime.color" style="margin-right: 10px" />
              <span style="line-height: 18px" :style="`color: ${card.AvgTime.color}`">{{
                card.AvgTime.color ? card.AvgTime.value : ""
              }}</span>
            </p>
            <!-- <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                  :fill="card.TimeTaken.color" />
                <path
                  d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                  :fill="card.TimeTaken.color" style="margin-right: 7px;" />
              </svg>
              <span :style="`color: ${card.TimeTaken.color}`">{{ card.TimeTaken.value }}</span>
            </p> -->
            <!-- <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                  :fill="card.Accuracy.color" />
              </svg>
              <span :style="`color: ${card.Accuracy.color}`">{{ card.Accuracy.value }}</span>
            </p> -->
            <!-- <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.00078 16.0312C12.4602 16.0312 15.2727 13.2047 15.2727 9.73125C15.2727 8.19844 14.7242 6.77812 13.8102 5.69531L14.1898 5.31562C14.4148 5.09062 14.4148 4.73906 14.1898 4.51406C13.9648 4.28906 13.6133 4.28906 13.3883 4.51406L13.0086 4.89375C12.0664 4.07812 10.8711 3.55781 9.56328 3.44531V3.09375H9.78828C10.0977 3.09375 10.3508 2.84062 10.3508 2.53125C10.3508 2.22187 10.0977 1.96875 9.78828 1.96875H8.21328C7.90391 1.96875 7.65078 2.22187 7.65078 2.53125C7.65078 2.84062 7.90391 3.09375 8.21328 3.09375H8.43828V3.44531C7.13047 3.55781 5.93516 4.07812 4.97891 4.89375L4.59922 4.5C4.37422 4.275 4.02266 4.275 3.79766 4.5C3.57266 4.725 3.57266 5.07656 3.79766 5.30156L4.17734 5.68125C3.26328 6.77813 2.71484 8.18437 2.71484 9.71719C2.72891 13.2047 5.54141 16.0312 9.00078 16.0312ZM9.00078 4.26094C11.9961 4.26094 14.4289 6.70781 14.4289 9.73125C14.4289 12.7547 11.9961 15.1875 9.00078 15.1875C6.00547 15.1875 3.57266 12.7406 3.57266 9.73125C3.57266 6.72187 6.00547 4.26094 9.00078 4.26094Z"
                  :fill="card.AvgTime.color" />
                <path
                  d="M8.99922 13.9355C11.3195 13.9355 13.1898 12.0512 13.1898 9.7168C13.1898 7.38242 11.3055 5.49805 8.99922 5.49805C6.69297 5.49805 4.80859 7.38242 4.80859 9.7168C4.80859 12.0512 6.67891 13.9355 8.99922 13.9355ZM8.57734 8.73242V6.73555C8.57734 6.49648 8.76016 6.31367 8.99922 6.31367C9.23828 6.31367 9.42109 6.49648 9.42109 6.73555V8.73242C9.80078 8.90117 10.068 9.28086 10.068 9.7168C10.068 10.3074 9.58984 10.7855 8.99922 10.7855C8.40859 10.7855 7.93047 10.3074 7.93047 9.7168C7.93047 9.28086 8.19766 8.90117 8.57734 8.73242Z"
                  :fill="card.AvgTime.color" />
                <line x1="3" y1="9.5" x2="1" y2="9.5" :stroke="card.AvgTime.color" stroke-linecap="round" />
                <line x1="7.5" y1="15.5" x2="4.5" y2="15.5" :stroke="card.AvgTime.color" stroke-linecap="round" />
                <line x1="3.5" y1="12.5" x2="2.5" y2="12.5" :stroke="card.AvgTime.color" stroke-linecap="round" />
                <line x1="1" y1="12.5" x2="0.5" y2="12.5" :stroke="card.AvgTime.color" stroke-linecap="round" />
                <line x1="3" y1="15.5" x2="2" y2="15.5" :stroke="card.AvgTime.color" stroke-linecap="round" />
              </svg>
              <span :style="`color: ${card.AvgTime.color}`">{{ card.AvgTime.value }}</span>
            </p> -->
          </div>
        </div>
      </div>
      <div
        v-if="Number(dashboardCourseId) === 1 || Number(dashboardCourseId) === 6"
        class="question-type"
        style="margin-top: 20px"
      >
        <h2 class="secondary-header">Type Of Practice</h2>
        <div
          class="flex"
          style="width: 100%; justify-content: space-around; margin-bottom: 10px; margin-top: 10px"
        >
          <span class="flex question-header flex-right" style="">Normal Practice</span>
          <label class="switch">
            <input type="checkbox" v-model="isPastYear" />
            <div class="slider round"></div>
          </label>
          <span class="question-header">Past Year Practice</span>
        </div>
      </div>
      <div class="question-type" style="margin-top: 20px">
        <h2 class="secondary-header">Type Of Questions</h2>
        <div style="margin-top: 10px">
          <div
            class="flex"
            style="width: 100%; justify-content: space-around; margin-bottom: 10px"
            v-if="Number(dashboardCourseId) !== 1 && isPastYear === false"
          >
            <span class="question-header">Objective / MCQ / Numerial</span>
            <label class="switch">
              <input type="checkbox" v-model="checkbox" />
              <div class="slider round"></div>
            </label>
            <span class="question-header">Subjective</span>
          </div>
          <div
            class="flex"
            style="width: 100%; justify-content: space-around; margin-bottom: 10px"
            v-if="Number(dashboardCourseId) === 1"
          >
            <span class="flex question-header flex-right" style="">JEE Mains</span>
            <label class="switch">
              <input type="checkbox" v-model="checkbox1" />
              <div class="slider round"></div>
            </label>
            <span class="question-header">JEE Advanced</span>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-section">
      <!-- Overview -->
      <!-- <h1 class="fs-18 fw-700 dark-text" style="margin-bottom: 12px;">Practice Sessions</h1> -->
      <!-- Practice -->
      <div class="recommendation practice" v-if="this.getNewStudentDashboardpra.length > 0">
        <div class="recommend-top flex items-center">
          <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 45px">
            Recommendations
          </h2>
        </div>
        <div
          v-dragscroll
          v-if="getNewStudentDashboardpra.length > 0"
          class="recommendation-content flex scroll-horizontal"
        >
          <div
            class="main-recommendation-box"
            v-for="(Chapter, index) in getNewStudentDashboardpra"
            :key="index"
            :value="Chapter.chapterId"
            ref="slider"
          >
            <PracticeCard
              :chapterName="Chapter.chapterName"
              :subjectName="Chapter.subjectName"
              :subjectIcon="Chapter.subjectIcon"
              :buttonName="startButton"
              :backgroundColor="startCardColor"
              :totalQuestion="null"
              :accuracy="null"
              :lpsId="lpsId"
              :data="JSON.stringify(Chapter)"
            />
          </div>
        </div>
        <!-- <div v-if="this.getNewStudentDashboardpra.length === 0">
                <div class="main-recommendation-box">
                    <div class="recommend-box recommendation-box relative lightPink-bgc card-radius-2" style="padding:12px;overflow: hidden; background: #E3E3E3 !important;">
                        <div class="flex flex-between flex-column">
                            <img style="height: 25px;margin-top: 5px;margin-bottom: 5px;" :src="require(`../assets/newsession.svg`)" @click="startNewPractice(Chapter)" />
                            <p class="fs-12 fw-600" style="opacity: 0.6;margin-left:5px">Start Your Own Practice</p>
                        </div>
                    </div>
                </div>
            </div> -->
      </div>
      <!-- PAused -->
      <div
        class="recommendation practice"
        v-if="this.subjectCoverageData.length !== 0 && this.pausePracticeList.length > 0"
      >
        <div class="recommend-top flex items-center">
          <h2
            class="fs-15 purple-text fw-700"
            style="margin-bottom: 5px; margin-right: 45px; width: 150px"
          >
            Pending Sessions
          </h2>
        </div>
        <div
          v-dragscroll
          v-if="pausePracticeList.length > 0"
          class="recommendation-content flex scroll-horizontal"
        >
          <div
            class="main-recommendation-box"
            v-for="(Chapter, index) in pausePracticeList"
            :key="index"
            :value="Chapter.chapterId"
            ref="slider"
          >
            <PracticeCard
              :chapterName="Chapter.chapterName"
              :subjectName="Chapter.subjectName"
              :subjectIcon="Chapter.subjectIcon"
              :buttonName="resumeButton"
              :backgroundColor="resumeCardColor"
              :date="Chapter.startedOn"
              :totalQuestion="null"
              :accuracy="null"
              @click="resumePracticeSession(Chapter)"
              :lpsId="lpsId"
              :lpsEndTime="lpsEndTime"
            />
          </div>
        </div>
      </div>
      <!-- Completed Session -->
      <div
        class="recommendation practice scroll-verticle"
        v-if="this.subjectCoverageData.length !== 0 && this.completedPracticeList.length > 0"
        style="max-height: calc(100vh - 300px)"
      >
        <div class="recommend-top flex items-center">
          <h2
            class="fs-15 purple-text fw-700"
            style="margin-bottom: 5px; margin-right: 45px; width: 175px"
          >
            Completed Sessions
          </h2>
        </div>
        <div
          v-if="completedPracticeList.length > 0"
          class="completedSession-content"
          :class="!showMore ? 'loadMoreDiv' : ''"
        >
          <div
            class="main-recommendation-box"
            v-for="(Chapter, index) in completedPracticeList"
            :key="index"
            :value="Chapter.chapterId"
            ref="slider"
          >
            <PracticeCard
              :chapterName="Chapter.chapterName"
              :subjectName="Chapter.subjectName"
              :subjectIcon="Chapter.subjectIcon"
              :buttonName="reportButton"
              :backgroundColor="reportCardColor"
              :date="Chapter.startedOn"
              :totalQuestion="Chapter.totalQuestions"
              :accuracy="Chapter.accuracy"
              :userTestSessionId="Chapter.userTestSesssionId"
              :data="JSON.stringify(Chapter)"
              :lpsId="lpsId"
              :lpsEndTime="lpsEndTime"
            />
          </div>
        </div>
        <div
          style="padding: 10px"
          v-if="completedPracticeList.length > 6 && showMore === false"
          @click="showMore = true"
        >
          <button class="loadMoreBtn center-align">
            See more
            <img
              style="margin-top: 4px; margin-left: 3px"
              :src="require(`../assets/downarrow.svg`)"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="right-section">
      <!-- coverage report -->
      <div class="coverageAvReport" v-if="this.avgreport.length > 0">
        <h1 class="fs-18 fw-700 dark-text CoverageReportTitle" style="margin-bottom: 12px">
          Coverage Report
        </h1>
        <Flicking
          :options="{ align: 'prev', circular: true }"
          :plugins="plugins"
          style="margin-bottom: 10px"
        >
          <div class="avCard" v-for="(img, index) in avgreport" :key="index">
            <div
              v-if="img.CardType === 'AVReport' && img.CardType !== 'LiveClass'"
              class="slideshow-container flex card-shadow card-radius card-border"
            >
              <div class="relative avCardHeight" style="padding: 15px; width: 100%">
                <div class="flex flex-between items-center" style="margin-bottom: 7px">
                  <h2 class="fw-700 fs-14" style="color: #ffffff !important">
                    Subject Coverage Report
                  </h2>
                  <img :src="require(`../assets/video 2.svg`)" alt="" width="24" />
                </div>
                <p class="white-text fs-11 fw-400" style="opacity: 0.7">
                  {{ formateTime(img.CreatedOn) }}
                </p>
                <a
                  class="yellow-btn fs-10 fw-600"
                  style="
                    width: 83px;
                    height: 20.03px;
                    margin-top: 25px;
                    line-height: 20.03px;
                    display: inline-block;
                  "
                  @click="watchVideo(img.VimeoURL)"
                  >Watch Video</a
                >
                <img class="class-image" :src="require(`../assets/Ellipse 419.svg`)" />
              </div>
            </div>
            <div
              v-if="img.CardType === 'LiveClass' && img.CardType !== 'AVReport'"
              class="slideshow1-container flex card-shadow card-radius"
            >
              <div class="fade relative" style="padding: 16px; margin-top: 15px">
                <h2 class="fs-18 fw-700 dark-text" style="color: #ffffff !important">
                  {{ img.Title }}
                </h2>
                <p class="fs-10 fw-400" style="color: #ffffff !important; margin-top: 15px">
                  <br /><span class="fs-15 fw-400">{{ img.SubjectName }}</span>
                </p>
                <p class="fs-11 fw-400" style="color: #ffffff !important; margin-top: 20px">
                  Duration {{ img.Duration }} min
                </p>
                <p class="fs-10 fw-600" style="color: #ffffff !important">
                  Faculty: <span class="fw-400"></span> {{ img.FacultyName }}
                </p>
                <a
                  @click="joinClass(img.Url)"
                  class="yellow-btn"
                  style="margin-top: 28px; line-height: 26px"
                  >Join Class</a
                >
                <img class="class-image" :src="require(`../assets/cardellipse.svg`)" />
              </div>
            </div>
          </div>
        </Flicking>
      </div>
      <!-- leaderboard -->
      <div class="leader-sec" v-if="leaderBoardData.length > 0">
        <div class="right-section1">
          <div class="leaderboard_reward-section">
            <div class="leaderboard">
              <div class="flex flex-between items-center" style="margin-bottom: 11px">
                <h1 class="fs-18 fw-700 dark-text top-header">Leaderboard</h1>
                <a
                  class="fs-11 fw-600 skyblue-text flex items-center flex-between"
                  @click="showleaderboardDetail = !showleaderboardDetail"
                  style="margin-right: 3px"
                  >Details<i class="material-icons fs-15" style="margin-left: 3px">{{
                    showleaderboardDetail ? "expand_less" : "expand_more"
                  }}</i></a
                >
              </div>
              <div class="white-bgc card-radius card-shadow card-border leader-card">
                <div class="leader-body scroll-verticle" v-if="showleaderboardDetail === false">
                  <div
                    class="flex leader-top items-center flex-between"
                    v-for="(Leaders, index) in leaderBoardData"
                    :key="index"
                  >
                    <div class="flex" v-if="Object.keys(Leaders).length > 0">
                      <ProfileAvatar
                        style="border: none"
                        :username="Leaders.name.charAt(0)"
                        class="leader-avatar"
                        :bgColor="leaderBg[index] + '!important'"
                      ></ProfileAvatar>
                      <h3
                        class="fs-12 black-text fw-600"
                        style="white-space: nowrap; line-height: 20px"
                      >
                        {{
                          Leaders.name.length > 16
                            ? Leaders.name.slice(0, 16) + "..."
                            : Leaders.name
                        }}
                      </h3>
                    </div>
                    <div v-else style="position: relative; left: 8px; top: 2px">
                      <img :src="require(`../assets/saperator.svg`)" alt="" />
                    </div>
                    <h3 class="fs-12 skyblue-text fw-600 relative" style="left: -15px">
                      {{ Leaders.rank }}
                    </h3>
                  </div>
                </div>
                <div
                  class="leader-body scroll-verticle leader-details"
                  v-if="showleaderboardDetail === true"
                >
                  <div class="leader-top" v-for="(Leaders, index) in leaderBoardData" :key="index">
                    <div
                      class="flex items-center flex-between"
                      v-if="Object.keys(Leaders).length > 0"
                    >
                      <div class="flex">
                        <ProfileAvatar
                          style="border: none"
                          :username="Leaders.name.charAt(0)"
                          class="leader-avatar"
                          :bgColor="leaderBg[index] + '!important'"
                        ></ProfileAvatar>
                        <h3
                          class="fs-12 black-text fw-600"
                          style="white-space: nowrap; line-height: 20px"
                        >
                          {{
                            Leaders.name.length > 16
                              ? Leaders.name.slice(0, 16) + "..."
                              : Leaders.name
                          }}
                        </h3>
                      </div>
                      <h3
                        class="fs-12 skyblue-text fw-600 relative"
                        style="left: -15px; line-height: 20px"
                      >
                        {{ Leaders.rank }}
                      </h3>
                    </div>
                    <div
                      class="flex"
                      style="margin-left: 28px"
                      v-if="Object.keys(Leaders).length > 0"
                    >
                      <p class="flex fs-10 dark-text fw-600 leader-para">
                        <img :src="require(`../assets/Question.svg`)" style="margin-right: 8px" />
                        <span style="opacity: 0.4">{{ Leaders.Attempted }}</span>
                      </p>
                      <!-- <p class="flex fs-10 black-text fw-400 leader-para"><span class="material-icons material-symbols-outlined" style="font-size: 20px; color: rgb(46 43 43);margin-right: 5px;">help</span> {{Leader.SLScore}}</p> -->
                      <p class="flex fs-10 black-text fw-600 leader-para">
                        <img
                          :src="require(`../assets/target (1) 1.svg`)"
                          style="margin-right: 8px"
                        /><span style="opacity: 0.4">{{ Leaders.Accuracy }}</span>
                      </p>
                    </div>
                    <div v-else style="position: relative; left: 8px; top: 2px">
                      <img :src="require(`../assets/saperator.svg`)" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal2 :show="showAvVideo" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <p
            @click="
              showAvVideo = false;
              avVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <iframe
          :src="avVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          allowfullscreen
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
    <Modal2
      :show="showClassSelectionModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="dashboardClassId === null ? ' ' : (showClassSelectionModal = false)"
    >
      <template v-slot:body>
        <h1 class="fs-18 fw-400 dark-text top-header1 center-align">
          Welcome <strong>{{ this.userDetails.Name }} !!</strong>
        </h1>
        <div style="display: flex; justify-content: center">
          <img class="center-align" :src="require(`../assets/school.png`)" />
        </div>
        <p class="center-align modal-select">Select Your Class</p>
        <div class="flex flex-around center-align" style="width: 450px">
          <button
            v-for="cls in getclass"
            :key="`cls${cls.ClassId}`"
            class="modal-button"
            :class="{ selected1: userClassId == cls.ClassId }"
            @click="onClassChange(cls.ClassId, cls.DisplayName)"
          >
            {{ cls.ClassName }}
          </button>
          <!-- <div class="row" v-if="!selectedTopics.length"><div class="col s12 center-align materialize-red-text">Please select a topic</div></div> -->
        </div>
      </template>
    </Modal2>
    <Modal2
      :show="noQuestionModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="noQuestionModal = false"
    >
      <template v-slot:body>
        <div class="no-question-modal">
          <div
            class="flex content-cntr"
            style="margin-bottom: 20px; padding-top: 30px"
            v-if="this.messages.messageTitle === 'All Questions Completed!'"
          >
            <img class="" :src="require(`../assets/allattempted.svg`)" />
          </div>
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px" v-else>
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px">{{
            this.messages.messageTitle
          }}</span>
          <div class="flex content-cntr" style="padding-left: 40px">
            <span
              class="mesage-body fs-13 fw-400 ln-17"
              style="margin-bottom: 25px; white-space: pre-line; width: 424px"
            >
              {{ this.messages.messageBody }}
              <!-- <span class="flex content-cntr fs-13 fw-400 ln-17">You will not be able to resume this session again.</span> -->
            </span>
          </div>
          <div class="flex content-even" v-if="this.messages.buttons.length === 2">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="start()">
              {{ this.messages.buttons[0].text }}
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="noQuestionModal = false">
              {{ this.messages.buttons[1].text }}
            </button>
          </div>
          <div class="flex content-even" v-if="this.messages.buttons.length === 1">
            <button
              class="end-confirm-button fs-13 fw-600 ln-18"
              @click="noQuestionModal = false"
              style="width: 235px"
            >
              {{ this.messages.buttons[0].text }}
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="pendingSessionModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-question-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Resume Pending Session?</span
          >
          <div class="" style="padding-bottom: 23px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You already have a pending session for one or more</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >of the selected chapters. If you start a new session now,</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17">the old session will end.</span>
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="resumePendingSession()">
              Resume Pending
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="startNewPractice()">
              Start New
            </button>
          </div>
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
// import Vue from "vue";
// import VueHighcharts from "vue3-highcharts";
import moment from "moment";
import Flicking from "@egjs/vue3-flicking";
import ProfileAvatar from "vue-profile-avatar";
import PracticeCard from "../components/PracticeCard.vue";
import MobileAPI from "../Mobileapi";
import Multiple from "../assets/Multiple.png";
import Modal2 from "../components/Model2.vue";
import Doubtapi from "../Doubtapi";
import EffortSvg from "../components/EffortSvg";
import AccuracySvg from "../components/AccuracySvg";
import SpeedSvg from "../components/SpeedSvg";
import QuestionIconSvg from "../components/QuestionIconSvg.vue";
import Api from "../Api";

export default {
  data() {
    return {
      subjectCoverageData: [],
      showSubjectList: true,
      chapterwiseFilterList: [],
      tempChapterCardList: [],
      chapterwiseCardsList: [],
      overviewCardList: [],
      getNewStudentDashboardpra: [],
      selfTest: [],
      leaderBoardData: [],
      showleaderboardDetail: false,
      showChapterDetail: false,
      subjectListData: [],
      leaderBg: [],
      avgreport: [],
      randomImage: Multiple,
      avVideoUrl: "",
      showAvVideo: false,
      avreportlength: null,
      selectedSub: "",
      selectedSubIconUrl: "",
      selectSubjectId: null,
      dashboardUserDetails: null,
      dashboardUserId: null,
      dashboardClassId: null,
      dashboardCourseId: null,
      dashboardSubjectId: null,
      dashboardDomainName: null,
      dashboardClassName: "",
      data: [],
      layout: null,
      PlotlyPerformanceSummary: null,
      respCode: null,
      getclass: null,
      activeChapter: null,
      activeSubject: null,
      selfSubjectid: null,
      checkbox: false,
      checkbox1: false,
      isActive: true,
      pauseDate: "05-Aug-2022",
      sessionAccuracy: 56,
      totalQuestion: 105,
      resumeCardColor: "#EBFFE7",
      reportCardColor: "#E3E8F2",
      startCardColor: "#FFECDB",
      startButton: "Start",
      resumeButton: "Resume",
      reportButton: "Report",
      activeIndex: null,
      activeIndex1: null,
      activeIndex2: null,
      getNewStudentDashboardsub: [
        {
          subjectName: "Science",
          subjectId: 1,
        },
        {
          subjectName: "Math",
          subjectId: 2,
        },
        {
          subjectName: "English",
          subjectId: 3,
        },
        {
          subjectName: "Hindi",
          subjectId: 4,
        },
        {
          subjectName: "Social Science",
          subjectId: 5,
        },
      ],
      selectedChapterList: [],
      selectedCourseChapterList: [],
      practiceDashBoardData: [],
      completedPracticeList: [],
      pausePracticeList: [],
      showMore: false,
      isPracticeReady: false,
      isSubjective: null,
      requestBody: {},
      chapterNames: [],
      difficultyLevelId: null,
      userCoursId: null,
      showErrorStatement: false,
      slToken: "",
      profileData: [],
      filterSubjectList: [],
      affiliationCourseId: null,
      noQuestionModal: false,
      messages: [],
      prompts: [],
      questionList: [],
      kscList: [],
      chapterList: [],
      subjectList: [],
      practiceQuestionList: [],
      questionTypeIds: "",
      isStart: false,
      reportData: [],
      showClassSelectionModal: false,
      userDetails: "",
      showClassChangeOption: false,
      index: -1,
      pendingSessionModal: false,
      pendingSessionList: [],
      sessionData: [],
      lpsId: null,
      lpsEndTime: null,
      isPastYear: false,
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#E0E4F0");
    localStorage.removeItem("practiceSelectedSubjectData");
    localStorage.removeItem("isPastYear");
  },
  beforeUnmount() {
    localStorage.removeItem("LpsId");
    localStorage.removeItem("LpsEndTime");
    localStorage.removeItem("DifficultyLevelId");
    localStorage.removeItem("isPastYear");
  },
  // beforeUnmount() {
  //   document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  // },
  activated() {
    this.lpsId = parseInt(this.$route.params.LpsId);
    if (this.lpsId) {
      this.lpsId = parseInt(this.$route.params.LpsId);
      localStorage.setItem("LpsId", this.lpsId);
    } else {
      this.lpsId = parseInt(localStorage.getItem("LpsId"));
    }

    const user = this.$store.getters.user;
    this.userDetails = user;
    this.isActive = true;
    this.showChapterDetail = false;
    this.dashboardUserDetails = this.$route.params.dashboardUserDetails;
    this.isSubjective = 0;

    MobileAPI.getClass(
      {
        UserId: this.userDetails.UserId,
        isPractice: 1,
      },
      (response1) => {
        if (response1.data !== null && response1.data.length > 0) {
          this.getclass = response1.data;
          if (this.getclass.length > 1) {
            this.showClassChangeOption = true;
          }
        }
      },
    );
    if (this.dashboardUserDetails) {
      this.dashboardDomainName = this.$route.params.dashboardDomainName;
      this.dashboardUserId = this.$route.params.dashboardUserId;
      this.dashboardClassId = this.$route.params.dashboardClassId;
      this.dashboardCourseId = this.$route.params.dashboardCourseId;
      this.dashboardSubjectId = this.$route.params.dashboardSubjectId;
      this.selectedSub = this.$route.params.dashboardSubjectName;
      this.selectedSubIconUrl = this.$route.params.dashboardSubjectIcon;
      this.dashboardClassName = this.$route.params.dashboardClassName;
      this.getSubjectCoverageData(
        this.dashboardUserId,
        this.dashboardClassId,
        this.dashboardCourseId,
        this.dashboardSubjectId,
        this.selectedSub,
        this.selectedSubIconUrl,
      );
      const userDetails = {
        domainName: this.dashboardDomainName,
        userId: this.dashboardUserId,
        courseId: this.dashboardCourseId,
        classId: this.dashboardClassId,
        subjectId: this.dashboardSubjectId,
        selectedSub: this.selectedSub,
        selectedSubIcon: this.selectedSubIconUrl,
        className: this.dashboardClassName,
      };
      localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
      const practiceDashboardList = {
        UserId: Number(userDetails.userId),
        ClassId: this.dashboardClassId,
        SubjectId: this.dashboardSubjectId,
        chapterids: 0,
      };
      Doubtapi.getPracticeDashboardList(practiceDashboardList, (response) => {
        if (response.statusCode === 200) {
          this.practiceDashBoardData = response.data;
          this.completedPracticeList = this.practiceDashBoardData.completedSessionList;
          this.pausePracticeList = this.practiceDashBoardData.pausedUserTestSessionList;
        } else if (response.statusCode === 500) {
          this.practiceDashBoardData = [];
          this.completedPracticeList = [];
          this.pausePracticeList = [];
        }
      });
    } else if (this.dashboardUserDetails === undefined) {
      //   const user = this.$store.getters.user;
      Api.getProfile((resp) => {
        this.profileData = resp;
        this.affiliationCourseId = this.profileData.AffiliationCourseId;
        MobileAPI.getClass(
          {
            UserId: this.profileData.UserId,
            isPractice: 0,
          },
          (response1) => {
            if (response1.data !== null && response1.data.length > 0) {
              this.getclass = response1.data;
              if (this.getclass.length > 1) {
                this.showClassSelectionModal = true;
              } else {
                this.userClassId = this.getclass[0].ClassId;
                this.dashboardClassId = this.userClassId;
                this.dashboardClassName = this.getclass[0].DisplayName;
                MobileAPI.getSubjectListForCourse(this.affiliationCourseId, (response) => {
                  this.subjectList = response.data;
                  this.filterSubjectList = this.subjectList.filter((a) => a.IsSelected === true);
                  this.dashboardUserId = this.profileData.UserId;
                  // this.dashboardClassId = this.profileData.ClassId;
                  this.dashboardCourseId = this.profileData.CourseId;
                  if (JSON.parse(localStorage.getItem("practiceSelectedSubjectData")) !== null) {
                    const selectedSubject = JSON.parse(
                      localStorage.getItem("practiceSelectedSubjectData"),
                    );
                    this.dashboardSubjectId = selectedSubject.subjectId;
                    this.selectedSub = selectedSubject.subjectName;
                    this.selectedSubIconUrl = selectedSubject.subjectIcon;
                  } else {
                    this.dashboardSubjectId = this.filterSubjectList[0].SubjectId;
                    this.selectedSub = this.filterSubjectList[0].SubjectName;
                    this.selectedSubIconUrl = this.filterSubjectList[0].subjectIcon;
                  }
                  // this.dashboardClassName = this.profileData.Class;
                  const userDetails = {
                    domainName: this.dashboardDomainName,
                    userId: this.dashboardUserId,
                    courseId: this.dashboardCourseId,
                    classId: this.dashboardClassId,
                    subjectId: this.dashboardSubjectId,
                    selectedSub: this.selectedSub,
                    selectedSubIcon: this.selectedSubIconUrl,
                    className: this.dashboardClassName,
                  };
                  localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
                  this.getSubjectCoverageData(
                    this.dashboardUserId,
                    this.dashboardClassId,
                    this.dashboardCourseId,
                    this.dashboardSubjectId,
                    this.selectedSub,
                    this.selectedSubIconUrl,
                  );
                  const practiceDashboardList = {
                    UserId: Number(this.dashboardUserId),
                    ClassId: this.dashboardClassId,
                    SubjectId: this.dashboardSubjectId,
                    chapterids: 0,
                  };
                  Doubtapi.getPracticeDashboardList(practiceDashboardList, (practiceDashBoard) => {
                    if (practiceDashBoard.statusCode === 200) {
                      this.practiceDashBoardData = practiceDashBoard.data;
                      this.completedPracticeList = this.practiceDashBoardData.completedSessionList;
                      this.pausePracticeList = this.practiceDashBoardData.pausedUserTestSessionList;
                    } else if (practiceDashBoard.statusCode === 500) {
                      this.practiceDashBoardData = [];
                      this.completedPracticeList = [];
                      this.pausePracticeList = [];
                    }
                  });
                });
              }
            }
          },
        );
      });
    } else {
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.getSubjectCoverageData(
        userDetail.userId,
        userDetail.classId,
        userDetail.courseId,
        userDetail.subjectId,
        userDetail.selectedSub,
        userDetail.selectedSubIcon,
      );
      const practiceDashboardList = {
        UserId: Number(userDetail.userId),
        ClassId: userDetail.classId,
        SubjectId: userDetail.subjectId,
        chapterids: 0,
      };
      Doubtapi.getPracticeDashboardList(practiceDashboardList, (response) => {
        if (response.statusCode === 200) {
          this.practiceDashBoardData = response.data;
          this.completedPracticeList = this.practiceDashBoardData.completedSessionList;
          this.pausePracticeList = this.practiceDashBoardData.pausedUserTestSessionList;
        } else if (response.statusCode === 500) {
          this.practiceDashBoardData = [];
          this.completedPracticeList = [];
          this.pausePracticeList = [];
        }
      });
      this.dashboardDomainName = userDetail.domainName;
    }
    this.lpsEndTime = this.$route.params.LpsEndTime;
    if (this.lpsEndTime) {
      this.lpsEndTime = this.$route.params.LpsEndTime;
      localStorage.setItem("LpsEndTime", this.lpsEndTime);
    } else {
      this.lpsEndTime = localStorage.getItem("LpsEndTime");
    }
    if (localStorage.getItem("LpsEndTime")) {
      setInterval(() => {
        this.compareDateTime();
      }, 1000);
    }

    if (this.userDetails.CourseId === 1) {
      this.difficultyLevelId = parseInt(this.$route.params.DifficultyLevel);
      if (parseInt(this.$route.params.DifficultyLevel) === 2) {
        this.checkbox1 = true;
      }
    }
    if (this.userDetails.CourseId !== 1) {
      this.LpsQuestionType = this.$route.params.LpsQuestionType;
      if (this.LpsQuestionType) {
        this.checkbox = !!this.LpsQuestionType.includes("2");
      }
    }

    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
    ];
  },
  components: {
    // Avatar,
    ProfileAvatar,
    // carousel,
    // Plotly,
    EffortSvg,
    AccuracySvg,
    QuestionIconSvg,
    SpeedSvg,
    Modal2,
    Flicking,
    PracticeCard,
    // VueHighcharts,
  },
  methods: {
    compareDateTime() {
      const currentTime = moment(new Date()).format("HH:mm");
      if (currentTime >= this.lpsEndTime) {
        this.lpsId = null;
        localStorage.removeItem("LpsId");
        localStorage.removeItem("LpsEndTime");
      }
    },
    start() {
      this.isStart = true;
      this.noQuestionModal = false;
      this.startPractice();
    },
    onClassChange(classId, className) {
      if (this.dashboardClassId === classId) {
        this.showClassSelectionModal = false;
      } else {
        this.userClassId = classId;
        this.dashboardClassId = this.userClassId;
        this.dashboardClassName = className;
        const data = {
          UserId: this.userDetails.UserId,
          ClassId: this.userClassId,
        };
        this.updateClassofSTudent(data);
        MobileAPI.getSubjectListForCourse(this.affiliationCourseId, (response) => {
          this.subjectList = response.data;
          this.filterSubjectList = this.subjectList.filter((a) => a.IsSelected === true);
          this.dashboardUserId = this.profileData.UserId;
          // this.dashboardClassId = this.profileData.ClassId;
          this.dashboardCourseId = this.profileData.CourseId;
          this.dashboardSubjectId = this.filterSubjectList[0].SubjectId;
          this.selectedSub = this.filterSubjectList[0].SubjectName;
          this.selectedSubIconUrl = this.filterSubjectList[0].subjectIcon;
          // this.dashboardClassName = this.profileData.Class;
          const userDetails = {
            domainName: this.dashboardDomainName,
            userId: this.dashboardUserId,
            courseId: this.dashboardCourseId,
            classId: this.dashboardClassId,
            subjectId: this.dashboardSubjectId,
            selectedSub: this.selectedSub,
            selectedSubIcon: this.selectedSubIconUrl,
            className: this.dashboardClassName,
          };
          localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
          this.getSubjectCoverageData(
            this.dashboardUserId,
            this.dashboardClassId,
            this.dashboardCourseId,
            this.dashboardSubjectId,
            this.selectedSub,
            this.selectedSubIconUrl,
          );
          const practiceDashboardList = {
            UserId: Number(this.dashboardUserId),
            ClassId: this.dashboardClassId,
            SubjectId: this.dashboardSubjectId,
            chapterids: 0,
          };
          Doubtapi.getPracticeDashboardList(practiceDashboardList, (practiceDashBoard) => {
            if (practiceDashBoard.statusCode === 200) {
              this.practiceDashBoardData = practiceDashBoard.data;
              this.completedPracticeList = this.practiceDashBoardData.completedSessionList;
              this.pausePracticeList = this.practiceDashBoardData.pausedUserTestSessionList;
            } else if (practiceDashBoard.statusCode === 500) {
              this.practiceDashBoardData = [];
              this.completedPracticeList = [];
              this.pausePracticeList = [];
            }
          });
        });
      }
    },
    updateClassofSTudent(data) {
      MobileAPI.updateClass(data, (response) => {
        this.class = response.data;
        this.showClassSelectionModal = false;
      });
    },
    changeClass() {
      MobileAPI.getClass(
        {
          UserId: this.userDetails.UserId,
          isPractice: 1,
        },
        (response1) => {
          if (response1.data !== null && response1.data.length > 0) {
            this.getclass = response1.data;
            if (this.getclass.length > 1) {
              this.showClassSelectionModal = true;
            }
          }
        },
      );
    },
    allPractice() {
      this.isActive = true;
    },
    toggleCheckbox() {
      this.checkbox = !this.checkbox;
    },
    formateTime(value) {
      if (value) {
        return moment(value).format("DD MMMM YYYY");
      }
      return 0;
    },
    getSubjectCoverageData(userId, classId, courseId, subjectId, selectedSub, selectedSubIcon) {
      this.$store.dispatch("showLoader", true);
      const isPractice = 1;
      MobileAPI.getSubjectCoverageData(
        {
          userId,
          courseId,
          classId,
          subjectId,
          isPractice,
          // userId: this.dashboardUserId,
          // courseId: this.dashboardCourseId,
          // classId: this.dashboardClassId,
          // subjectId: this.dashboardSubjectId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          // localStorage.setItem("subjectCoverageData", JSON.stringify(response.data));
          // this.subjectCoverageData = JSON.parse(localStorage.getItem("subjectCoverageData"));

          this.subjectCoverageData = response.data;
          this.subjectListData = this.subjectCoverageData.subjectsList;
          this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters;
          this.chapterwiseCardsList = this.subjectCoverageData.chapterWiseCards.cards;
          this.tempChapterCardList = this.subjectCoverageData.chapterWiseCards.cards;
          this.overviewCardList = this.subjectCoverageData.overviewCards;
          this.getNewStudentDashboardpra = this.subjectCoverageData.recommendations.Practice;
          this.selfTest = this.subjectCoverageData.recommendations.SelfTest;
          this.leaderBoardData = this.subjectCoverageData.leaderboard;
          this.selectedSub = selectedSub;
          this.selectedSubIconUrl = selectedSubIcon;
          this.dashboardUserId = userId;
          this.dashboardCourseId = courseId;
          this.dashboardClassId = classId;
          this.dashboardSubjectId = subjectId;
          if (Object.keys(this.subjectCoverageData.chapterWiseCards).length > 0) {
            this.$store.dispatch("showLoader", true);
            MobileAPI.getStudentAvgReport(
              this.dashboardUserId,
              this.dashboardSubjectId,
              (resp) => {
                this.avgreport = resp.data;
                this.avreportlength = resp.data.length;
                this.$store.dispatch("showLoader", false);
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
            if (this.chapterwiseFilterList != null) {
              const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                (a) => a.isDefault,
              );
              if (defaultCard != null) {
                this.index = Object.values(this.chapterwiseFilterList).findIndex(
                  (a) => a.isDefault,
                );
                this.filterChapterCards(defaultCard[0], this.index);
              }
            }
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectSubject(subjectData) {
      this.subjectCoverageData = [];
      this.selectedChapterList = [];
      this.selectedCourseChapterList = [];
      this.chapterNames = [];
      this.selectedSub = subjectData.subjectName;
      this.showSubjectList = true;
      this.selectSubjectId = subjectData.subjectId;
      localStorage.setItem("practiceSelectedSubjectData", JSON.stringify(subjectData));
      this.dashboardSubjectId = this.selectSubjectId;
      this.selectedSubIconUrl = subjectData.subjectIcon;
      const tempUserDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      const practiceDashboardList = {
        UserId: Number(tempUserDetail.userId),
        ClassId: Number(tempUserDetail.classId),
        SubjectId: this.dashboardSubjectId,
        chapterids: 0,
      };
      Doubtapi.getPracticeDashboardList(practiceDashboardList, (response) => {
        if (response.statusCode === 200) {
          this.practiceDashBoardData = response.data;
          this.completedPracticeList = this.practiceDashBoardData.completedSessionList;
          this.pausePracticeList = this.practiceDashBoardData.pausedUserTestSessionList;
        } else if (response.statusCode === 500) {
          this.practiceDashBoardData = [];
          this.completedPracticeList = [];
          this.pausePracticeList = [];
        }
      });
      this.dashboardDomainName = tempUserDetail.domainName;
      this.dashboardUserId = tempUserDetail.userId;
      this.dashboardCourseId = tempUserDetail.courseId;
      this.dashboardClassId = tempUserDetail.classId;
      this.dashboardClassName = tempUserDetail.className;
      localStorage.removeItem("subjectCoverageUserDetails");
      const userDetails = {
        domainName: this.dashboardDomainName,
        userId: this.dashboardUserId,
        courseId: this.dashboardCourseId,
        classId: this.dashboardClassId,
        subjectId: this.dashboardSubjectId,
        selectedSub: this.selectedSub,
        selectedSubIcon: this.selectedSubIconUrl,
        className: this.dashboardClassName,
      };
      localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.dashboardDomainName = userDetail.domainName;
      if (this.dashboardSubjectId && this.selectSubjectId) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getSubjectCoverageData(
          {
            userId: this.dashboardUserId,
            courseId: this.dashboardCourseId,
            classId: this.dashboardClassId,
            subjectId: this.dashboardSubjectId,
            isPractice: 1,
          },
          (response) => {
            this.$store.dispatch("showLoader", false);
            this.subjectCoverageData = response.data;
            this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters;
            this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters;
            this.chapterwiseCardsList = this.subjectCoverageData.chapterWiseCards.cards;
            this.tempChapterCardList = this.subjectCoverageData.chapterWiseCards.cards;
            this.overviewCardList = this.subjectCoverageData.overviewCards;
            this.getNewStudentDashboardpra = this.subjectCoverageData.recommendations.Practice;
            this.selfTest = this.subjectCoverageData.recommendations.SelfTest;
            this.leaderBoardData = this.subjectCoverageData.leaderboard;
            if (Object.keys(this.subjectCoverageData.chapterWiseCards).length > 0) {
              this.$store.dispatch("showLoader", true);
              MobileAPI.getStudentAvgReport(
                this.dashboardUserId,
                this.selectSubjectId,
                (resp) => {
                  this.avgreport = resp.data;
                  this.avreportlength = resp.data.length;
                  this.$store.dispatch("showLoader", false);
                },
                () => {
                  this.$store.dispatch("showLoader", false);
                },
              );
              if (this.chapterwiseFilterList != null) {
                const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                  (a) => a.isDefault,
                );
                if (defaultCard != null) {
                  this.index = Object.values(this.chapterwiseFilterList).findIndex(
                    (a) => a.isDefault,
                  );
                  this.filterChapterCards(defaultCard[0], this.index);
                }
              }
            }
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      }
    },
    filterChapterCards(chapterFilter, index) {
      const sortArray = (arr1, arr2) => {
        arr2.sort((a, b) => {
          const aKey = a.courseChapterId;
          const bKey = b.courseChapterId;
          return arr1.indexOf(aKey) - arr1.indexOf(bKey);
        });
      };
      sortArray(chapterFilter.order, this.chapterwiseCardsList);
      Object.values(this.chapterwiseFilterList).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.isDefault = false;
      });
      Object.values(this.chapterwiseFilterList)[index].isDefault = true;
    },
    watchVideo(videoUrl) {
      this.avVideoUrl = videoUrl;
      this.showAvVideo = true;
    },
    onClickOutside() {
      if (this.showSubjectList === false) {
        this.showSubjectList = true;
      }
    },
    onChapterSelect(chapterId, courseChapterId, chapterName) {
      this.questionTypeList = [];
      if (this.selectedChapterList.includes(chapterId)) {
        const index = this.selectedChapterList.indexOf(chapterId);
        const index1 = this.selectedCourseChapterList.indexOf(courseChapterId);
        const index2 = this.chapterNames.indexOf(chapterName);
        this.selectedChapterList.splice(index, 1);
        this.selectedCourseChapterList.splice(index1, 1);
        this.chapterNames.splice(index2, 1);
      } else if (this.selectedChapterList.length <= 2) {
        this.selectedChapterList.push(chapterId);
        this.selectedCourseChapterList.push(courseChapterId);
        this.chapterNames.push(chapterName);
      } else {
        this.selectedChapterList.splice(2, 1);
        this.selectedCourseChapterList.splice(2, 1);
        this.chapterNames.splice(2, 1);
        // const index = this.selectedChapterList.indexOf(chapterId);
        this.selectedChapterList.push(chapterId);
        this.selectedCourseChapterList.push(courseChapterId);
        this.chapterNames.push(chapterName);
      }
      if (this.selectedCourseChapterList.length > 0) {
        this.isPracticeReady = true;
      } else {
        this.isPracticeReady = false;
      }
    },
    resumePendingSession() {
      this.pendingSessionModal = false;
      if (this.pendingSessionList.length > 1) {
        this.sessionData = this.pendingSessionList[0];
        this.pendingSessionList.splice(0, 1);
        this.pendingSessionList.forEach((obj) => {
          this.slToken = localStorage.getItem("SLToken");
          const endsession = {
            userTestSessionId: obj.userTestSessionId,
            serverToken: String(this.slToken),
          };
          MobileAPI.Endsession(endsession, (endSession) => {
            this.sessionEnded = endSession;
          });
        });
        this.resumePracticeSession(this.sessionData);
      } else if (this.pendingSessionList.length === 1) {
        this.sessionData = this.pendingSessionList[0];
        this.resumePracticeSession(this.sessionData);
      }
    },
    checkForPendingSession() {
      if (this.isSubjective === 0) {
        MobileAPI.getPendingSessionData(
          {
            userId: this.userDetails.UserId,
            courseChapterIds: this.selectedCourseChapterList.join(","),
          },
          (response) => {
            this.pendingSessionList = response.data;
            if (this.pendingSessionList.length > 0) {
              this.pendingSessionModal = true;
            } else if (this.pendingSessionList.length === 0) {
              this.startPracticeSession();
            }
          },
        );
      } else {
        this.startPracticeSession();
      }
    },
    startNewPractice() {
      this.slToken = localStorage.getItem("SLToken");
      this.pendingSessionList.forEach((obj) => {
        const endsession = {
          userTestSessionId: obj.userTestSessionId,
          serverToken: String(this.slToken),
        };
        MobileAPI.Endsession(endsession, (endSession) => {
          this.sessionEnded = endSession;
        });
      });
      this.startPracticeSession();
    },
    startPracticeSession() {
      this.$store.dispatch("showLoader", true);
      this.showErrorStatement = false;
      this.pendingSessionModal = false;
      if (this.checkbox === true) {
        this.questionTypeIds = "2";
        this.isSubjective = 1;
      } else if (this.checkbox === false) {
        this.questionTypeIds = "1,3,4,5";
        this.isSubjective = 0;
      }
      const user = this.$store.getters.user;
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.userCourseId = Number(userDetail.courseId);
      if (this.userCourseId !== 1) {
        const data = {
          courseChapterIds: String(this.selectedCourseChapterList),
          questionTypeIds: this.questionTypeIds,
          questionLevelIds: "1,2",
          isPastYear: this.isPastYear === true ? 1 : 0,
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.practiceQuestionList = response.data;
          this.messages = this.practiceQuestionList.messages;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.prompts = this.practiceQuestionList.prompts;
            // this.messages = this.practiceQuestionList.messages;
            if (this.messages.length !== 0) {
              this.messages = this.messages[0];
              this.noQuestionModal = true;
            } else if (this.messages.length === 0) {
              this.requestBody = {
                subjectId: Number(userDetail.subjectId),
                topicId: 0,
                difficultyLevel: 1,
                courseId: Number(userDetail.courseId),
                courseChapterIdList: this.selectedCourseChapterList,
                chapterIdList: this.selectedChapterList,
                lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
              };
              MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
                this.$store.dispatch("showLoader", false);
                if (practice.responseCode === 200) {
                  this.USessionId = practice.data[0].UserTestSessionId;
                  if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                    this.$router.push({
                      name: "PracticeSession",
                      params: {
                        questionList: JSON.stringify(this.questionList),
                        kscList: JSON.stringify(this.kscList),
                        chapterList: JSON.stringify(this.chapterList),
                        subjectList: JSON.stringify(this.subjectList),
                        messages: JSON.stringify(this.messages),
                        prompts: JSON.stringify(this.prompts),
                        UserTestSessionId: this.USessionId,
                        coureId: userDetail.courseId,
                        subjectName: userDetail.selectedSub,
                        subjectId: userDetail.subjectId,
                        className: userDetail.className,
                        isSubjective: this.isSubjective,
                        isLp3s: this.lpsId ? 1 : 0,
                        LpsId: this.lpsId ? this.lpsId : 0,
                        LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                        isPastYear: this.isPastYear === true ? 1 : 0,
                        isDppSession: 0,
                      },
                    });
                  }
                }
              });
            }
          } else if (this.practiceQuestionList.questionsList.length === 0) {
            this.messages = this.messages[0];
            this.noQuestionModal = true;
          }
        });
      } else if (this.userCourseId === 1) {
        if (this.checkbox1 === true) {
          this.difficultyLevelId = 2;
        } else if (this.checkbox1 === false) {
          this.difficultyLevelId = 1;
        }
        const data = {
          courseChapterIds: String(this.selectedCourseChapterList),
          questionTypeIds: this.questionTypeIds,
          questionLevelIds: this.difficultyLevelId,
          isPastYear: this.isPastYear === true ? 1 : 0,
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.practiceQuestionList = response.data;
          this.messages = this.practiceQuestionList.messages;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.prompts = this.practiceQuestionList.prompts;
            if (this.messages.length !== 0) {
              this.messages = this.messages[0];
              this.noQuestionModal = true;
            } else if (this.messages.length === 0) {
              this.requestBody = {
                subjectId: Number(userDetail.subjectId),
                topicId: 0,
                difficultyLevel: 1,
                courseId: Number(userDetail.courseId),
                courseChapterIdList: this.selectedCourseChapterList,
                chapterIdList: this.selectedChapterList,
                lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
              };
              MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
                this.$store.dispatch("showLoader", false);
                if (practice.responseCode === 200) {
                  this.USessionId = practice.data[0].UserTestSessionId;
                  if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                    this.$router.push({
                      name: "PracticeSession",
                      params: {
                        questionList: JSON.stringify(this.questionList),
                        kscList: JSON.stringify(this.kscList),
                        chapterList: JSON.stringify(this.chapterList),
                        subjectList: JSON.stringify(this.subjectList),
                        messages: JSON.stringify(this.messages),
                        prompts: JSON.stringify(this.prompts),
                        UserTestSessionId: this.USessionId,
                        coureId: userDetail.courseId,
                        subjectName: userDetail.selectedSub,
                        subjectId: userDetail.subjectId,
                        className: userDetail.className,
                        isSubjective: this.isSubjective,
                        isLps: this.lpsId ? 1 : 0,
                        LpsId: this.lpsId ? this.lpsId : 0,
                        LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                        isPastYear: this.isPastYear === true ? 1 : 0,
                        isDppSession: 0,
                      },
                    });
                  }
                }
              });
            }
          } else if (this.practiceQuestionList.questionsList.length === 0) {
            this.messages = this.practiceQuestionList.messages[0];
            this.noQuestionModal = true;
          }
        });
      }
    },
    startPractice() {
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));

      this.noQuestionModal = false;
      this.requestBody = {
        subjectId: Number(userDetail.subjectId),
        topicId: 0,
        difficultyLevel: 1,
        courseId: Number(userDetail.courseId),
        courseChapterIdList: this.selectedCourseChapterList,
        chapterIdList: this.selectedChapterList,
        lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
      };
      MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
        this.$store.dispatch("showLoader", false);
        if (practice.responseCode === 200) {
          this.USessionId = practice.data[0].UserTestSessionId;
          if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
            if (this.userCourseId !== 1) {
              this.$router.push({
                name: "PracticeSession",
                params: {
                  questionList: JSON.stringify(this.questionList),
                  kscList: JSON.stringify(this.kscList),
                  chapterList: JSON.stringify(this.chapterList),
                  subjectList: JSON.stringify(this.subjectList),
                  messages: JSON.stringify(this.messages),
                  prompts: JSON.stringify(this.prompts),
                  UserTestSessionId: this.USessionId,
                  coureId: userDetail.courseId,
                  subjectName: userDetail.selectedSub,
                  subjectId: userDetail.subjectId,
                  className: userDetail.className,
                  isSubjective: this.isSubjective,
                  isLps: this.lpsId ? 1 : 0,
                  LpsId: this.lpsId ? this.lpsId : 0,
                  LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                  isPastYear: this.isPastYear === true ? 1 : 0,
                },
              });
            } else {
              this.$router.push({
                name: "PracticeSession",
                params: {
                  questionList: JSON.stringify(this.questionList),
                  kscList: JSON.stringify(this.kscList),
                  chapterList: JSON.stringify(this.chapterList),
                  subjectList: JSON.stringify(this.subjectList),
                  messages: JSON.stringify(this.messages),
                  prompts: JSON.stringify(this.prompts),
                  UserTestSessionId: this.USessionId,
                  coureId: userDetail.courseId,
                  subjectName: userDetail.selectedSub,
                  subjectId: userDetail.subjectId,
                  className: userDetail.className,
                  isSubjective: this.isSubjective,
                  isLps: this.lpsId ? 1 : 0,
                  LpsId: this.lpsId ? this.lpsId : 0,
                  LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                  isPastYear: this.isPastYear === true ? 1 : 0,
                },
              });
            }
          }
        }
      });
    },
    resumePracticeSession(chapter) {
      const data = {
        lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
      };
      console.log(">>>> 1 >>>> Resuming session in practice home", chapter.userTestSessionId);
      MobileAPI.resumePracticeSession(chapter.userTestSessionId, data, (response) => {
        this.resumeSessionData = response.data;
        if (this.resumeSessionData.length === 0) {
          let beginDate = moment();
          beginDate = beginDate.format("YYYY-MM-DD HH:MM");
          this.startDate = beginDate;
          this.slToken = localStorage.getItem("SLToken");
          const endsession = {
            userTestSessionId: chapter.userTestSessionId,
            serverToken: String(this.slToken),
          };
          clearTimeout(this.timer);
          this.$store.dispatch("showLoader", true);
          console.log(">>>> 1 >>>> Ending session in practice home");
          MobileAPI.Endsession(endsession, (endSession) => {
            if (endSession.responseCode === 200) {
              this.$router.push({
                name: "PracticeSessionNewReport",
                params: {
                  UserTestSessionId: chapter.userTestSessionId,
                  Name: this.$store.getters.user.Name,
                  SubjectName: chapter.subjectName,
                  Chapters: chapter.chapterName,
                  StartedOn: chapter.startedOn,
                  fromPractice: 1,
                },
              });
            }
          });
          this.$store.dispatch("showLoader", false);
        } else {
          const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
          this.$router.push({
            name: "PracticeSession",
            params: {
              UserTestSessionId: chapter.userTestSessionId,
              coureId: userDetail.courseId,
              subjectName: userDetail.selectedSub,
              subjectId: userDetail.subjectId,
              className: userDetail.className,
              chapters: chapter.chapterName,
              LpsId: this.lpsId ? this.lpsId : 0,
              isSubjective: 0,
              isLps: this.lpsId ? 1 : 0,
              isPastYear: 0,
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

.subject-coverage {
  font-family: "Open Sans" !important;
  margin-top: 15px;
}

/* Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /*Standard */
  margin: 0;
}

@font-face {
  font-family: PackMan;
  src: url(../font/pac-font.ttf);
}

* {
  box-sizing: border-box;
}

.mesage-body {
  width: 200px;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.dark-text {
  color: #333333 !important;
}

.black-text2 {
  color: #242c3c;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.skyblue-text2 {
  color: #5c8eff !important;
}

.purple-text {
  color: #642c90 !important;
}

.purple-text2 {
  color: #9470c1 !important;
}

.orange-text {
  color: #f59e00 !important;
}

.light-gray-text {
  color: #666666;
}

.white-bgc {
  background: #fff;
}

.orange-bgc {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.lightPink-bgc {
  background: #ffecdb;
}

.lightGreen-bgc {
  background: #ebffe7;
}

.test-bgc {
  background: linear-gradient(98.34deg, #e9ccff 11.31%, #f4e6ff 88.13%);
}

.purple-bgc {
  background: #642c90;
}

.card-border {
  border: 1px solid #e3e3e3;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-radius {
  border-radius: 5px;
}

.card-radius-2 {
  border-radius: 6px;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.block {
  display: block;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-right {
  justify-content: right;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.max-content {
  width: max-content;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.m-0 {
  margin: 0;
}

.mt-31 {
  margin-top: 31px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-24 {
  margin-bottom: 24px;
}

.w-105 {
  width: 105px;
}

.w-69 {
  width: 69px;
}

.w-75 {
  width: 75px;
}

.p-15 {
  padding: 15px;
}

.ln-18 {
  line-height: 18px !important;
}

.ln-20 {
  line-height: 20px !important;
}

.ln-16 {
  line-height: 16px !important;
}

.ln-17 {
  line-height: 17px !important;
}

.ln-27 {
  line-height: 27.24px !important;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.flex-around {
  justify-content: space-around !important;
}

.recommend-top .scroll-horizontal::-webkit-scrollbar {
  height: 0px;
}

::-webkit-scrollbar {
  display: none;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 3px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.select-box.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%) !important;
  border-radius: 3px;
  border: none;
  outline: none;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
}

.gray-btn {
  max-width: 103px;
  min-width: 42px;
  margin-bottom: 5px !important;
  height: 22px;
  background: #f8f8f8;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #333333;
  margin: 0 8px;
  opacity: 0.65;
  cursor: pointer;
  white-space: nowrap;
}

/* Custom CSS */
/* Left Section */
.left-section {
  max-width: 355px;
  min-width: 310px;
  padding: 15px 20px 5px;
  height: max-content;
  margin-bottom: 15px;
}

.secondary-header {
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #642c90;
}

.subject-select {
  width: 240px;
  height: 67px;
  position: relative;
  margin-top: 12px;
  padding: 10px 16px;
}

.subject-select .subject-img {
  position: absolute;
  right: 17px;
  top: 13px;
  opacity: 0.1;
  width: 55px;
}

.start-practice-button {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  opacity: 0.5;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  height: 30.508474349975586px;
  width: 144px;
}

.top-header1 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 10px;
}

.modal-select {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  text-align: center;
  color: #642c90;
}

.modal-button.selected1 {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.modal-button {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  cursor: pointer;
  border-radius: 6px;
  width: 128px;
  height: 50px;
  margin-bottom: 10px;
}

.ready {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%) !important;
  opacity: 1 !important;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  height: 30.508474349975586px;
  width: 144px;
  cursor: pointer !important;
}

.subject-list {
  width: 100%;
  max-height: 229px;
  position: absolute;
  left: 0;
  z-index: 20;
  top: -1px;
  background: #ffffff;
  border: 1px solid #333333;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.15);
  border-radius: 5px;
  padding: 10px 0;
}

.subject-list a {
  padding-left: 15px;
  margin-bottom: 10px;
}

.sublist p:first-child(1) {
  margin-top: 10px;
}

.sublist p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 8px 15px;
}

.sublist p:hover {
  background: #f6f8fb;
}

.chapter-wise-detail {
  margin-top: 22px;
  margin-bottom: 15px;
}

.chapter-wise-detail h2 {
  margin-bottom: 10px;
}

.instruction-line {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  color: #333333;
  margin-bottom: 6px;
}

.chapter-wise-detail .gray-btn.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.chapter-wise-detail .gray-btn1.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.chapter-wise-content {
  width: 100%;
  max-height: calc(100vh - 485px);
}

.chapter-wise-card {
  padding: 8px 12px;
  /* height: 50px; */
}

.progress-value {
  margin-top: 14px;
  margin-bottom: 8px;
}

.progress1 {
  position: relative;
  max-width: 100%;
  height: 4px;
  background: #f2f3f8;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 3px;
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: #ee9b05;
}

/*Middle section*/
.middle-section {
  max-width: 605px;
  min-width: 300px;
  width: 100%;
  margin: 0 20px;
}

.no-question-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}

.middle-card-box {
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  margin-top: 11px;
}

.card-box {
  position: relative;
  max-width: 168px;
  height: 90px;
  background: #faf5ff;
  padding: 10px 15px 15px;
  color: #9470c1;
}

.card-box h3 {
  margin-bottom: 9px;
}

.selected {
  border: 1px solid #37841c !important;
}

.loadMoreDiv {
  max-height: 210px !important;
  overflow: hidden;
}

.card-box::after {
  /*position: absolute;
    content: "";
    right: -70%;
    bottom: -45%;
    background: url('../assets/bookmark-page\ 1.svg') no-repeat;
    z-index: 10;
    width: 100%;
    height: 100%;*/
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: url("../assets/bookmark-page\ 1.svg") no-repeat;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-position: 95% 83% !important;
  z-index: 0;
}

.card-box:nth-child(2) {
  background: #f2f6ff;
  color: #5c8eff;
}

.card-box:nth-child(2):after {
  background: url("../assets/Question\ Att.svg") no-repeat;
}

.card-box:nth-child(3) {
  background: #f4faff;
  color: #427ca9;
}

.card-box:nth-child(3):after {
  background: url("../assets/hourglass\ 4.svg") no-repeat;
}

.card-box:nth-child(4) {
  background: #fffaf0;
  color: #ee9b05;
}

.card-box:nth-child(4):after {
  background: url("../assets/success\ 1.svg") no-repeat;
}

.card-box:nth-child(5) {
  background: #fff4f5;
  color: #ef727a;
}

.card-box:nth-child(5):after {
  background: url("../assets/aim\ 1.svg") no-repeat;
}

.card-box:nth-child(6) {
  background: #f3fffc;
  color: #36af8d;
}

.card-box:nth-child(6):after {
  background: url("../assets/Speed2.svg") no-repeat;
}

/*Right Section */
.right-section {
  width: 216px;
  margin-bottom: 10px;
}

/* Practice */
.recommend-section {
  max-width: 570px;
  min-width: 220px;
  padding: 0 15px;
}

.recommend-box {
  width: 170px;
  height: 80px;
  border-radius: 6px;
}

.recommendation {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 16px 15px;
  margin-bottom: 15px;
  width: 100%;
  min-height: 146px;
}

.recommendation .Plotly {
  /* font-size: 55px; */
  margin-top: 15px;
}

.recommendation:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recommendation-content {
  margin-top: 9px;
}

.completedSession-content {
  margin-top: 9px;
  display: flex;
  flex-wrap: wrap;
}

.completedSession-content .main-recommendation-box {
  width: 170px;
  margin-right: 15px;
}

.recommendation-content .main-recommendation-box {
  width: 170px;
  margin-right: 15px;
  /* margin-bottom: 15px; */
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.recommendation-box .progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar1.yellow {
  background: yellow;
}

.progress-bar1.blue {
  background: blue;
}

.progress-bar1.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.assignment .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.assignment .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.recommend-box .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.recommend-box:hover .yellow-btn {
  opacity: 1;
  z-index: 2;
}

/*.practice .recommendation-box:hover .yellow-btn {
    opacity: 1;
}*/
.gray-btn1 {
  height: 21px;
  background: #f8f8f8;
  border: 1px solid #d6daf7;
  border-radius: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  opacity: 0.65;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

/*Leaderboard */
.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.leaderboard .leader-body {
  max-height: 365px;
}

.leaderboard .leader-body.leader-details .leader-top {
  padding: 6px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body.leader-details .leader-top:first-child {
  padding-top: 0;
}

/*
.leaderboard .leader-body .leader-top {
    padding: 10px 0px;
    border-bottom: 1px solid #E0E4F0;
}*/

.leaderboard .leader-body .leader-top:first-child {
  padding-top: 0px;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
  padding: 10px 0px 0px;
}

.leader-para {
  line-height: 20px;
  margin-right: 16px;
  width: 50px;
}

.leader-card {
  padding: 22px 15px;
  position: relative;
  max-height: 408px;
}

.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  background: #fc8686 !important;
  color: white !important;
  margin-right: 10px;
}

.leaderboard_reward-section {
  min-width: 216px;
}

/*.leaderboard {
    margin-top: 18px;
}*/

.leaderboard-content {
  padding: 16px 15px 4px;
  max-height: 470px;
  height: 100%;
}

.leaderboard .leader-body .leader-top {
  padding: 8px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
}

.leader-para {
  line-height: 20px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

/*Coverage report*/
.slideshow-container {
  min-width: 100%;
  height: 203px;
  background: #5a5fe6;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
  min-width: auto;
  padding: 2px 0;
  height: auto;
  overflow: hidden;
}

.slideshow-container:hover .yellow-btn {
  opacity: 1;
  z-index: 2;
}

.class-image {
  position: absolute;
  right: -10px;
  top: 60px;
  z-index: 0;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f3f3f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f3f3f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.increaseHeight {
  height: 105px;
}

.slider.round:before {
  border-radius: 50%;
}

.question-header {
  height: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.loadMoreBtn {
  /* padding: 5px; */
  border-radius: 10px;
  height: 20px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  width: 85px;
  background: #4787f3;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #eeeeee;
  color: #333333 !important;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  /* left: -14px; */
  bottom: 20px;
  text-align: center;
  width: 200px;
  border: 1px solid #e1e1e1;
  font-size: 11px;
}

.tooltip .tooltiptext.practice {
  right: -143px !important;
  color: #333333 !important;
  bottom: 28px !important;
}

.tooltip .tooltiptext.video {
  left: -18px !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

/*Media Query*/
@media screen and (max-width: 830px) {
  .completedSession-content {
    /* display: grid !important; */
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 12px;
    /* place-items: center; */
  }

  .completedSession-content .main-recommendation-box {
    max-width: 150px !important;
    width: 100% !important;
    margin-right: 0px !important;
  }

  .recommendation-content .main-recommendation-box {
    margin-right: 12px;
  }
}

@media screen and (max-width: 992px) {
  .subject-coverage {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    max-width: 565px;
    width: 95%;
    margin-bottom: 15px;
  }

  .middle-section {
    width: 95%;
  }

  .card-content {
    grid-template-columns: repeat(3, 1fr);
  }

  .right-section {
    width: 100%;
    margin-bottom: 10px;
    max-width: 605px;
  }

  .slideshow-container {
    margin-left: 15px;
  }

  .CoverageReportTitle {
    margin-left: 15px;
  }

  .leaderboard_reward-section {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .subject-coverage {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    max-width: 565px;
    width: 95%;
    margin-bottom: 15px;
  }

  .middle-section {
    width: 95%;
  }

  .card-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .videoModal {
    width: 100% !important;
    height: auto !important;
    padding: 10px 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }

  .recommendation {
    background: #ffffff;
    border: 1px solid #e0e4f0;
    box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
    border-radius: 5px;
    padding: 16px 12px;
    margin-bottom: 15px;
    width: 100%;
    min-height: 146px;
  }
}

/* .js-plotly-plot {
    display: none !important;
} */

@media screen and (min-width: 992px) {
  .subject-coverage-responsive {
    height: 88vh;
  }

  .chapter-wise-content {
    max-height: calc(100vh - 485px) !important;
  }

  .middle-section {
    max-height: calc(100vh - 88px);
    overflow-y: scroll;
    border-radius: 5px;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .avCard {
    max-width: 605px;
    width: 100% !important;
  }

  .avCardHeight {
    height: 151px !important;
  }
}

@media screen and (max-width: 992px) {
  .middle-section {
    max-width: 565px !important;
  }

  .right-section {
    max-width: 565px !important;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 26px !important;
  }

  .leaderboard_reward-section {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .slideshow-container {
    margin-left: 0px !important;
  }

  .coverageAvReport {
    margin-top: 20px;
  }
}

@media screen and (max-width: 540px) {
  .right-section {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 0px !important;
  }
}
@media screen and (max-width: 290px) {
  .right-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
  }
}
</style>
