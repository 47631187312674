<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"  style="margin-right: 2px">
        <path d="M8.9998 16.0312C12.4592 16.0312 15.2717 13.2047 15.2717 9.73125C15.2717 8.19844 14.7232 6.77812 13.8092 5.69531L14.1889 5.31562C14.4139 5.09062 14.4139 4.73906 14.1889 4.51406C13.9639 4.28906 13.6123 4.28906 13.3873 4.51406L13.0076 4.89375C12.0654 4.07812 10.8701 3.55781 9.5623 3.44531V3.09375H9.7873C10.0967 3.09375 10.3498 2.84062 10.3498 2.53125C10.3498 2.22187 10.0967 1.96875 9.7873 1.96875H8.21231C7.90293 1.96875 7.64981 2.22187 7.64981 2.53125C7.64981 2.84062 7.90293 3.09375 8.21231 3.09375H8.4373V3.44531C7.12949 3.55781 5.93418 4.07812 4.97793 4.89375L4.59824 4.5C4.37324 4.275 4.02168 4.275 3.79668 4.5C3.57168 4.725 3.57168 5.07656 3.79668 5.30156L4.17637 5.68125C3.2623 6.77813 2.71387 8.18437 2.71387 9.71719C2.72793 13.2047 5.54043 16.0312 8.9998 16.0312ZM8.9998 4.26094C11.9951 4.26094 14.4279 6.70781 14.4279 9.73125C14.4279 12.7547 11.9951 15.1875 8.9998 15.1875C6.00449 15.1875 3.57168 12.7406 3.57168 9.73125C3.57168 6.72187 6.00449 4.26094 8.9998 4.26094Z" :fill="bgColor" />
        <path d="M9.0002 13.936C11.3205 13.936 13.1908 12.0517 13.1908 9.71729C13.1908 7.38291 11.3064 5.49854 9.0002 5.49854C6.69395 5.49854 4.80957 7.38291 4.80957 9.71729C4.80957 12.0517 6.67988 13.936 9.0002 13.936ZM8.57832 8.73291V6.73604C8.57832 6.49697 8.76113 6.31416 9.0002 6.31416C9.23926 6.31416 9.42207 6.49697 9.42207 6.73604V8.73291C9.80176 8.90166 10.0689 9.28135 10.0689 9.71729C10.0689 10.3079 9.59082 10.786 9.0002 10.786C8.40957 10.786 7.93145 10.3079 7.93145 9.71729C7.93145 9.28135 8.19863 8.90166 8.57832 8.73291Z" :fill="bgColor" />
        <line x1="3" y1="9.5" x2="1" y2="9.5" :stroke="bgColor" stroke-linecap="round" />
        <line x1="7.5" y1="15.5" x2="4.5" y2="15.5" :stroke="bgColor" stroke-linecap="round" />
        <line x1="3.5" y1="12.5" x2="2.5" y2="12.5" :stroke="bgColor" stroke-linecap="round" />
        <line x1="1" y1="12.5" x2="0.5" y2="12.5" :stroke="bgColor" stroke-linecap="round" />
        <line x1="3" y1="15.5" x2="2" y2="15.5" :stroke="bgColor" stroke-linecap="round" />
    </svg>
</template>

<script>
export default {
  props: {
    bgColor: String,
  },
};
</script>
