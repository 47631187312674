<template>
  <div>
    <LearnHeader
      v-show="showTopic"
      learnTitle="Concept"
      @activeClass="getActiveclass($event)"
      @chapterData="getChapterData($event)"
      :ConceptChapterNameChange="selectChapterChangeName"
      @userDetail="getUserDetail($event)"
      @chapterList="getChapterList($event)"
      @bgColor="getBackgroundColor($event)"
      @subjectIndex="getSubjectIndex($event)"
    />
    <div class="concept-topics" v-show="showTopic">
      <!-- <p class="topic-count">All Topic <span>({{this.topicList.length}})</span></p> -->
      <div class="topic-content">
        <div class="row">
          <div class="col l3 m4 s12" v-for="(topic, index) in topicList" :key="index">
            <div
              class="topic-box"
              :class="`sub-index-${selectedSubjectIndex}`"
              @click="getConcept(topic.KSCClusterId)"
            >
              <div class="topic-header">
                <h2>{{ topic.KSCClusterName }}</h2>
                <p class="concept-number">
                  <span>{{ topic.TotalConcepts }} </span> Concepts
                </p>
              </div>
              <div class="topic-footer">
                <p class="topic-attempt">
                  {{ topic.TotalAttempts }} <span> Correct Attempts</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="concept-content" v-if="showConcept">
      <div class="row">
        <div class="col s12" style="padding: 0">
          <div class="concept-header valign-wrapper">
            <div class="col m4 s7 concept-header-column" style="padding-left: 0">
              <select
                @change="changeChapter"
                :style="showConcept ? `borderColor: ${backgroundColor}` : ''"
              >
                <option
                  v-for="(chapter, index) in chapterList"
                  :key="index"
                  :value="chapter.ChapterId"
                  :selected="chapter.ChapterId === chapterData.ChapterId"
                  style="text-transform: capitalize"
                >
                  {{ chapter.ChapterName }}
                </option>
              </select>
            </div>
            <div class="col m3 s7 concept-header-column">
              <select
                @change="getConceptDetail"
                :style="showConcept ? `borderColor: ${backgroundColor}` : ''"
              >
                <option
                  v-for="(topic, index) in topicList"
                  :key="index"
                  :selected="topic.KSCClusterId === KSCClusterId"
                  :value="topic.KSCClusterId"
                  style="text-transform: capitalize"
                >
                  {{ topic.KSCClusterName }}
                </option>
              </select>
            </div>
            <div class="col m5 s7 concept-header-column">
              <div
                class="cancel-concept right tooltip"
                @click="
                  showConcept = false;
                  showTopic = true;
                "
              >
                <span
                  class="material-icons"
                  style="font-size: 30px; cursor: pointer"
                  data-tooltip="I am a tooltip"
                  >disabled_by_default</span
                >
                <span class="tooltiptext">Cancel</span>
              </div>
            </div>
          </div>
          <div
            class="concept-item"
            :style="
              showConcept
                ? `borderTopColor: ${backgroundColor}; borderRightColor: ${backgroundColor}`
                : ''
            "
          >
            <div class="concept-item-heading">
              <ul>
                <li
                  class="concept-btn"
                  :class="{ active: selectedTab === 1 }"
                  @click="selectedConcept"
                >
                  <span class="material-icons">text_snippet</span>Concept
                </li>
                <li
                  class="concept-btn"
                  :class="[{ active: selectedTab === 2 }, { hide: showVideoTab === false }]"
                  @click="selectedVideo"
                >
                  <span class="material-icons">smart_display</span>Video
                </li>
                <div></div>
              </ul>
            </div>
            <div class="concept-content">
              <div class="diagramDiv" v-if="selectedTab === 1">
                <div class="diagram-img">
                  <div
                    class="concept-image"
                    v-for="(concept, index) in conceptList"
                    :key="index"
                    :v-if="showDiagram === index"
                    :class="{ active: showDiagram === index }"
                  >
                    <img
                      :src="getAzureUrl(concept.KSCDiagramURL)"
                      style="width: 60vw !important; height: auto !important"
                      draggable="false"
                    />
                  </div>
                </div>
                <div class="diagramBtns">
                  <button
                    class="diagramPre diagram-btn"
                    :style="
                      showConcept
                        ? `borderColor: ${backgroundColor}; backgroundColor: ${backgroundColor}`
                        : ''
                    "
                    @click="preConcept(showDiagram - 1)"
                    :class="{ hide: showDiagram === 0 }"
                  >
                    <span class="material-icons" style="line-height: 28px; margin-left: -3px"
                      >navigate_before</span
                    >
                  </button>
                  <button
                    class="diagramNext diagram-btn"
                    :style="
                      showConcept
                        ? `borderColor: ${backgroundColor}; backgroundColor: ${backgroundColor}`
                        : ''
                    "
                    @click="nextConcept(showDiagram + 1)"
                    :class="{ hide: showDiagram === conceptList.length - 1 }"
                  >
                    <span class="material-icons" style="line-height: 30px; margin-left: -1px"
                      >navigate_next</span
                    >
                  </button>
                </div>
              </div>
              <div class="videoDIv" v-if="selectedTab === 2">
                <div
                  class="video-content"
                  v-for="(video, index) in this.conceptList"
                  :key="index"
                  :class="{ hide: showDiagram !== index }"
                >
                  <iframe
                    :src="video.KSCExternalUrl"
                    frameborder="0"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnHeader from "./LearnHeader.vue";
import Doubtapi from "../Doubtapi";
import MobileAPI from "../Mobileapi";

export default {
  name: "LearnConcept",
  components: {
    LearnHeader,
  },
  data() {
    return {
      userDetails: "",
      chapterData: "",
      chapterList: [],
      topicList: [],
      conceptList: [],
      KSCClusterId: "",
      showTopic: true,
      showConcept: false,
      selectedTab: 1,
      showConceptChapterData: "",
      KSCDiagramURL: "",
      kscVideoUrl: "",
      showDiagram: 0,
      changeConceptChapter: false,
      showVideoTab: false,
      backgroundColor: "",
      selectChapterChangeName: "",
      selectedSubjectIndex: "",
      classId: "",
    };
  },
  methods: {
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
    getActiveclass(classId) {
      // console.log("class:", classId);
      this.classId = classId;
    },
    getUserDetail(user) {
      // console.log("user: ", user);
      this.userDetails = user;
    },
    getChapterList(chapterlist) {
      // console.log("chapList: ", chapterlist);
      this.chapterList = chapterlist;
    },
    getSubjectIndex(subjectIndex) {
      // console.log("subInd: ", subjectIndex);
      this.selectedSubjectIndex = subjectIndex;
    },
    getChapterData(chapterdata) {
      MobileAPI.getStudentDetails(this.$store.getters.user.UserId, (resp) => {
        if (Object.keys(resp.data).length !== 0 || resp.data !== null) {
          const student = resp.data;
          this.chapterData = chapterdata;
          this.showConceptChapterData = chapterdata;
          this.showDiagram = 0;
          this.selectChapterChangeName = "";
          this.$store.dispatch("showLoader", true);
          const data = {
            userId: Number(this.$store.getters.user.UserId),
            chapterId: Number(this.chapterData.ChapterId),
            courseId: Number(student.courseId),
          };
          // console.log("data: ", data);
          Doubtapi.getTopics(data, (response) => {
            if (response) {
              this.topicList = response.data;
            } else {
              this.topicList = [];
              window.M.toast({
                html: "Record Not found",
              });
            }
            this.$store.dispatch("showLoader", false);
          });
        } else {
          this.topicList = [];
          window.M.toast({
            html: "Record Not found",
          });
        }
      });
    },
    getConcept(KSCClusterId) {
      this.KSCClusterId = KSCClusterId;
      this.$store.dispatch("showLoader", true);
      Doubtapi.getConcept(
        {
          kscClusterId: KSCClusterId,
          courseId: this.chapterData.CourseId,
          chapterId: this.chapterData.ChapterId,
        },
        (response) => {
          this.conceptList = response.data;
          this.$store.dispatch("showLoader", false);

          this.kscVideoUrl = this.conceptList[0].KSCExternalUrl;
          if (this.kscVideoUrl) {
            this.showVideoTab = true;
          } else {
            this.showVideoTab = false;
          }
        },
      );
      this.showTopic = false;
      this.showConcept = true;
    },
    // Call API for concept page
    changeChapter(e) {
      if (this.showConcept === true) {
        this.topicList = "";
        this.showConceptChapterData = this.chapterList.filter(
          (chapter) => chapter.ChapterId.toString() === e.target.value.toString(),
        );
        this.$store.dispatch("showLoader", true);

        Doubtapi.getTopics(
          {
            chapterId: this.showConceptChapterData[0].ChapterId,
            userId: this.userDetails.UserId,
            courseId: this.showConceptChapterData[0].CourseId,
          },
          (response) => {
            this.topicList = response.data;
            this.$store.dispatch("showLoader", false);

            this.showConceptChapterData = this.showConceptChapterData[0];
            this.selectChapterChangeName = this.showConceptChapterData.ChapterName;
            this.changeConceptChapter = true;
            this.getConceptDetail(this.topicList[0].KSCClusterId);
          },
        );
      }
    },
    getConceptDetail(e) {
      this.conceptList = "";
      this.showDiagram = 0;
      if (this.changeConceptChapter) {
        this.KSCClusterId = this.topicList[0].KSCClusterId;
        this.changeConceptChapter = false;
      } else {
        this.KSCClusterId = e.target.value;
      }
      this.$store.dispatch("showLoader", true);

      Doubtapi.getConcept(
        {
          kscClusterId: this.KSCClusterId,
          courseId: this.showConceptChapterData.CourseId,
          chapterId: this.showConceptChapterData.ChapterId,
        },
        (response) => {
          this.conceptList = response.data;
          this.$store.dispatch("showLoader", false);
          this.KSCDiagramURL = this.conceptList.map((concept) => concept.KSCDiagramURL);
          this.kscVideoUrl = this.conceptList.map((concept) => concept.KSCExternalUrl);
          if (this.kscVideoUrl[0]) {
            this.showVideoTab = true;
          } else {
            this.showVideoTab = false;
          }
        },
      );
    },

    selectedConcept() {
      if (this.selectedTab !== 1) {
        this.selectedTab = 1;
      }
    },
    nextConcept(showConceptIndex) {
      if (this.selectedTab === 1) {
        this.showDiagram += 1;
        this.kscVideoUrl = this.conceptList.map((concept) => concept.KSCExternalUrl);
        if (this.kscVideoUrl[showConceptIndex]) {
          this.showVideoTab = true;
        } else {
          this.showVideoTab = false;
        }
      }
    },
    preConcept(showConceptIndex) {
      if (this.selectedTab === 1) {
        this.showDiagram -= 1;
        this.kscVideoUrl = this.conceptList.map((concept) => concept.KSCExternalUrl);
        if (this.kscVideoUrl[showConceptIndex]) {
          this.showVideoTab = true;
        } else {
          this.showVideoTab = false;
        }
      }
    },
    selectedVideo() {
      if (this.selectedTab !== 2) {
        this.selectedTab = 2;
      }
    },
    getBackgroundColor(bgcolor) {
      this.backgroundColor = bgcolor;
    },
  },
};
</script>

<style scoped>
.concept-image {
  overflow: hidden;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.concept-image.active {
  display: flex;
}

.concept-topics .topic-count {
  color: #1d1e1e;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.topic-box {
  height: 12.125rem;
  margin-bottom: 1.875rem;
  background-color: #fff;
  max-width: 100%;
  padding: 1.5625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 0.3125rem;
  -webkit-box-shadow: 0 0 30px 0 rgb(0 0 0 / 13%);
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 13%);
  border-style: solid;
  border-width: 0.0625rem;
  border-color: #e8e8e8;
  transition: all 0.3s ease;
  cursor: pointer;
}

.topic-box:hover {
  background-color: #f9a825;
  color: #fff;
}

.topic-box.sub-index-1:hover {
  background-color: #f9a825;
  color: #fff;
}

.topic-box.sub-index-2:hover {
  background-color: #4cb4aa;
  color: #fff;
}

.topic-box.sub-index-3:hover {
  background-color: #64b5f6;
  color: #fff;
}

.topic-box.sub-index-4:hover {
  background-color: #e47272;
  color: #fff;
}

.topic-box.sub-index-5:hover {
  background-color: #7885ca;
  color: #fff;
}

.topic-box.sub-index-6:hover {
  background-color: #ffd54f;
  color: #fff;
}

.topic-box.sub-index-7:hover {
  background-color: #4ccfe0;
  color: #fff;
}

.topic-box:hover .topic-header h2,
.topic-box:hover .topic-attempt {
  color: #fff;
}

.topic-box:hover .concept-number {
  color: #e5ebea;
}

.topic-header h2 {
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  color: #21201f;
  font-weight: 600;
  transition: all 0.3s ease;
  line-height: 1.4;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.concept-number {
  color: #a2b1bc;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  transition: all 0.3s ease;
}

.topic-box .topic-footer {
  width: 6.375rem;
}

.topic-box .topic-footer p {
  margin: 0;
}

.topic-attempt {
  color: #21201f;
  font-size: 0.75rem;
  margin: 0.625rem 0 0 0;
  transition: all 0.3s ease;
}

.concept-content .diagramDiv {
  position: relative;
}

.concept-content .diagramDiv .diagramBtns {
  position: absolute;
  width: 100%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.concept-content .diagramDiv .diagramBtns .diagram-btn {
  position: absolute;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  background: #f9a825;
  border: 0.0625rem solid #f9a825;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.concept-content .diagramDiv .diagramBtns .diagramPre {
  left: 10px;
}

.concept-content .diagramDiv .diagramBtns .diagramNext {
  right: 10px;
}

.videoDIv {
  width: 100%;
  padding: 0 30px;
}

.concept-content .videoDIv .video-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.concept-content .videoDIv .video-content::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.concept-content .videoDIv .video-content iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.concept-header {
  margin-top: 16px;
  margin-bottom: 5px;
}

.concept-header select {
  display: block;
  background: transparent;
  border: 2px solid #f9a825;
  border-radius: 5px;
  padding-right: 1.5rem;
  cursor: pointer;
  color: #21201f;
  font-weight: 600;
  margin-bottom: 10px;
}

.concept-header select::-webkit-scrollbar {
  display: none;
}

.concept-header select::-moz-scrollbar {
  display: none;
}

.concept-header select::-o-scrollbar {
  display: none;
}

.concept-header select::-google-ms-scrollbar {
  display: none;
}

.concept-header select::-khtml-scrollbar {
  display: none;
}

.concept-header select:focus {
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.concept-item {
  background: white;
  border-top: 15px solid #f9a825;
  border-right: 2px solid #f9a825;
  border-radius: 5px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.concept-item-heading {
  padding: 20px 30px 0;
}

.concept-item .concept-item-heading ul {
  display: flex;
}

.concept-item .concept-item-heading ul .concept-btn {
  border-bottom: 1px solid #dee2e6;
  border-radius: 5px 5px 0 0;
  width: fit-content;
  font-size: 16px;
  margin-bottom: 0;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #000;
  transition: all 0.3s ease;
}

.concept-item-heading ul li span {
  margin-right: 10px;
}

.concept-item .concept-item-heading ul div {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  border-left: none;
  width: 100%;
}

.concept-item .concept-item-heading ul .concept-btn:last-child {
  padding-left: 20px;
}

.concept-item .concept-item-heading ul .concept-btn.active {
  border: 1px solid #dee2e6;
  border-bottom-color: transparent;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: rgb(66, 63, 63);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -40px;
  margin-top: -15px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(66, 63, 63) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Responsive */
@media screen and (max-width: 600px) {
  .concept-content {
    padding: 0 10px;
  }

  .valign-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .cancel-concept {
    margin-left: 6px;
    float: left !important;
  }

  .concept-header .concept-header-column {
    padding-left: 0px;
    margin-left: 0;
  }
}
</style>
