<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 2px">
        <path d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z" :fill="bgColor" />
    </svg>
</template>
<script>
export default {
  props: {
    bgColor: String,
  },
};
</script>
