<template>
<div class="container font-open-sans">
    <br>
    <div class="navName row s12 m12">
        <span>Institute Tests</span>
    </div>
    <div>
        <div class="row header-row scroll-horizontal, .scroll-horizontal-bar">
            <div class="col s3 m3 fs-16 fw-600 flex items-center"><img :src="require(`../assets/instituteBookmark.svg`)" class="icon-bullet"><span class="pl-5">Title</span></div>
            <div class="col s3 m3 fs-16 fw-600 flex items-center"><img :src="require(`../assets/instituteCalendar.svg`)" class="icon-bullet"><span class="pl-5">Date & Time</span></div>
            <div class="col s2 m2 fs-16 fw-600 flex items-center"><img :src="require(`../assets/instituteDuration.svg`)" class="icon-bullet"><span class="pl-5">Duration</span></div>
            <div class="col s2 m2 fs-16 fw-600 flex items-center"><img :src="require(`../assets/instituteQuestion.svg`)" class="icon-bullet"><span class="pl-5">Questions</span></div>
            <div class="col s2 m2 fs-16 fw-600 flex items-center flex-center"><img :src="require(`../assets/instituteStatus.svg`)" class="icon-bullet"><span class="pl-5">Status</span></div>
        </div>
        <div class="scroll-verticle-bar" style="max-height: calc(100vh - 260px);">
          <div v-if="testList.length > 0">
              <div v-for="data in testList" :key="data.InstituteTestId" class="table-row row scroll-horizontal, .scroll-horizontal-bar" :class="getTestStyle(data.StartDateTime,data.EndDateTime,data.Status)">
                  <div class="col s3 fw-400 fs-16">
                    <span class="truncate" :title="`${data.Title}`">{{data.Title}}</span>
                  </div>
                  <div class="col s3 nowrap">
                      <p class="flex items-center fw-400 fs-16 mtb">
                        <span class="material-icons material-symbols-outlined time-img fs-25" style="margin-right: 6px; color: rgb(109 194 112);">play_circle</span>
                        <span>{{formatDate(data.StartDateTime)}}</span>
                      </p>
                      <p class="flex items-center fw-400 fs-16 mtb">
                        <span class="material-icons material-symbols-outlined time-img fs-25" style="margin-right: 6px; color: rgb(251 97 86 / 85%);">stop_circle</span>
                        <span>{{formatDate(data.EndDateTime)}}</span>
                      </p>
                  </div>
                  <div class="col s2 fw-600 fs-16 ta-center">
                    <span class="color-purple">{{data.Duration}} Min</span>
                  </div>
                  <div class="col s2 fw-600 fs-16">
                    <span class="color-purple">{{data.TotalQuestions}}</span>
                  </div>
                  <div class="col s2 align-center">
                      <div v-if="getTestStatusText(data.StartDateTime, data.Status, data.EndDateTime) === 'Completed'" class="d-grid">
                          <span class="fw-600 fs-16 color-green">Completed</span>
                          <a class="fs-13 flex flex-center flex-align" @click="linkButtonResultClick(data.InstituteTestId, data.Title, data.InstituteTestUserId)">
                              <img :src="require(`../assets/eye.svg`)" class="eye-icon">
                              <span class="fw-400 pl-5"> View Report</span>
                          </a>
                      </div>
                      <div v-else-if="getTestStatusText(data.StartDateTime, data.Status, data.EndDateTime) === 'Expired'">
                          <span class="fw-600 fs-16 color-red">Expired</span>
                      </div>
                      <div v-else>
                          <span class="fw-600">
                              <button class="btn-btn startButton" :disabled="getTestStatusText(data.StartDateTime, data.Status, data.EndDateTime) === 'Wait'" @click="linkButtonActionClick(data.InstituteTestUserId, data.InstituteTestId, data.Title, data.Duration, data.TestType, data.EndDateTime)">{{ getTestStatusText(data.StartDateTime, data.Status, data.EndDateTime) }}</button>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
          <div v-else>
              <div class="text-center" style="margin-bottom: 20px;">
                  <img :src="require(`../assets/no-result-found.png`)" alt="" style="width: 200px; opacity: 0.7;">
                  <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585;">No test available.</p>
              </div>
          </div>
        </div>
          <div v-if="testList.length > 0" class="pagination flex flex-between d-block">
        <div>
            <span class="show-index fw-500 fs-15  ws-nowrap ">
                <span>Showing </span>
                <span>{{startQuesNumber}}</span>
                <span> - </span>
                <span>{{endQuesNumber}}</span>
                <span> of </span>
                <span>{{ totalQnsList}}</span>
                <span> Tests</span>
            </span>
        </div>
        <div class="flex flex-wrap" style="gap: 3px;">
            <a @click="previousPage()" class="navigation-btn prev-btn" :class="{disable: currentPage < 2}" title="Previous">
                <span class="material-icons material-symbols-outlined fs-20 pointer">west</span>
            </a>
            <a @click="nextPage()" class="navigation-btn" :class="{disable: this.testList.length < 20 }" title="Next">
                <span class="material-icons material-symbols-outlined fs-20 pointer">east</span>
            </a>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import { useToast } from "vue-toastification";
import moment from "moment";
import mobileAPI from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      testList: [],
      affiliationId: null,
      courseId: null,
      studentId: null,
      centerCodeId: null,
      startEnable: false,
      enable: null,
      currentPage: 1,
      endQuesNumber: 0,
      startQuesNumber: 0,
      totalQnsList: 0,
      next: true,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  created() {
    // this.refreshList();
    const user = this.$store.getters.user;
    this.affiliationId = Number(user.AffiliationId);
    const testStudentId = Number(user.UserId);
    this.$store.dispatch("showLoader", true);
    mobileAPI.getStudentDetails(testStudentId, (responseParent) => {
      if (responseParent.responseCode === 200) {
        if (responseParent.data !== null && Object.keys(responseParent.data).length !== 0) {
          this.$store.dispatch("showLoader", true);
          const student = responseParent.data;
          this.courseId = student.courseId;
          this.studentId = student.studentId;
          this.centerCodeId = student.centerCodeId;
          this.calStartPageNumber();
          this.getInstituteTestData();
        }
      } else {
        this.$store.dispatch("showLoader", false);
        toast.error(`${responseParent.data.info}`, {
          timeout: 2500,
        });
      }
    });
  },
  methods: {
    getInstituteTestData() {
      const getList = {
        userId: Number(this.studentId),
        batchId: Number(this.centerCodeId),
        courseId: Number(this.courseId),
        affiliationId: Number(this.affiliationId),
        pageNo: this.currentPage,
      };
      this.$store.dispatch("showLoader", true);
      mobileAPI.getTestInstituteListData(getList, (response) => {
        if (response.responseCode === 200) {
          if (!this.next) {
            this.endQuesNumber -= this.testList.length;
            this.testList = null;
          }
          if (response.data !== null && Object.keys(response.data).length > 0) {
            this.testList = null;
            this.testList = response.data;
            this.totalQnsList = this.testList[0].TotalTest;
            if (this.next) {
              this.endQuesNumber += this.testList.length;
            }
            this.$store.dispatch("showLoader", false);
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error("No data in list", {
              timeout: 2500,
            });
          }
        }
        if (response.responseCode === 500) {
          this.$store.dispatch("showLoader", false);
          toast.error(`${response.data.info}`, {
            timeout: 2500,
          });
        }
      });
    },
    previousPage() {
      this.next = false;
      this.currentPage -= 1;
      this.calStartPageNumber();
      this.getInstituteTestData();
    },
    nextPage() {
      this.next = true;
      this.currentPage += 1;
      this.calStartPageNumber();
      this.getInstituteTestData();
    },
    calStartPageNumber() {
      this.startQuesNumber = 1 + (this.currentPage - 1) * 20;
    },
    getTestStatusText(startDateTime, status, endTime) { // reviewed
      const date = new Date();
      const currentDate = `${moment(date).format("YYYY-MM-DDTHH:mm:00.000")}Z`;
      let action = "";
      if (startDateTime <= currentDate) {
        if (status === null) {
          if (endTime <= currentDate) {
            action = "Expired";
          } else {
            action = "Start";
          }
        } else if (status === "Completed") {
          action = "Completed";
        } else if (endTime <= currentDate) {
          action = "Expired";
        } else if (status === "InProgress") {
          action = "Resume";
        } else {
          action = "wait";
        }
      } else {
        action = "Wait";
      }
      return action;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY hh:mm:ss A");
      }
      return null;
    },
    getTestStyle(startDateTime, endtime, status) {
      let style = "wait";
      const date = new Date();
      const currentDate = `${moment(date).format("YYYY-MM-DDTHH:mm:00.000")}Z`;
      if (startDateTime <= currentDate) {
        if (status === null && currentDate < endtime) {
          style = "start";
        } else if (status === "Completed") {
          style = "completed";
        } else if (endtime <= currentDate) {
          style = "expired";
        } else if (status === "InProgress") {
          style = "inProgress";
        } else {
          style = "expired";
        }
      } else {
        style = "wait";
      }
      return style;
    },
    linkButtonActionClick(instituteTestUserId) {
      const testType = 2;
      this.$router.push({
        name: "ConductTest",
        params: {
          testUserId: instituteTestUserId,
          testType,
        },
      });
    },
    linkButtonResultClick(instituteTestId, testName, instituteTestUserId) {
      const user = this.$store.getters.user;
      this.$router.push({
        name: "TestReport",
        params: {
          testUserId: Number(instituteTestUserId),
          testType: 2,
          userId: user.UserId,
          name: this.affiliationUserName,
          previouspage: true,
          testid: instituteTestId,
          testname: testName,
          testName,
        },
      });
    },
  },
};
</script>

<style scoped>
.navigation-btn.disable {
    pointer-events: none;
}

.navigation-btn {
    width: 30px;
    height: 30px;
    line-height: 38px;
    text-align: center;
    background-color: white;
    color: #8c8585;
    border: 1px solid #333333 !important;
    border-radius: 3px;
}

.navigation-btn.currentPage {
    border-color: #3751FF !important;
    color: #3751FF;
}
.pagination {
    padding: 13.15px;
    margin-top: 10px !important;
    background-color: #FFFFFF;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pagination {
    margin-bottom: 10px;
}

.scroll-verticle,
.scroll-verticle-bar {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
    width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
    width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.scroll-bar {
    max-height: calc(100vh - 100px);
}

.font-open-sans {
    font-family: 'Open Sans';
    font-style: normal;
}

.align-center {
    text-align: center;
}

.d-grid {
    display: grid;
}

.pl-5 {
    padding-left: 5px;
}

.mtb {
    margin: 7.4px 0px !important;
}

.row {
    margin-bottom: 10px;
}

.start {
    border-left: 4px solid #FF9421;
    border-radius: 4px 0px 0px 4px;
}

.completed {
    border-left: 4px solid #16A085;
    border-radius: 4px 0px 0px 4px;
}

.expired {
    border-left: 4px solid #EF727A;
    border-radius: 4px 0px 0px 4px;
}

.wait {
    border-left: 4px solid #FF9421;
    border-radius: 4px 0px 0px 4px;
}

.inProgress {
    border-left: 4px solid #FF9421;
    border-radius: 4px 0px 0px 4px;
}

.color-red {
    color: #EF727A;
}

.color-green {
    color: #16A085;
}

.color-orange {
    color: #FF9421;
}

.color-purple {
    color: #642C90;
}

button:focus {
    outline: none;
    background-color: #dde4eb;
}

.resume-btn {
    background: linear-gradient(90deg, #2c83df 35%, rgb(58, 137, 210) 100%);
    font-weight: 600;
    border-color: #2c83df;
    color: #FFFFFF;
}

.resume-btn:hover {
    cursor: pointer;
}

.startButton {
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%) !important;
    font-family: 'Open Sans';
    border: none;
    border-color: #FBAD26;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
}

.startButton:hover {
    cursor: pointer;
}

.btn-btn {
    width: 90px;
    height: 36px;
    border-radius: 5px;
    border: none !important;
    font-size: 14px;
}

.header-row {
    background-color: #E0E4F0;
    border-radius: 4px 4px 0px 0px;
    padding: 13.15px;
    justify-content: space-between;
}

.icon-bullet {
    width: 28px;
}

.prev-btn {
    margin-right: 20px;
}

.table-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
    border-collapse: separate;
    border-spacing: 0 10px;
    background-color: white;
}

.navName {
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #333333;
}
.flex-align{
  align-items: center;
}

/* @media screen and (max-width: 811px) {
  .ta-center{
      text-align: center;
    }
} */
@media screen and (max-width: 810px) {
  .ta-center{
      text-align: center;
    }
    .container {
        width: 810px;
    }

    .scroll-horizontal,
    .scroll-horizontal-bar {
        display: flex;
        overflow-x: auto !important;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .scroll-horizontal::-webkit-scrollbar {
        height: 0px;
    }

    .scroll-horizontal-bar::-webkit-scrollbar {
        height: 3px;
    }

    .scroll-horizontal::-webkit-scrollbar-track,
    .scroll-horizontal-bar::-webkit-scrollbar-track {
        background-color: #ececec;
        border-radius: 5px;
    }

    .scroll-horizontal::-webkit-scrollbar-thumb,
    .scroll-horizontal-bar::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
    }
  }
  .eye-icon {
    width: 16px;
    height: 16px;
  }
</style>
