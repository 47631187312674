<template>
 <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 2px">
         <path d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z" :fill="bgColor" />
         <path d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z" :fill="bgColor" />
     </svg>
</template>
<script>
export default {
  props: {
    bgColor: String,
  },
};
</script>
