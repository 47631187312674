<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0025 14.9257C13.3929 14.4406 15.999 11.5248 15.999 8.00025C15.999 4.13628 12.8667 1.00391 9.00269 1.00391C5.13872 1.00391 2.00635 4.13628 2.00635 8.00025C2.00635 11.2469 4.21774 13.977 7.21644 14.7665L10.0025 16.9953V14.9257ZM11.1935 9.09031C11.3568 8.76262 11.4487 8.39307 11.4487 8.00208C11.4487 6.65276 10.3548 5.55891 9.0055 5.55891C7.65617 5.55891 6.56233 6.65276 6.56233 8.00208C6.56233 9.3514 7.65617 10.4452 9.0055 10.4452C9.54604 10.4452 10.0456 10.2697 10.4502 9.97252L9.01533 8.82461C8.76666 8.62567 8.72634 8.26281 8.92528 8.01414C9.12421 7.76547 9.48707 7.72515 9.73575 7.92409L11.1935 9.09031ZM12.162 9.86512C12.4849 9.31921 12.6702 8.68229 12.6702 8.00208C12.6702 5.97809 11.0295 4.33733 9.0055 4.33733C6.98151 4.33733 5.34075 5.97809 5.34075 8.00208C5.34075 10.0261 6.98151 11.6668 9.0055 11.6668C9.93339 11.6668 10.7807 11.322 11.4264 10.7535L11.8984 11.1311C12.1471 11.33 12.5099 11.2897 12.7089 11.041C12.9078 10.7924 12.8675 10.4295 12.6188 10.2306L12.162 9.86512Z" :fill="bgColor" />
</svg>
</template>

<script>
export default {
  props: {
    bgColor: String,
  },
};
</script>
