<template>
  <div class="container">
    <div class="Aff-TestAnalysis-Header">
      <strong style="color: Black">Effort Analysis</strong>
      <strong><a style="color: #642c90"> / Test Analysis</a></strong>
    </div>
    <div></div>
    <div class="divider linediv hide-on-small-only" style="width: 100%"></div>
    <div class="card-panel Tcard">
      <div class="row">
        <div class="col l3 m6 s12">
          <div class="dropdowntitle"><strong>Course</strong></div>
          <select
            class="browser-default affdatadropd"
            @change="onCourseChange($event)"
            v-model="selectCourse"
          >
            <option value="" disabled selected>-- select --</option>
            <option
              :value="data.courseId"
              v-for="data in coursedata"
              :key="data.affiliationCourseId"
            >
              {{ data.courseName }}
            </option>
          </select>
          <div v-show="CourseValidation" style="color: red">{{ this.CourseValidationMessage }}</div>
        </div>
        <div class="col l3 m6 s12">
          <div class="dropdowntitle"><strong>Batch</strong></div>
          <select
            class="browser-default affdatadropd"
            @change="onBatchChange($event)"
            v-model="selectBatch"
          >
            <option value="" disabled selected>-- select --</option>
            <option :value="batch.BatchId" v-for="batch in courseBatchList" :key="batch.BatchId">
              {{ batch.BatchName }}
            </option>
          </select>
          <div v-show="BatchValidation" style="color: red">{{ this.BatchValidationMessage }}</div>
        </div>
        <div class="col l3 m6 s12">
          <div class="dropdowntitle"><strong>Date</strong></div>
          <select
            class="browser-default affdatadropd"
            v-model="dateRangeType"
            @change="showCalendar()"
          >
            <option value="Date" disabled selected>Select Here</option>
            <option v-for="(period, index) in Timeinterval" :key="period" :value="index + 1">
              {{ period }}
            </option>
          </select>
        </div>
        <div class="col l3 m6 s12">
          <a
            class="AffsubmitBtn waves-effect waves-light btn getclassbutton"
            @click="getTestAnalysisDetails()"
            >Submit</a
          >
        </div>
      </div>
      <div class="row" v-if="isDateRangeOpen">
        <div class="col l3 m6 s12">
          <strong class="dropdowntitle">Start Date</strong>
          <input
            type="date"
            name="start date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default TestAnalysisdatepicker"
            style="display: block"
            v-model="startDate"
            @change="showCalendar()"
          />
          <div v-show="isStartDateSelected" style="color: red">
            {{ this.DatevalidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <strong class="dropdowntitle">End Date</strong>
          <input
            type="date"
            name="end Date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default TestAnalysisdatepicker"
            style="display: block"
            v-model="endDate"
            @change="showCalendar()"
          />
          <div v-show="isEndDateSelected" style="color: red">{{ this.DatevalidationMessage }}</div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col s12">
        <label class="radio" style="color: black">
          <input class="with-gap" name="group4" type="radio" checked />
          <span class="col s12 m4">Completed Test</span>
        </label>
      </div>
    </div>
    <div class="row">
      <table class="qtl striped" style="width: 100%">
        <thead>
          <tr>
            <td class="left-align">TestName</td>
            <td class="center">Date</td>
            <td class="center-align">Subject</td>
            <td class="center-align">Stud. Appeared/ Total Stud.</td>
            <td class="center-align">
              <span class="material-icons" style="position: relative; top: 5px; margin-right: 2px"
                >alarm</span
              >Duration
            </td>
            <td class="center-align">Total Question</td>
            <!-- <td class="center-align">Attempt%</td>
            <td class="center-align">Avg Marks</td> -->
            <td class="center-align">Test Details</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(test, index) in testdata" :key="index">
            <td class="left-align size" style="text-decoration: underline">
              <a
                @click="
                  navigateTestAnalysisReport(
                    test.instituteTestId,
                    test.title,
                    test.StartDateTime,
                    test.TotalStudents,
                    test.StudentAppeared,
                    test.Duration,
                    test.TotalQuestions,
                    test.SubjectNames,
                  )
                "
                >{{ test.title }}</a
              >
            </td>
            <td class="center">
              {{ formatDate(test.StartDateTime) }}<br />{{ formatTime(test.StartDateTime) }}
            </td>
            <td class="center">{{ test.SubjectNames }}</td>
            <td class="center">
              {{ test.StudentAppeared === null ? 0 : test.StudentAppeared }}/{{
                test.TotalStudents
              }}
            </td>
            <td class="center">
              <div v-if="test">
                <span v-if="test.Duration >= 3600">{{
                  Math.floor(test.Duration / 3600) + " hrs"
                }}</span>
                <span v-else-if="test.Duration >= 60">{{
                  Math.floor(test.Duration / 60) + " hr"
                }}</span>
                <span v-else>{{ test.Duration + " min" }}</span>
              </div>
            </td>
            <td class="center">{{ test.TotalQuestions }}</td>
            <!-- <td class="center">{{test.AttemptPercentage === null ? 0 :test.AttemptPercentage }}
            </td>
            <td class="center">{{ test.AvgMarks === null ? 0 :test.AvgMarks}}</td> -->
            <td class="center" style="text-decoration: underline">
              <a @click="viewQuestion(test.title, test.StartDateTime, test.instituteTestId)"
                >View Questions</a
              >
            </td>
            <td class="center">
              <label>
                <i
                  class="icon material-icons"
                  style="color: #642c90"
                  @click="getReportExcel(test.instituteTestId)"
                  >file_download</i
                >
              </label>
            </td>
            <td>
              <img
                style="height: 25px; cursor: pointer"
                @click="extensiveReport(test.instituteTestId)"
                :src="require(`../assets/analytics.png`)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div>
      <Modal
        :show="showCampaignModal"
        @close="showCampaignModal = false"
        :showHeader="true"
        :showCloseBtn="true"
        width="1000px"
      >
        <template v-slot:header>
          <div class="row nmb">
            <div class="col s6 left-align VqTitle" style="color: #642c90">
              <p>Test Details :{{ this.CourseTitle }}</p>
            </div>
            <div class="col s6 right-align VqTitle" style="color: #642c90">
              <p>Test Date:{{ formatDate(this.CourseStartDate) }}</p>
            </div>
          </div>
        </template>
        <template v-slot:body>
          <div class="row">
            <div class="DownloadText col S3">
              <a class="pdfbtn waves-effect waves-light btn getclassbutton">
                <div class="export-pdf-button">
                  <i
                    class="small material-icons text-darken-3"
                    style="
                      position: relative;
                      top: 6px;
                      margin-left: 10px;
                      cursor: pointer;
                      margin-right: 5px;
                    "
                    >file_download</i
                  >
                  <span @click="PdfDownload((isAnswerReq = 0))">Export to Question PDF</span>
                </div>
              </a>
            </div>
            <div class="DownloadText col S3">
              <a class="pdfbtn waves-effect waves-light btn getclassbutton">
                <div class="export-pdf-button">
                  <i
                    class="small material-icons text-darken-3"
                    style="
                      position: relative;
                      top: 6px;
                      margin-left: 10px;
                      cursor: pointer;
                      margin-right: 5px;
                    "
                    >file_download</i
                  >
                  <span @click="PdfDownload((isAnswerReq = 1))"
                    >Export to Question and Answer PDF</span
                  >
                </div>
              </a>
            </div>
          </div>
          <div class="row">
            <table class="qtl">
              <thead>
                <tr class="titlebar">
                  <td class="center col s1" style="margin-top: 6px">Question No</td>
                  <td class="center col s1" style="margin-top: 18px">Part</td>
                  <td class="center col s9" style="margin-top: 18px">Questions</td>
                  <td class="center col s1" style="margin-top: 18px">Solution</td>
                </tr>
              </thead>
              <tbody>
                <div v-for="(Questions, index) in viewQuestionReport" :key="index">
                  <div class="row">
                    <div class="center col s1">{{ index + 1 }}</div>
                    <div class="center col s1">{{ Questions.PartName }}</div>
                    <div class="left col s9">
                      <img
                        class="responsive-img"
                        draggable="false"
                        :src="getAzureUrl(Questions.QuestionDiagramURL)"
                      />
                    </div>
                    <div class="center col s1 icon">
                      <i
                        class="small material-icons icon"
                        style="color: #642c90"
                        @click="ViewSolution(Questions.fullSolutionURL)"
                      >
                        lightbulb_outline</i
                      >
                    </div>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </template>
      </Modal>
    </div>
    <!-- viewSolution -->
    <Modal
      :show="showSolutionModal"
      @close="showSolutionModal = false"
      :showHeader="false"
      :showCloseBtn="true"
      width="1000px"
    >
      <!-- <div slot="header">
        <div style="font-size: 30px; color: #642C90">
          <strong>Solution</strong>
        </div>
      </div> -->
      <template v-slot:body>
        <div style="font-size: 30px; color: #642c90">
          <strong>Solution</strong>
          <div class="divider TestLine-divide"></div>
        </div>
        <table>
          <tr>
            <td class="center col s12">
              <img
                class="responsive-img"
                draggable="false"
                :src="getAzureUrl(this.ViewSolutionUrl)"
                style="max-height: 400px"
              />
            </td>
          </tr>
        </table>
        <div class="row"></div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Papa from "papaparse";
import moment from "moment";
// import Vue from "vue";
import API from "../Api";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
//   }
//   return value;
// });
// Vue.filter("formatTime", (value) => {
//   if (value) {
//     // console.log(value);
//     return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
//   }
//   return value;
// });

export default {
  data() {
    return {
      testdata: [],
      coursedata: [],
      courseBatchList: [],
      exceldata: [],
      instituteid: [],
      viewQuestionReport: [],
      courseId: null,
      batchId: 0,
      dataId: "",
      startDate: "",
      endDate: "",
      dateRangeType: 1,
      selectCourse: -1,
      selectBatch: -1,
      CourseValidation: false,
      BatchValidation: false,
      isDateRangeOpen: false,
      CourseValidationMessage: "",
      BatchValidationMessage: "",
      DatevalidationMessage: "",
      isStartDateSelected: false,
      isEndDateSelected: false,
      showCampaignModal: false,
      showSolutionModal: false,
      TestTitle: "",
      CourseTitle: " ",
      CourseStartDate: "",
      CourseInstituteTestId: "",
      ViewSolutionUrl: "",
      img: "",
      ViewQuestions: true,
      Timeinterval: ["Today", "Yesterday", "Last Week", "Custom"],
      teacherDashAffId: null,
    };
  },
  activated() {
    if (this.$route.params.teacherDashCourseId != null) {
      this.selectCourse = Number(this.$route.params.teacherDashCourseId);
      this.selectBatch = Number(this.$route.params.teacherDashBatchId);
      this.teacherDashAffId = Number.isNaN(Number(this.$route.params.teacherDashAffId))
        ? Number(this.$store.getters.user.AffiliationId)
        : Number(this.$route.params.teacherDashAffId);
      API.getBatches(this.teacherDashAffId, (response) => {
        const listOfBatches = response.data;
        if (this.selectCourse !== -1 && this.selectBatch !== -1 && this.teacherDashAffId) {
          // this.getResponseOfPracticeAssignments();
          this.courseBatchList = listOfBatches.filter(
            (batch) => batch.CourseId === this.selectCourse,
          );
          this.batchId = this.selectBatch;
          this.courseId = this.selectCourse;
          this.dateRangeType = 3;
          if (Number(this.$route.params.dateRange) > 0) {
            this.dateRangeType = Number(this.$route.params.dateRange);
            this.startDate = this.$route.params.start;
            this.endDate = this.$route.params.end;
            if (this.dateRangeType === 4) {
              this.isDateRangeOpen = true;
              this.isStartDateSelected = false;
              this.isEndDateSelected = false;
            } else {
              this.isDateRangeOpen = false;
            }
          }
          this.getTestAnalysisDetails();
        }
      });
    }
  },
  methods: {
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
      }
      return value;
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
      }
      return value;
    },
    extensiveReport(instituteTestId) {
      this.$router.push({
        name: "DetailReport",
        params: {
          TestAnalysisTestId: instituteTestId,
        },
      });
    },
    onCourseChange(event) {
      this.courseBatchList = this.batchData.filter(
        (a) => a.CourseId.toString() === event.target.value,
        (this.CourseValidation = false),
      );
      this.courseId = Number(event.target.value);
    },
    onBatchChange(event) {
      this.batchId = Number(event.target.value);
      this.BatchValidation = false;
    },
    navigateTestAnalysisReport(
      institutetest,
      title,
      createddate,
      studentappear,
      totalstud,
      duration,
      totalquestion,
      subjectNames,
    ) {
      const thisTestData = {
        instituteTestId: institutetest,
        TestName: title,
        CreatedDate: createddate,
        StudentApp: studentappear,
        TotalStud: totalstud,
        Duration: duration,
        TotalQuestion: totalquestion,
        SubjectName: subjectNames,
      };
      this.$store.dispatch("showLoader", true);
      this.$router.push({
        name: "TestAnalysisReport",
        params: {
          instituteTestId: thisTestData.instituteTestId,
          testname: JSON.stringify(thisTestData),
          title: thisTestData.TestName,
          pageName: "TestAnalysis",
          courseId: this.courseId,
          batchId: this.batchId,
          dateRange: this.dateRangeType,
          start: this.startDate,
          end: this.endDate,
        },
      });
    },
    PdfDownload(IsAnswerReq) {
      const data = {
        instituteTestId: this.InstituteTestId,
        isAnswerReq: IsAnswerReq,
      };
      MobileAPI.PrintPage(data);
      this.$store.dispatch("showLoader", true);
      setTimeout(() => {
        this.$store.dispatch("showLoader", false);
      }, 3000);
    },
    roundOf(num) {
      return Math.floor(num);
    },
    getTestAnalysisDetails() {
      if (this.selectCourse === -1) {
        this.CourseValidation = true;
        this.CourseValidationMessage = "Please select course";
      } else if (this.selectBatch === -1) {
        this.BatchValidation = true;
        this.BatchValidationMessage = "Please select Batch";
      }
      let beginDate = moment();
      let endingDate = moment();
      if (this.dateRangeType === -1) {
        return;
      }
      if (this.dateRangeType === 1) {
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = endingDate.format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 2) {
        beginDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        endingDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 3) {
        beginDate = moment().subtract(1, "weeks").startOf("week");
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = moment().format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 4) {
        beginDate = this.startDate;
        endingDate = this.endDate;
        if (beginDate === "") {
          this.DatevalidationMessage = "Please Select start date";
          this.isStartDateSelected = true;
          return;
        }
        if (endingDate === "") {
          this.DatevalidationMessage = "Please Select end date";
          this.isEndDateSelected = true;
          this.isStartDateSelected = false;
          return;
        }
        if (beginDate !== "" && endingDate !== "") {
          beginDate = moment(this.startDate, "YYYY-MM-DD");
          endingDate = moment(this.endDate, "YYYY-MM-DD");
          const differenceOfDays = endingDate.diff(beginDate, "days");
          if (differenceOfDays < 0) {
            window.M.toast({
              html: "End Date can not be less than Start Date",
            });
            return;
          }
          if (differenceOfDays > 30) {
            window.M.toast({
              html: "Select date range between 30 days",
            });
            return;
          }
        }
      }
      const data = {
        // startDate: "2022-04-01",
        // endDate: "2022-04-28",
        // affiliationId: 18,
        // batchId: 377,
        // courseId: 1,

        startDate: this.startDate,
        endDate: this.endDate,
        affiliationId: this.teacherDashAffId
          ? this.teacherDashAffId
          : Number(this.$store.getters.user.AffiliationId),
        batchId: this.batchId,
        courseId: this.courseId,
      };
      if (
        this.selectCourse !== -1 &&
        this.selectBatch !== -1 &&
        this.startDate !== " " &&
        this.endDate !== " "
      ) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.AffiliationTestAnalysis(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.testdata = response.data;
          if (response.responseCode === 201) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection",
            });
            this.testdata = [];
          }
        });
      }
    },
    getReportExcel(instituteTestId) {
      API.PartwiseInstituteTestReport(
        {
          insituteTestId: instituteTestId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.exceldata = response.data;
          const blob = new Blob([Papa.unparse(this.exceldata)], {
            type: "text/csv;charset=utf-8;",
          });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "InstituteReport.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      );
    },
    showCalendar() {
      if (this.dateRangeType === 4) {
        this.isDateRangeOpen = true;
        this.isStartDateSelected = false;
        this.isEndDateSelected = false;
      } else {
        this.isDateRangeOpen = false;
      }
    },
    viewQuestion(courseTitle, courseStartDate, instituteTestId) {
      this.CourseTitle = courseTitle;
      this.CourseStartDate = courseStartDate;
      this.InstituteTestId = instituteTestId;
      this.$store.dispatch("showLoader", true);
      MobileAPI.ViewInstituteTestQuestions(
        { instituteTestId: this.InstituteTestId },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.data.length > 0) {
            this.viewQuestionReport = response.data;
            this.showCampaignModal = true;
          } else {
            window.M.toast({
              html: "No question available.",
            });
          }
        },
      );
    },
    ViewSolution(solutionUrl) {
      this.ViewSolutionUrl = this.getAzureUrl(solutionUrl);
      this.showSolutionModal = true;
    },
  },
  created() {
    const user = this.$store.getters.user;
    API.getCourses({}, (response) => {
      this.coursedata = response.data;
    });
    API.getBatches(user.AffiliationId, (response) => {
      this.batchData = response.data;
    });
  },
  components: {
    Modal,
  },
};
</script>

<style scoped>
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #f59e00;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #f59e00;
}

.Aff-TestAnalysis-Header {
  margin-top: 19px;
  font-size: 21px;
  color: #642c90;
}

.linediv {
  margin-top: 18.5px;
  height: 0px;
  left: 132px;
  border: 1px solid #e0e0e0;
}

.dropdowntitle {
  margin-bottom: 8px;
  font-size: 19px;
}

.affdatadropd {
  border-radius: 5px;
  height: 36px;
}

.AffsubmitBtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-left: 41px;
  margin-top: 36px;
  width: 130px;
  height: 38px;
}

.radio {
  margin-left: 10px;
}

table.qtl {
  border-collapse: separate;
  border-spacing: 0 10px;
}

table.qtl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtl thead tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtl thead tr td {
  border-radius: 0;
}

.TestAnalysisdatepicker {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1.5px solid #e3e3e3;
}

.size {
  width: 200px;
}

.icon {
  cursor: pointer;
}

.pdfbtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.VqTitle {
  font-size: 19px;
}

.image {
  width: 900px;
}

.titlebar {
  height: 56px;
}
.Tcard {
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.TestLine-divide {
  margin-top: 8px;
  /* width: 100%; */
  height: 0px;
  left: 132px;
  border: 1px solid #e0e0e0;
}
.export-pdf-button {
  margin-top: -4px;
  margin-left: -5px;
}
</style>
