<template>
  <div>
    <strong class="contentheader">StudentReport</strong>
    <div class="card-panel">
      <div class="row">
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <div class="bodycontent">
            <div class="bodyTitle"><strong>Course</strong></div>
            <select class="browser-default dropd" @change="onCourseChange($event)"
              v-model="selectCourse">
              <option value="" disabled selected>-- select --</option>
              <option :value="data.courseId" v-for="data in coursedata"
                :key="data.affiliationCourseId">
                {{ data.courseName }}
              </option>
            </select>
            <div v-show="CourseValidation" style="color:red">{{this.CourseValidationMessage}}</div>
          </div>
        </div>
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <div class="bodycontent">
            <div class="bodyTitle"><strong>Batch</strong></div>
            <select class="browser-default dropd" @change="onBatchChange($event)"
              v-model="selectBatch">
              <option value="" disabled selected>-- select --</option>
              <option :value="batch.BatchId" v-for="batch in courseBatchList" :key="batch.BatchId">
                {{ batch.BatchName }}
              </option>
            </select>
            <div v-show="BatchValidation" style="color:red">{{this.BatchValidationMessage}}</div>
          </div>
        </div>
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <div class="bodycontent">
            <div class="bodyTitle"><strong>Date</strong></div>
            <select class="browser-default dropd" v-model="dateRangeType" @change="showCalendar()">
              <option value="Date" disabled selected>Select Here</option>
              <option v-for="(period, index) in Timeinterval" :key="period" :value="index +1">
                {{ period }}
              </option>
            </select>
          </div>
        </div>
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <a class="dbtn waves-effect waves-light btn getclassbutton"
            @click="getcourses()">Submit</a>
        </div>
      </div>

      <div class="row" v-if="isDateRangeOpen">
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <strong class="bodyTitle">Start Date</strong>
          <input type="date" name="start date" class="browser-default datepicker"
            style="display: block" v-model="StartDate" @change="showCalendar()" />
          <div v-show="isStartDateSelected" style="color:red">{{this.DatevalidationMessage}}</div>
        </div>
        <div class="col s12 m6 l3" style="margin-right: 15px;">
          <strong class="bodyTitle">End Date</strong>
          <input type="date" name="end Date" class="browser-default datepicker"
            style="display: block" v-model="EndDate" @change="showCalendar()" />
          <div v-show="isEndDateSelected" style="color:red">{{this.DatevalidationMessage}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <table class="qtl striped">
        <thead>
          <tr>
            <td class="center">Student Name</td>
            <td class="center"># of Sessions</td>
            <td class="center">Questions</td>
            <td class="center">Attempted</td>
            <td class="center">Correct</td>
            <td class="center">Time</td>
            <td></td>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(course, index) in courses" :key="index">
            <td class="center size" style="text-decoration: underline">
              <a>{{ course.StudentName }}</a>
            </td>
            <td class="center">{{ course.Sessions}}</td>
            <td class="center">
              {{ course.TotalQuestions }}
            </td>
            <td class="center">{{ course.Attempted}}</td>
            <td class="center">{{ course.Correct}}</td>
            <td class="center">
              <div v-if="course">
                <span v-if="course.TotalTimeInSec >= 3600">{{
                        Math.floor(
                          course.TotalTimeInSec/ 3600
                        ) + " hrs"
                      }}</span>
                <span v-else-if="course.TotalTimeInSec >= 60">{{
                        Math.floor(
                          course.TotalTimeInSec / 60
                        ) + " Min"
                      }}</span>
                <span v-else>{{
                      course.TotalTimeInSec + " Sec"
                    }}</span>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "../Api";

export default {
  data() {
    return {
      courses: [],
      coursedata: [],
      courseBatchList: [],
      courseId: null,
      batchId: null,
      dataId: "",
      StartDate: "",
      EndDate: "",
      dateRangeType: 1,
      calender: false,
      selectCourse: -1,
      selectBatch: -1,
      CourseValidation: false,
      BatchValidation: false,
      isDateRangeOpen: false,
      CourseValidationMessage: "",
      BatchValidationMessage: "",
      DatevalidationMessage: "",
      isStartDateSelected: false,
      isEndDateSelected: false,
      Timeinterval: [
        "Today",
        "Yesterday",
        "Last Week",
        "Custom",
      ],
    };
  },
  created() {
    const user = this.$store.getters.user;
    API.getCourses({}, (response) => {
      this.coursedata = response.data;
    });
    API.getBatches(user.AffiliationId, (response) => {
      this.batchData = response.data;
    });
  },
  methods: {
    onCourseChange(event) {
      this.courseBatchList = this.batchData.filter(
        /*eslint-disable */
          (a) => a.CourseId == event.target.value,
          this.CourseValidation = false,
        );
        this.courseId = Number(event.target.value);
      },
      onBatchChange(event) {
        this.batchId = Number(event.target.value);
        this.BatchValidation = false;
      },
      getcourses() {
        if (this.selectCourse === -1) {
          this.CourseValidation = true;
          this.CourseValidationMessage = "Please select course";
        } else if (this.selectBatch === -1) {
          this.BatchValidation = true;
          this.BatchValidationMessage = "Please select Batch";
        }
        let beginDate = moment();
        let endingDate = moment();
        if (this.dateRangeType === -1) {
          return;
        }
        if (this.dateRangeType === 1) {
          beginDate = beginDate.format("YYYY-MM-DD");
          endingDate = endingDate.format("YYYY-MM-DD");
        } else if (this.dateRangeType === 2) {
          beginDate = moment().subtract(1, "days").format("YYYY-MM-DD");
          endingDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        } else if (this.dateRangeType === 3) {
          beginDate = moment().subtract(1, "weeks").startOf("week");
          beginDate = beginDate.format("YYYY-MM-DD");
          endingDate = moment().format("YYYY-MM-DD");
        } else if (this.dateRangeType === 4) {
          beginDate = this.StartDate;
          endingDate = this.EndDate;
          if (beginDate === "") {
            this.DatevalidationMessage = "Please Select start date";
            this.isStartDateSelected = true;
            return;
          }
          if (endingDate === "") {
            this.DatevalidationMessage = "Please Select end date";
            this.isEndDateSelected = true;
            this.isStartDateSelected = false;
            return;
          }
          if (beginDate !== "" && endingDate !== "") {
            beginDate = moment(beginDate, "YYYY-MM-DD");
            endingDate = moment(endingDate, "YYYY-MM-DD");
            const differenceOfDays = endingDate.diff(beginDate, "days");
            if (differenceOfDays < 0) {
              window.M.toast({
                html: "End Date can not be less than Start Date",
              });
              return;
            }
          }
        }
        const data = {
          // startDate: "2022-02-01",
          // endDate: "2022-02-15",
          // affiliationId: 18,
          // batchId: 45,
          // courseId: 1,

          startDate: moment(beginDate).format("YYYY-MM-DD"),
          endDate: moment(endingDate).format("YYYY-MM-DD"),
          affiliationId: this.$store.getters.user.AffiliationId,
          batchId: this.batchId,
          courseId: this.courseId,
        };
        if (this.selectCourse !== -1 && this.selectBatch !== -1 && this.startDate !== " " && this
          .endDate !== " ") {
          this.$store.dispatch("showLoader", true);
          const user = this.$store.getters.user;
          API.StudentReport(user.AffiliationId, data, (response) => {
            this.$store.dispatch("showLoader", false);
            this.courses = response.data;
            if (this.courses === []) {
              window.M.toast({
                html: "Record not found",
              });
              this.courses = [];
            }
          });
        }
      },
      showCalendar() {
        if (this.dateRangeType === 4) {
          this.isDateRangeOpen = true;
          this.isStartDateSelected = false;
          this.isEndDateSelected = false;
        } else {
          this.isDateRangeOpen = false;
        }
      },
    },

  };

</script>

<style scoped>
  .contentheader {
    margin-top: 19px;
    font-size: 30px;
    color: #642c90;
  }

  /* .coursecard {
    margin-top: 24.5px;
    max-width: 1176px;
    height: 110px;
    left: 132px;
    top: 130.5px;
    margin-bottom: 13px;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  } */
  .bodyTitle {
    margin-bottom: 8px;
    font-size: 19px;
  }

  .dropd {
    border-radius: 5px;
    width: 268px;
    height: 36px;
  }

  .dbtn {
    width: 144px;
    height: 36px;
    margin-top: 36px;
    background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  }

  .datepicker {
    width: 268px;
    height: 36px;
    border-radius: 5px;
    border: 1.5px solid #E3E3E3;
  }

  table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }

  table.qtl thead tr td:first-child {
    border-radius: 5px 0 0 5px;
  }

  table.qtl thead tr td {
    border-radius: 0;
  }

</style>
