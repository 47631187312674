<template>
  <div>
    <div class="pracard scroll-horizontal" style="display: flex; flex-direction: column">
      <div
        class="recommend-box recommendation-box relative card-radius-2"
        style="padding: 12px; overflow: hidden"
        :style="`background: ${backgroundColor}`"
      >
        <h2 class="fs-12 dark-text fw-600" unselectable="on">
          {{ chapterName?.length > 20 ? chapterName.slice(0, 20) + ".." : chapterName }}
        </h2>
        <h3 class="fs-9 light-text fw-600" unselectable="on" v-if="date !== ''">
          {{ formatDate(date) }}
        </h3>
        <div class="flex flex-between">
          <p class="fs-12 fw-600" style="opacity: 0.6">{{ subjectName }}</p>
          <img
            v-if="buttonName === 'Start'"
            class="practice-image"
            :src="subjectIcon ? subjectIcon : randomImage"
            @click="startPractice(data)"
          />
          <img
            v-if="buttonName === 'Resume'"
            class="practice-image"
            :src="subjectIcon ? subjectIcon : randomImage"
            @click="resumePractice()"
          />
          <img
            v-if="buttonName === 'Report'"
            class="practice-image"
            :src="subjectIcon ? subjectIcon : randomImage"
            @click="practiceReport(data)"
          />
          <button
            class="yellow-btn"
            v-if="buttonName === 'Start'"
            style="width: 59px; height: 21px"
            @click="startPractice(data)"
          >
            {{ buttonName }}
          </button>
          <button
            class="yellow-btn"
            v-if="buttonName === 'Resume'"
            style="width: 59px; height: 21px"
            @click="resumePractice()"
          >
            {{ buttonName }}
          </button>
          <button
            class="yellow-btn"
            v-if="buttonName === 'Report'"
            style="width: 59px; height: 21px"
            @click="practiceReport(data)"
          >
            {{ buttonName }}
          </button>
        </div>
      </div>
      <div class="flex flex-between" style="margin-bottom: 5px; margin-top: 2px">
        <div v-if="totalQuestion !== null" class="flex">
          <img
            style="height: 18px; width: 18px; margin-right: 6px"
            :src="require(`../assets/Question1.svg`)"
          />
          <span class="fw-700 fs-13" style="color: #5c8eff !important">{{ totalQuestion }}</span>
        </div>
        <div v-if="accuracy !== null" class="flex">
          <img
            style="height: 15px; width: 15px; margin-top: 2px; margin-right: 6px"
            :src="require(`../assets/target.svg`)"
          />
          <span class="fw-700 fs-13" style="color: #ef727a !important">{{ accuracy }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { json } from "body-parser";
import moment from "moment";
import Multiple from "../assets/Multiple.png";
import Mobileapi from "../Mobileapi";
// import Mobileapi from "../Mobileapi";
// import Doubtapi from "../Doubtapi";

export default {
  data() {
    return {
      mouse: false,
      randomImage: Multiple,
      userDetails: [],
      difficultyLevel: null,
      obj: this.propsdata,
      requestBody: {},
    };
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD MMMM YYYY");
      }
      return null;
    },
    startPractice(chapter) {
      // console.log(chapter);
      const chapterData = JSON.parse(chapter);
      this.$store.dispatch("showLoader", true);
      // if (this.checkbox === true) {
      //   this.isSubjective = 1;
      //   if (this.isSubjective === 1) {
      //     this.questionTypeIds = "4";
      //   }
      // } else if (this.checkbox === false) {
      //   this.isSubjective = 0;
      //   if (this.isSubjective === 0) {
      //     this.questionTypeIds = "1,3,4";
      //   }
      // }
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.userCourseId = Number(userDetail.courseId);
      const user = this.$store.getters.user;
      if (this.userCourseId !== 1) {
        const data = {
          courseChapterIds: chapterData.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1,2",
          isPastYear: 0,
        };
        Mobileapi.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(userDetail.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(userDetail.courseId),
              courseChapterIdList: [chapterData.courseChapterId],
              chapterIdList: [chapterData.chapterId],
              lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
            };
            Mobileapi.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: userDetail.courseId,
                      subjectName: userDetail.selectedSub,
                      subjectId: userDetail.subjectId,
                      className: userDetail.className,
                      chapters: chapterData.chapterName,
                      isSubjective: 0,
                      LpsId: this.lpsId ? this.lpsId : 0,
                      LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      } else if (this.userCourseId === 1) {
        const data = {
          courseChapterIds: chapterData.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1",
          isPastYear: 0,
        };
        Mobileapi.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(userDetail.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(userDetail.courseId),
              courseChapterIdList: [chapterData.courseChapterId],
              chapterIdList: [chapterData.chapterId],
              lpsId: this.lpsId ? parseInt(this.lpsId) : 0,
            };
            Mobileapi.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: userDetail.courseId,
                      subjectName: userDetail.selectedSub,
                      subjectId: userDetail.subjectId,
                      className: userDetail.className,
                      chapters: chapterData.chapterName,
                      isSubjective: 0,
                      LpsId: this.lpsId ? this.lpsId : 0,
                      LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      }
    },
    resumePractice() {},
    practiceReport(chapter) {
      const chapterData = JSON.parse(chapter);

      this.$router
        .push({
          name: "PracticeSessionNewReport",
          params: {
            UserTestSessionId: chapterData.userTestSessionId,
            Name: this.userDetails.Name,
            Chapters: this.chapterName,
            SubjectName: this.subjectName,
            StartedOn: this.startDate,
            fromPractice: 1,
          },
          query: { feedback: true },
        })
        .catch(() => {});
    },
  },
  props: {
    chapterName: String,
    subjectName: String,
    subjectIcon: String,
    buttonName: String,
    backgroundColor: String,
    date: String,
    totalQuestion: Number,
    accuracy: Number,
    userTestSessionId: Number,
    data: String,
    lpsId: Number,
    lpsEndTime: String,
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin: 0;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.dark-text {
  color: #333333 !important;
}

.light-text {
  color: #000000 !important;
  opacity: 0.3;
}

.relative {
  position: relative !important;
}

.lightPink-bgc {
  background: #ffecdb;
}

.card-radius-2 {
  border-radius: 6px;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.pracard {
  width: 170px;
  margin-right: 15px;
  /* margin-bottom: 15px; */
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

.recommend-box {
  width: 170px;
  height: 80px;
  border-radius: 6px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.yellow-btn {
  width: 85px;
  height: 24px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  z-index: 20;
}

.progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.recommendation-box:hover .yellow-btn {
  opacity: 1;
}

@media screen and (max-width: 830px) {
  .pracard {
    width: 150px !important;
    margin-right: 0 !important;
  }

  .recommend-box {
    width: 150px !important;
    height: 80px !important;
    /* border-radius: 6px; */
  }
}
</style>
