<template>
    <svg width="18" height="18" viewBox="0 0 18 18" :fill="bgColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99983 5.42822C8.21245 5.42822 7.57129 6.06938 7.57129 6.85676C7.57129 7.64415 8.21245 8.2853 8.99983 8.2853C9.78721 8.2853 10.4284 7.64415 10.4284 6.85676C10.4284 6.06938 9.78721 5.42822 8.99983 5.42822Z" :fill="bgColor" />
        <path d="M13.9998 6.85683C13.9998 6.13693 13.5892 5.51827 12.9902 5.20332C13.1899 4.55935 13.0437 3.83102 12.5347 3.32203C12.0257 2.81304 11.2974 2.66681 10.6534 2.86647C10.3384 2.2675 9.71979 1.85693 8.99989 1.85693C8.28 1.85693 7.66134 2.2675 7.34638 2.86647C6.70242 2.66681 5.97409 2.81304 5.4651 3.32203C4.95611 3.83102 4.80988 4.55935 5.00954 5.20332C4.41338 5.51827 4 6.13412 4 6.85683C4 7.57672 4.41056 8.19538 5.00954 8.51033C4.80988 9.1543 4.95611 9.88263 5.4651 10.3916C5.71256 10.6391 6.01064 10.7966 6.3256 10.8725L4.73677 14.9135C4.64397 15.1497 4.883 15.3803 5.1164 15.279L6.26654 14.3763C6.40996 14.3145 6.57868 14.3792 6.64336 14.5254L6.86833 15.968C6.96956 16.2014 7.3042 16.1958 7.397 15.9596L9.00552 11.8708L10.614 15.9596C10.7068 16.1958 11.0415 16.2014 11.1427 15.968L11.3677 14.5254C11.4295 14.382 11.5983 14.3145 11.7445 14.3763L12.8918 15.279C13.1252 15.3803 13.3643 15.1497 13.2715 14.9135L11.6826 10.8697C11.9948 10.7909 12.2928 10.6335 12.5375 10.3888C13.0465 9.87982 13.1927 9.15149 12.9931 8.50752C13.5892 8.19538 13.9998 7.57672 13.9998 6.85683V6.85683ZM8.99989 9.71391C7.42512 9.71391 6.14281 8.43159 6.14281 6.85683C6.14281 5.28206 7.42512 3.99974 8.99989 3.99974C10.5747 3.99974 11.857 5.28206 11.857 6.85683C11.857 8.43159 10.5747 9.71391 8.99989 9.71391Z" :fill="bgColor" />
    </svg>
</template>

<script>
export default {
  props: {
    bgColor: String,
  },
};
</script>
