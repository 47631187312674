<template>
<div class="container">
    <strong class="contentheader">Student Doubt Report</strong>
    <div class="card-panel">
        <div class="row">
            <div class="col s12 m3">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>Course</strong></div>
                    <select class="browser-default dropd" @change="onCourseChange($event)" v-model="selectCourse">
                        <option value="" disabled selected>-- select --</option>
                        <option :value="data.courseId" v-for="data in coursedata" :key="data.affiliationCourseId">
                            {{ data.courseName }}
                        </option>
                    </select>
                    <div v-show="CourseValidation" style="color: red">
                        {{ this.CourseValidationMessage }}
                    </div>
                </div>
            </div>
            <div class="col s12 m3">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>Subjects</strong></div>
                    <select class="browser-default dropd" @change="onSubjectChange($event)" v-model="selectSubject">
                        <option value="" disabled selected>-- select --</option>
                        <option value="0" selected>All Subjects</option>
                        <option :value="data.SubjectId" v-for="data in subjectsList" :key="data.SubjectId">
                            {{ data.SubjectName }}
                        </option>
                    </select>
                    <div v-show="SubjectValidation" style="color: red">
                        {{ this.SubjectValidationMessage }}
                    </div>
                </div>
            </div>
            <!-- <div class="col s12 m6 l3" style="margin-right: 15px;">
          <div class="bodycontent">
            <div class="bodyTitle"><strong>Batch</strong></div>
            <select class="browser-default dropd" @change="onBatchChange($event)"
              v-model="selectBatch">
              <option value="" disabled selected>-- select --</option>
              <option :value="batch.BatchId" v-for="batch in courseBatchList" :key="batch.BatchId">
                {{ batch.BatchName }}
              </option>
            </select>
            <div v-show="BatchValidation" style="color: red">
              {{ this.BatchValidationMessage }}
            </div>
          </div>
        </div> -->
            <div class="col s12 m3">
                <div class="bodyTitle"><strong>Batch</strong></div>
                <VueMultiselect tag-placeholder="Add this Batch" v-model="selectBatch" placeholder="Search Or Add Batch" @update:model-value="updateSelectedBatch" label="BatchName" track-by="BatchId" :options="courseBatchList" :multiple="true" :taggable="true" :limit="1" @tag="addBatch">
                    <template v-slot:beforeList>
                        <div>
                            <button @click="selectBatchNone">None</button>
                            <button @click="selectBatchAll">All</button>
                        </div>
                    </template>
                </VueMultiselect>
                <div v-show="BatchValidation" style="color: red">
                    {{ this.BatchValidationMessage }}
                </div>
            </div>
            <div class="col s12 m3">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>Status</strong></div>
                    <select class="browser-default dropd" @change="selectDoubts($event)">
                        <option value="" disabled selected>Select Here</option>
                        <option value=0 selected>Pending</option>
                        <option value=1>Resolved</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m3">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>Date</strong></div>
                    <select class="browser-default dropd" v-model="dateRangeType" @change="showCalendar()">
                        <option value="Date" disabled selected>Select Here</option>
                        <option v-for="(period, index) in Timeinterval" :key="period" :value="index + 1">
                            {{ period }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col s12 m3" v-if="isDateRangeOpen">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>Start Date</strong></div>
                    <input type="date" name="start date" class="browser-default datepicker" v-model="StartDate" @change="showCalendar()" />
                    <div v-show="isStartDateSelected" style="color: red">
                        {{ this.DatevalidationMessage }}
                    </div>
                </div>
            </div>
            <div class="col s12 m3" v-if="isDateRangeOpen">
                <div class="bodycontent">
                    <div class="bodyTitle"><strong>End Date</strong></div>
                    <input type="date" name="end Date" class="browser-default datepicker" v-model="EndDate" @change="showCalendar()" />
                    <div v-show="isEndDateSelected" style="color: red">
                        {{ this.DatevalidationMessage }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m3">
                <a class="dbtn waves-effect waves-light btn getclassbutton" @click="getcourses()">Submit</a>
            </div>
        </div>
    </div>

    <div class="row">
        <table class="qtl striped">
            <thead>
                <tr>
                    <td>Student Name</td>
                    <td>Course</td>
                    <td>Subject</td>
                    <td>Topic</td>
                    <td>Chapter</td>
                    <td>Concept 1</td>
                    <td>Correct (Global accuracy)</td>
                    <td>Time (Global correct time)</td>
                    <td>View</td>
                    <td v-if="pending===0">Actions</td>
                    <!-- <td></td> -->
                </tr>
            </thead>
            <tbody class="card-panel">
                <tr v-for="(course, index) in filterPending" :key="index">
                    <td>
                        {{ course.Name }}
                    </td>
                    <td>{{ course.Cource }}</td>
                    <td>
                        {{ course.Subject }}
                    </td>
                    <td>{{ course.TopicName }}</td>
                    <td>{{ course.ChapterName }}</td>
                    <td>{{ course.KSCText }}</td>
                    <td>
                        <span>{{formatGlobalAccuracy(course.IsCorrectlyAnswered)}} ({{GetQuestionAccuracy(course._QuestionScore)}})</span>
                    </td>
                    <td><span>{{GetMinuteSecond(course.TimeTakenTillSubmission)}} ({{GetMinuteSecondBasedOnCorrectlyAnswered(course.IsCorrectlyAnswered,course._AverageTimeTakenInSecCorrectQuestions)}})</span></td>
                    <!-- <td>
                        <div v-if="course">
                            <span v-if="course.TimeTakenTillSubmission >= 3600">{{
                  Math.floor(course.TimeTakenTillSubmission / 3600) + " hrs"
                }}</span>
                            <span v-else-if="course.TimeTakenTillSubmission >= 60">{{
                  Math.floor(course.TimeTakenTillSubmission / 60) + " Min"
                }}</span>
                            <span v-else>{{ course.TimeTakenTillSubmission + " Sec" }}</span>
                        </div>
                    </td> -->
                    <!-- <td style="text-decoration: underline">
                        <a @click="
                  ViewQuestion(
                    course.KSCDiagramURL,
                    course.FullSolutionURL,
                    course.QuestionDiagramURL
                  )
                ">View Questions</a>
                    </td> -->
                    <td class="center" style="display: flex, justify-content: center;cursor:pointer;"><img :src="require(`../assets/eye.svg`)" @click="
                  ViewQuestion(
                    course.KSCDiagramURL,
                    course.FullSolutionURL,
                    course.QuestionDiagramURL
                  )" /></td>
                    <td class="editIcon" v-if="pending===0">
                        <a class="Edit"><i class="material-icons">more_vert</i>
                            <div class="menuEditor">
                                <ul>
                                    <li class="MarkasResolve" @click="getResolve(course.UserTestSessionQuestionId)">Mark as Resolved</li>
                                </ul>
                            </div>
                        </a>
                    </td>
                    <!-- <td class="center">{{getPending(course.Name)}}</td> -->
                    <!-- <td class="center"></td> -->
                    <!-- <td></td> -->
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Modal -->
    <Modal :show="showCampaignModal" @close="showCampaignModal = false" :showHeader="true" :showCloseBtn="true" width="1000px">
        <template v-slot:body>
            <ul class="tabs">
                <li class="tab col s3">
                    <a :class="{ active: selectedTab === 1 }" @click="selectedTab = 1">Question</a>
                </li>
                <li class="tab col s3">
                    <a :class="{ active: selectedTab === 2 }" @click="selectedTab = 2">Answer</a>
                </li>
                <li class="tab col s3">
                    <a :class="{ active: selectedTab === 3 }" @click="selectedTab = 3">Concept</a>
                </li>
            </ul>
            <div class="row">
                <div class="col s12">
                    <img v-if="selectedTab === 1" class="responsive-img" :src="`${this.questiondiagramURL}`" style="max-height: 450px" />
                    <img v-if="selectedTab === 2" class="responsive-img" :src="`${this.fullSolutionURL}`" style="max-height: 450px" />
                    <img v-if="selectedTab === 3" class="responsive-img" :src="`${this.conceptUrl}`" style="max-height: 450px" />
                </div>
            </div>
        </template>
    </Modal>
</div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import moment from "moment";
// import Vue from "vue";
import API from "../Api";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

// Vue.filter("formatGlobalAccuracy", (value) => {
//   let result = "Incorrect";
//   if (value === 1) {
//     result = "Correct";
//   }
//   if (value === 2) {
//     result = "Partially correct";
//   }
//   return result;
// });

export default {
  data() {
    return {
      courses: [],
      coursedata: [],
      courseBatchList: [],
      subjectsList: [],
      selectedBatches: [],
      courseId: null,
      batchId: null,
      subjectId: null,
      dataId: "",
      StartDate: "",
      EndDate: "",
      dateRangeType: 1,
      calender: false,
      selectCourse: -1,
      selectBatch: [],
      selectSubject: -1,
      CourseValidation: false,
      BatchValidation: false,
      isDateRangeOpen: false,
      SubjectValidation: false,
      CourseValidationMessage: "",
      BatchValidationMessage: "",
      DatevalidationMessage: "",
      SubjectValidationMessage: "",
      isStartDateSelected: false,
      isEndDateSelected: false,
      showCampaignModal: false,
      conceptUrl: " ",
      questiondiagramURL: " ",
      fullSolutionURL: " ",
      selectedTab: 1,
      Timeinterval: ["Today", "Yesterday", "Last Week", "Custom"],
      studentDoubtLis: [],
      studentDoubtList: [],
      resolveResponseList: [],
      pendingList: [],
      resolveList: [],
      list: [],
      pending: 0,
      resolve: false,
    };
  },
  created() {
    const user = this.$store.getters.user;
    API.getCourses({}, (response) => {
      this.coursedata = response.data;
    });
    API.getBatches(user.AffiliationId, (response) => {
      this.batchData = response.data;
    });
  },
  computed: {
    filterPending() {
      return this.pending === 0 ? this.pendingList : this.resolveList;
    },
  },
  methods: {
    formatGlobalAccuracy(value) {
      let result = "Incorrect";
      if (value === 1) {
        result = "Correct";
      }
      if (value === 2) {
        result = "Partially correct";
      }
      return result;
    },
    selectDoubts(event) {
      this.pendingList = [];
      this.resolveList = [];
      this.pending = Number(event.target.value);
    },
    GetMinuteSecond(timeInSeconds) {
      let minuteSecString = null;
      const minute = timeInSeconds / 60;
      const seconds = timeInSeconds % 60;

      if (seconds < 10) {
        minuteSecString = `${Math.floor(minute)}:0${seconds}`;
      } else {
        minuteSecString = `${Math.floor(minute)}:${seconds}`;
      }
      return minuteSecString;
    },
    GetMinuteSecondBasedOnCorrectlyAnswered(isCorrectlyAnsweredObj, timeInSecondsCorrectlyAnsweredObj) {
      let timeString = null;
      if (isCorrectlyAnsweredObj != null && isCorrectlyAnsweredObj === 1) {
        const time = timeInSecondsCorrectlyAnsweredObj != null ? timeInSecondsCorrectlyAnsweredObj : 0;
        timeString = this.GetMinuteSecond(time);
      } else {
        const time = timeInSecondsCorrectlyAnsweredObj != null ? timeInSecondsCorrectlyAnsweredObj : 0;
        timeString = this.GetMinuteSecond(time);
      }
      return timeString;
    },
    GetQuestionAccuracy(qAccuracy) {
      let questionAccuracy = qAccuracy;
      questionAccuracy *= 100;
      return `${(Math.round(questionAccuracy))}%`;
    },
    onCourseChange(event) {
      this.selectBatch = [];
      this.courseBatchList = this.batchData.filter(
        /*eslint-disable*/
                (a) => a.CourseId == event.target.value,
                (this.CourseValidation = false),
            );
            this.courseId = Number(event.target.value);
            this.getSubjectsForCourses(this.courseId);
        },
        getResolve(Id) {
            MobileAPI.MarkAsResolved(Number(Id), (response) => {
                this.resolveResponseList = response.data;
                this.getGridInfo();
            });
            window.M.toast({
                html: "Question Resolved Successfully"
            });
        },
        getPending(courseName) {
            const list = this.studentDoubtList.filter(e => e.name === courseName);
            const pending = list.map(e => e.pending);
            return pending;
        },
        onBatchChange(event) {
            this.batchId = Number(event.target.value);
            this.BatchValidation = false;
        },
        onSubjectChange(event) {
            this.subjectId = Number(event.target.value);
            this.SubjectValidation = false;
        },
        addBatch(newTag) {
            const tag = {
                BatchName: newTag,
                BatchId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
            this.SelectedBatchList.push(tag);
            this.selectBatch.push(tag);
        },
        updateSelectedBatch(value) {
            if (this.selectBatch.length >= 0) {
                this.selectedBatches = [];
            }
            this.selectedBatches = [];
            value.forEach((resource) => {
                this.selectedBatches.push(resource);
            });
            const batch = this.selectedBatches.map((a) => a.BatchId);
            this.batchId = batch.join(",");
            this.BatchValidation = false;
        },
        selectBatchNone() {
            this.selectBatch = [];
        },
        selectBatchAll() {
            this.selectBatch = this.courseBatchList;
            this.selectedBatches = this.courseBatchList;
            this.BatchValidation = false;
            const batch = this.selectedBatches.map((a) => a.BatchId);
            this.batchId = batch.join(",");
        },
        getSubjectsForCourses(courseId) {
            API.getSubjectsForCourses(courseId, {}, (resp) => {
                this.subjectsList = resp.data;
            });
        },
        getcourses() {
            if (this.selectCourse === -1) {
                this.CourseValidation = true;
                this.CourseValidationMessage = "Please select course";
            } else if (this.selectBatch.length === 0) {
                this.BatchValidation = true;
                this.BatchValidationMessage = "Please select Batch";
            }
            if (this.selectSubject === -1) {
                this.SubjectValidation = true;
                this.SubjectValidationMessage = "Please select Subject";
            }

            if (
                this.selectCourse !== -1 &&
                this.selectBatch.length !== 0 &&
                this.startDate !== " " &&
                this.endDate !== " "
            ) {
                this.getGridInfo();
                // const dataStud = {
                //     page: 1,
                //     fromDate: moment(beginDate).format("YYYY-MM-DD"),
                //     toDate: moment(endingDate).format("YYYY-MM-DD"),
                //     id: this.$store.getters.user.AffiliationId,
                //     batch: this.batchId,
                //     subjectId: this.subjectId,
                //     courseId: this.courseId,
                // }
                //  console.log(dataStud);
                // MobileAPI.getStudentDoubt(dataStud, (response) => {
                //     this.studentDoubtList = response.data;
                //     console.log("doubtlist", response.data);
                // });
            }
        },
        getGridInfo() {
            let beginDate = moment();
            let endingDate = moment();
            if (this.dateRangeType === -1) {
                return;
            }
            if (this.dateRangeType === 1) {
                beginDate = beginDate.format("YYYY-MM-DD");
                endingDate = endingDate.format("YYYY-MM-DD");
            } else if (this.dateRangeType === 2) {
                beginDate = moment().subtract(1, "days").format("YYYY-MM-DD");
                endingDate = moment().subtract(1, "days").format("YYYY-MM-DD");
            } else if (this.dateRangeType === 3) {
                beginDate = moment().subtract(1, "weeks").startOf("week");
                beginDate = beginDate.format("YYYY-MM-DD");
                endingDate = moment().format("YYYY-MM-DD");
            } else if (this.dateRangeType === 4) {
                beginDate = this.StartDate;
                endingDate = this.EndDate;
                if (beginDate === "") {
                    this.DatevalidationMessage = "Please Select start date";
                    this.isStartDateSelected = true;
                    return;
                }
                if (endingDate === "") {
                    this.DatevalidationMessage = "Please Select end date";
                    this.isEndDateSelected = true;
                    this.isStartDateSelected = false;
                    return;
                }
                if (beginDate !== "" && endingDate !== "") {
                    beginDate = moment(beginDate, "YYYY-MM-DD");
                    endingDate = moment(endingDate, "YYYY-MM-DD");
                    const differenceOfDays = endingDate.diff(beginDate, "days");
                    if (differenceOfDays < 0) {
                        window.M.toast({
                            html: "End Date can not be less than Start Date",
                        });
                        return;
                    }
                }
            }
            const data = {
                // startDate: "2022-02-01",
                // endDate: "2022-02-15",
                // affiliationId: 18,
                // batchId: 45,
                // courseId: 1,

                startDate: moment(beginDate).format("YYYY-MM-DD"),
                endDate: moment(endingDate).format("YYYY-MM-DD"),
                affiliationId: this.$store.getters.user.AffiliationId,
                centerIds: this.batchId,
                subjectId: this.subjectId,
                courseId: this.courseId,
            };
            this.$store.dispatch("showLoader", true);
            const user = this.$store.getters.user;
            API.StudentDoubtReport(user.AffiliationId, data, (response) => {
                this.$store.dispatch("showLoader", false);
                this.courses = response.data;
                this.pendingList = this.courses.filter(e => e.IsTutorHelpTaken === false);
                this.resolveList = this.courses.filter(e => e.IsTutorHelpTaken !== false);
                if (this.courses.length === 0) {
                    window.M.toast({
                        html: "Record not found",
                    });
                    this.courses = [];
                }
            });
        },
        showCalendar() {
            if (this.dateRangeType === 4) {
                this.isDateRangeOpen = true;
                this.isStartDateSelected = false;
                this.isEndDateSelected = false;
            } else {
                this.isDateRangeOpen = false;
            }
        },
        ViewQuestion(KSCDiagramURL, FullSolutionURL, QuestionDiagramURL) {
            this.selectedTab = 1;
            this.showCampaignModal = true;
            this.conceptUrl = KSCDiagramURL;
            this.fullSolutionURL = FullSolutionURL;
            this.questiondiagramURL = QuestionDiagramURL;
        },
    },
    components: {
        Modal,
        VueMultiselect,
    },
};
</script>

<style scoped>
[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
    border: 2px solid #f59e00;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
    background-color: #f59e00;
}

.contentheader {
    margin-top: 19px;
    font-size: 26px;
    color: #642c90;
}

.editIcon {
    text-align: center;
    position: absolute;
    padding: 0px;
    margin-left: 20px;
}

.editIcon a {
    text-align: center;
    position: absolute;
    padding: 0px;
    margin-top: 20px;
}

a.Edit:hover .menuEditor {
    visibility: visible;
    opacity: 1;
}

.menuEditor {
    box-sizing: border-box;
    position: absolute;
    width: 140px;
    height: 45px;
    margin-top: -10px;
    margin-left: -33px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
    border-radius: 5px;
    overflow: hidden;
    visibility: hidden;
    display: block;
    z-index: 500 !important;
    color: #000;
}

.menuEditor ul li:hover {
    color: #ee7800;
    margin: 0px;
    font-size: 14px;
}

.MarkasResolve {
    position: absolute;
    height: 16px;
    left: 20px;
    top: 14px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    opacity: 0.75
}

/* .coursecard {
  margin-top: 24.5px;
  max-width: 1176px;
  height: 110px;
  left: 132px;
  top: 130.5px;
  margin-bottom: 13px;
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
} */
.bodyTitle {
    margin-bottom: 8px;
    font-size: 19px;
}
	
.dropd {
    border-radius: 5px;
    height: 36px;
}
.dbtn {
    width: 144px;
    height: 36px;
    margin-top: 30px;
    background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
.datepicker {
    width: 100%;
    height: 36px;
    border-radius: 5px;
    border: 1.5px solid #e3e3e3;
}

table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
}

table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
}

table.qtl thead tr td:first-child {
    border-radius: 5px 0 0 5px;
}

table.qtl thead tr td {
    border-radius: 0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
