<template>
  <div>
    <LearnHeader
      v-show="showMaterial"
      learnTitle="Study Material"
      @chapterData="getChapterData($event)"
      :ConceptChapterNameChange="selectChapterChangeName"
      @userDetail="getUserDetail($event)"
      @chapterList="getChapterList($event)"
      @bgColor="getBackgroundColor($event)"
      @subjectIndex="getSubjectIndex($event)"
    />
    <div v-if="showMaterial">
      <div class="content-header">
        <li class="content-tab col s12 m3">
          <a
            :class="{ active: selectedTab === 1 }"
            class="content-selector"
            @click="selectedExamPrepTab"
            >Exam Preparations</a
          >
        </li>
        <li class="content-tab col s12 m3" v-if="instituteTabVisible">
          <a
            :class="{ active: selectedTab === 2 }"
            class="content-selector"
            @click="selectedInstituteNotesTab"
            >Institute Notes</a
          >
        </li>
      </div>
      <div class="topic-content">
        <div class="row">
          <div v-if="selectedTab === 1" style="display: flex; flex-direction: column">
            <div v-if="chapternotes.length > 0">
              <h2 class="notes-header">Chapter Notes ({{ chapternotes.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in chapternotes" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="imptques.length > 0">
              <h2 class="notes-header">Important Qs ({{ imptques.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in imptques" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="solvedexmp.length > 0">
              <h2 class="notes-header">Solved Examples ({{ solvedexmp.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in solvedexmp" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="Formulaesheet.length > 0">
              <h2 class="notes-header">Formulae Sheet ({{ Formulaesheet.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in Formulaesheet" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="Ncertques.length > 0">
              <h2 class="notes-header">Ncert Qs ({{ Ncertques.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in Ncertques" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="other.length > 0">
              <h2 class="notes-header">Other ({{ other.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in other" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedTab === 2" style="display: flex; flex-direction: column">
            <div v-if="instituteChapterNotes.length > 0">
              <h2 class="notes-header">
                institute chapter notes ({{ instituteChapterNotes.length }})
              </h2>
              <div
                class="col l3 m4 s12"
                v-for="(item, index) in instituteChapterNotes"
                :key="index"
              >
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="instimptques.length > 0">
              <h2 class="notes-header">institute imp Qs ({{ instimptques.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in instimptques" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="instsolvedexmp.length > 0">
              <h2 class="notes-header">institute solved Example ({{ instsolvedexmp.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in instsolvedexmp" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="instFormulaesheet.length > 0">
              <h2 class="notes-header">
                institute Formulae sheet ({{ instFormulaesheet.length }})
              </h2>
              <div class="col l3 m4 s12" v-for="(item, index) in instFormulaesheet" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="instNcertques.length > 0">
              <h2 class="notes-header">institute ncret Qs ({{ instNcertques.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in instNcertques" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="instother.length > 0">
              <h2 class="notes-header">institute Other ({{ instother.length }})</h2>
              <div class="col l3 m4 s12" v-for="(item, index) in instother" :key="index">
                <div class="topic-box" :class="`sub-index-${selectedSubjectIndex}`">
                  <div class="topic-header">
                    <h2>{{ item.FileName }}</h2>
                  </div>
                  <div class="topic-footer">
                    <p class="topic-attempt" @click="openPreview(item)">preview</p>
                    <a
                      class="topic-attempt"
                      :download="item.FileReference"
                      :href="item.FileReference"
                      >download</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Preview -->
    <Modal
      :show="previewMaterial1"
      @close="previewMaterial1 = false"
      :width="'98%'"
      :maxHeightBody="'100vh'"
      :height="'94%'"
      :showCloseBtn="true"
    >
      <template v-slot:body class="assignment-modal-body">
        <iframe
          allowfullscreen="allowfullscreen"
          scrolling="no"
          class="fp-iframe"
          :src="`https://heyzine.com/api1?pdf=${previewPdf}&fs=1&pn=1&tpl=125f82ac36d74f8cb66b203f80bd6ba2740654d2.pdf&k=${flipper_key}`"
          style="border: 1px solid lightgray; width: 100%; max-height: 90vh; min-height: 90vh"
        ></iframe>
      </template>
    </Modal>
    <!-- <Modal :show="previewMaterial2" @close="previewMaterial2 = false;" :width="'80%'" :maxHeightBody="'600px'">
            <template v-slot:body>
                <div class="embed-responsive embed-responsive-16by9 recVidImg">
                <iframe :src="`https://drive.google.com/viewerng/viewer?url=${previewPdf}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`" width="100%" height='500px' ></iframe>
                </div>
            </template>
        </Modal> -->
  </div>
</template>

<script>
import LearnHeader from "./LearnHeader.vue";
import Doubtapi from "../Doubtapi";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      showMaterial: true,
      selectChapterChangeName: "",
      selectedTab: 1,
      selectedSubjectIndex: "",
      user: "",
      backgroundColor: "",
      notesList: [],
      chapterId: null,
      chapternotes: [],
      imptques: [],
      solvedexmp: [],
      Formulaesheet: [],
      other: [],
      Ncertques: [],
      previewMaterial1: false,
      previewMaterial2: false,
      previewHeader: "",
      previewPdf: "",
      getChapterLists: [],
      instituteChapterNotes: [],
    };
  },
  activated() {
    this.selectedTab = 1;
  },
  components: {
    LearnHeader,
    Modal,
  },
  computed: {
    flipper_key() {
      return process.env.VUE_APP_FLIPPER;
    },
    instituteTabVisible() {
      if (
        this.instituteChapterNotes?.length > 0 ||
        this.instimptques?.length > 0 ||
        this.instsolvedexmp?.length > 0 ||
        this.instFormulaesheet?.length > 0 ||
        this.instother?.length > 0 ||
        this.instNcertques?.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getUserDetail(userdetails) {
      this.user = userdetails;
    },

    getChapterData(chapterData) {
      this.chapterId = chapterData.ChapterId;
      this.selectedTab = 1;
      this.$store.dispatch("showLoader", true);
      Doubtapi.getChapterNotes(
        {
          chapterId: this.chapterId,
        },
        (response1) => {
          this.noteslist = response1.data;
          // Exam Preparations
          this.chapternotes = this.noteslist.filter(
            (a) => a.FileTypeId === 2 && a.AffiliationId === 0 && a.CenterId === 0,
          );

          this.imptques = this.noteslist.filter(
            (a) => a.FileTypeId === 3 && a.AffiliationId === 0 && a.CenterId === 0,
          );

          this.solvedexmp = this.noteslist.filter(
            (a) => a.FileTypeId === 6 && a.AffiliationId === 0 && a.CenterId === 0,
          );

          this.Formulaesheet = this.noteslist.filter(
            (a) => a.FileTypeId === 1 && a.AffiliationId === 0 && a.CenterId === 0,
          );

          this.other = this.noteslist.filter(
            (a) => a.FileTypeId === 9 && a.AffiliationId === 0 && a.CenterId === 0,
          );

          this.Ncertques = this.noteslist.filter(
            (a) => a.FileTypeId === 10 && a.AffiliationId === 0 && a.CenterId === 0,
          );
          // Institute Notes
          this.instituteChapterNotes = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 2 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );

          this.instimptques = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 3 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );

          this.instsolvedexmp = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 6 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );

          this.instFormulaesheet = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 1 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );

          this.instother = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 9 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );

          this.instNcertques = this.noteslist.filter(
            (a) =>
              a.FileTypeId === 10 &&
              a.AffiliationId === Number(this.user.AffiliationId) &&
              a.CenterId === Number(this.user.CenterCodeId),
          );
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    openPreview(notesData) {
      this.$store.dispatch("showLoader", true);
      this.previewPdf = encodeURIComponent(notesData.FileReference);
      this.previewHeader = notesData.FileName;
      this.previewMaterial1 = true;
      setTimeout(() => {
        this.$store.dispatch("showLoader", false);
      }, 3000);
    },
    cancelPreview() {
      this.previewMaterial1 = false;
      this.showMaterial = true;
      this.previewPdf = "";
    },
    getChapterList(chapterList) {
      this.getChapterLists = chapterList;
    },
    getBackgroundColor(bgcolor) {
      this.backgroundColor = bgcolor;
    },
    getSubjectIndex(subjectIndex) {
      this.selectedSubjectIndex = subjectIndex;
    },
    selectedExamPrepTab() {
      if (this.selectedTab !== 1) {
        this.selectedTab = 1;
      }
    },
    selectedInstituteNotesTab() {
      if (this.selectedTab !== 2) {
        this.selectedTab = 2;
      }
    },
  },
};
</script>

<style scoped>
li {
  list-style: none;
}

.content-header {
  background-color: transparent;
  width: fit-content;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  margin: 10px 0;
  list-style: none;
  display: flex;
}

.content-header .content-tab {
  height: auto;
  text-align: left;
  line-height: unset;
  line-height: 30px;
}

.content-tab a {
  position: relative;
  margin: 0px 40px 0 0;
  padding: 0;
  color: rgba(29, 30, 30, 0.5);
  text-align: left;
  font-weight: normal;
}

.content-tab a.active {
  color: #000;
}

.content-tab a.active::after {
  background: #584f52;
  width: 10px;
}

.content-tab a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  z-index: 9;
  background: rgba(29, 30, 30, 0.5);
  transition: all 0.3s ease;
}

.content-tab a:hover::after {
  width: 10px;
}

/****/
.topic-box {
  height: 12.125rem;
  margin-bottom: 1.875rem;
  background-color: #fff;
  max-width: 100%;
  padding: 1.5625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 0.3125rem;
  -webkit-box-shadow: 0 0 30px 0 rgb(0 0 0 / 13%);
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 13%);
  border-style: solid;
  border-width: 0.0625rem;
  border-color: #e8e8e8;
  transition: all 0.3s ease;
}

.topic-box:hover {
  background-color: #f9a825;
  color: #fff;
}

.topic-box.sub-index-1:hover {
  background-color: #f9a825;
  color: #fff;
}

.topic-box.sub-index-2:hover {
  background-color: #4cb4aa;
  color: #fff;
}

.topic-box.sub-index-3:hover {
  background-color: #64b5f6;
  color: #fff;
}

.topic-box.sub-index-4:hover {
  background-color: #e47272;
  color: #fff;
}

.topic-box.sub-index-5:hover {
  background-color: #7885ca;
  color: #fff;
}

.topic-box.sub-index-6:hover {
  background-color: #ffd54f;
  color: #fff;
}

.topic-box.sub-index-7:hover {
  background-color: #4ccfe0;
  color: #fff;
}

.topic-box:hover .topic-header h2,
.topic-box:hover .topic-attempt {
  color: #fff;
}

.topic-box:hover .concept-number {
  color: #e5ebea;
}

.topic-header h2 {
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  color: #21201f;
  font-weight: 600;
  transition: all 0.3s ease;
  line-height: 1.4;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.concept-number {
  color: #a2b1bc;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  transition: all 0.3s ease;
}

.topic-box .topic-footer {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.topic-box .topic-footer p {
  margin: 0;
}

.topic-attempt {
  color: #338510;
  font-size: 13px;
  margin: 0.625rem 0 0 0 !important;
  transition: all 0.3s ease;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}

.concept-content {
  margin-top: 30px;
}

.concept-content .diagramDiv {
  position: relative;
}

.notes-header {
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: normal;
  color: #21201f;
  position: relative;
  padding-left: 0.9375rem;
  text-transform: capitalize;
  margin: 10px 0;
}

.notes-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3125rem;
  height: 100%;
  border-radius: 5px;
  background-color: #584f52;
}

.concept-content .diagramDiv {
  position: relative;
}

.concept-content .diagramDiv .diagramBtns {
  position: absolute;
  width: 100%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.concept-content .diagramDiv .diagramBtns .diagram-btn {
  position: absolute;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  background: #f9a825;
  border: 0.0625rem solid #f9a825;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.concept-content .diagramDiv .diagramBtns .diagramPre {
  left: 10px;
}

.concept-content .diagramDiv .diagramBtns .diagramNext {
  right: 10px;
}

.videoDIv {
  width: 100%;
  padding: 0 30px;
}

.concept-content .videoDIv .video-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.concept-content .videoDIv .video-content::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.concept-content .videoDIv .video-content iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.concept-header {
  margin-top: 16px;
  margin-bottom: 5px;
}

.concept-item {
  background: white;
  border-top: 15px solid #f9a825;
  border-right: 2px solid #f9a825;
  border-radius: 5px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: rgb(66, 63, 63);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -40px;
  margin-top: -15px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(66, 63, 63) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.modaltop-heading {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  text-transform: uppercase;
  margin: 0;
}

.concept-header-column {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  align-items: center;
}

.previewHeader {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  margin: 0;
  margin-left: 30px;
  margin-top: 20px;
}

.concept-image {
  margin: 30px;
  border: 15px solid #f2f2f2;
  border-radius: 5px;
  margin-top: 15px;
}

/* Responsive */
@media screen and (max-width: 600px) {
  .concept-content {
    padding: 0 10px;
  }

  .valign-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .cancel-concept {
    margin-left: 6px;
    float: left !important;
  }

  .concept-header .concept-header-column {
    padding-left: 0px;
    margin-left: 0;
  }
}
</style>
