<template>
  <div class="container">
    <div>
      <div class="head">
        <!-- <strong style="color:Black">Effort Analysis</strong> -->
        <strong><a style="color: Black" @click="Reset()">Test Result</a></strong>
        / <strong v-for="(data, index) in getdata" :key="index" style="color:#642C90">{{data.TestName}}</strong>
      </div>
      <div class="divider divide hide-on-small-only"></div>
      <table class="qtl striped ">
        <thead>
          <tr>
            <td>Name</td>
            <td>Date</td>
            <td>Subject</td>
            <td class="left-align">Stud.Appeared/Total Stud</td>
            <td class="left-align">Duration</td>
            <td class="left-align">Total Questions</td>
            <td class="left-align">Attempt %</td>
            <td class="left-align">Avg Marks</td>
            <td></td>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(data, index) in getdata" :key="index">
            <td class="left-align"><a>{{data.TestName}}</a></td>
            <td class="left-align">{{formatDate(data.CreatedDate)}}</td>
            <td class="center">Maths</td>
            <td class="center">{{ data.TotalStud }}/{{ data.StudentApp }}</td>
            <td class="center">
              <div v-if="data">
                <span v-if="data.Duration >= 3600">{{
                        Math.floor(
                          data.Duration/ 3600
                        ) + " hrs"
                      }}</span>
                <span v-else-if="data.Duration >= 60">{{
                        Math.floor(
                          data.Duration / 60
                        ) + " hr"
                      }}</span>
                <span v-else>{{
                      data.Duration + " min"
                    }}</span>
              </div>
            </td>
            <td class="center">{{data.TotalQuestion}}</td>
            <td class="center">{{data.Attempt}}</td>
            <td class="center">{{data.AvgMarks}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="qtl striped">
        <thead>
          <tr>
            <td class="center">Name</td>
            <td class="center" >Total Questions</td>
            <td class="center" >Correct</td>
            <td class="center" >Wrong</td>
            <td class="center" >Skipped</td>
            <td class="center">Marks</td>
            <td class="center">Accuracy</td>
            <td></td>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(row, index) in testResult" :key="index">
            <td class="center"><a class="center">{{row.Name}}</a></td>
            <td class="center">{{row.TotalQuestions}}</td>
            <td class="center">{{row.Correct}}</td>
            <td class="center">{{row.TotalWrong}}</td>
            <td class="center">{{row.TotalNotAttempted}}</td>
            <td class="center">{{row.Marks}}</td>
            <td class="center">{{row.Accuracy}}</td>
          </tr>
        </tbody>
      </table>
          <!-- <table>
            <tbody>
              <tr>
                <table v-if="extended===index" class="dtltbl centered">
                  <tbody>
                    <tr class="card-panel purple lighten-4">
                      <td class="indigo-text text-darken-4" colspan="2">Breakdown Analysis</td>
                      <td class="indigo-text text-darken-4">Attempted</td>
                      <td class="indigo-text text-darken-4">Correct</td>
                      <td class="indigo-text text-darken-4">Incorrect</td>
                      <td class="indigo-text text-darken-4">Skipped</td>
                      <td></td>
                    </tr>
                    <tr class="card-panel">
                      <td colspan="2">Total Questions</td>
                      <td class="blue-text">{{instId.summary.correct + instId.summary.incorrect}}</td>
                      <td class="green-text">{{instId.summary.correct}}</td>
                      <td class="red-text">{{instId.summary.incorrect}}</td>
                      <td class="grey-text">{{instId.summary.skipped}}</td>
                      <td></td>
                    </tr>
                    <tr class="card-panel">
                      <td colspan="2">Attempt %</td>
                      <td class="blue-text">{{instId.summary.attempt}}</td>
                      <td class="green-text">{{(instId.summary.correct/instId.summary.questions)*100}}</td>
                      <td class="red-text">{{(instId.summary.incorrect/instId.summary.questions)*100}}</td>
                      <td class="grey-text">{{(instId.summary.skipped/instId.summary.questions)*100}}</td>
                      <td></td>
                    </tr>
                    <tr class="card-panel">
                      <td colspan="2">Practice Time</td>
                      <td class="blue-text">
                        {{instId.summary.timeTakenCorrect + instId.summary.timeTakenIncorrect + instId.summary.timeTakenSkipped}}
                      </td>
                      <td class="green-text">{{instId.summary.timeTakenCorrect}}</td>
                      <td class="red-text">{{instId.summary.timeTakenIncorrect}}</td>
                      <td class="grey-text">{{instId.summary.timeTakenSkipped}}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
            </tbody>
          </table> -->
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
import API from "../Api";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).format("DD/MM/YYYY HH:mm a");
//   }
//   return value;
// });
export default {
  data() {
    return {
      resetdata: "",
      InstituteTestIdId: null,
      UserTestData: null,
      testResult: [],
      getdata: [],
      extended: -1,
      testreport: true,
    };
  },
  activated() {
    this.reset();
    // console.log("ConductTest activated");
    this.InstituteTestIdId = parseInt(this.$route.params.instituteTestId);
    this.UserTestData = this.$route.params.testname;
    this.getdata.push(this.UserTestData);
    this.$store.dispatch("showLoader", true);
    API.InstituteTestResult({
      InstituteTestId: this.InstituteTestIdId,
    }, (response) => {
      this.testResult = response.data;
      this.$store.dispatch("showLoader", false);
    });
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm a");
      }
      return value;
    },
    Reset() {
      this.$router.push({
        name: "InstituteTests",
      });
      this.getdata = [];
    },
    reset() {
      this.getdata = [];
    },
    expand(index) {
      if (this.extended === index) {
        this.extended = -1;
      } else {
        this.extended = index;
      }
    },
    getStudentReport(userid, name) {
      const data = {
        userId: userid,
        Name: name,
      };
      this.$router.push({
        name: "TestAnalysisUserReport",
        params: {
          UserId: data.userId, Name: data.Name,
        },
      });
    },
  },
};

</script>

<style scoped>
  .head {
    margin-top: 19px;
    font-size: 25px;
    color: #642C90;
  }

  .divide {
    margin-top: 18.5px;
    width: 1176px;
    height: 0px;
    left: 132px;
    border: 1px solid #E0E0E0;
  }
  .coursecard {
    margin-top: 24.5px;
    max-width: 1176px;
    height: 110px;
    left: 132px;
    top: 130.5px;
    margin-bottom: 13px;
  }

  table.qtl {
    max-width: 1176px;
    left: 132px;
    top: 130.5px;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }
  table.qtl thead tr td {
    border-radius: 0;
  }
  table.dtltbl {
    font-size: 1rem;
  }

  table.dtltbl thead tr {
    background-color: #f4e6fd;
    color: #6a2f85;
    border-spacing: 10px;
  }

  table.dtltbl tbody td {
    border-radius: 0;
  }
    .container {
    max-width: 1176px;
  }
  .icn{
    cursor: pointer;
  }
</style>
