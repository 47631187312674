<template>
  <div>
    <LearnHeader
      learnTitle="Videos"
      @chapterData="getChapterData($event)"
      @userDetail="getUserDetail($event)"
      @bgColor="getBackgroundColor($event)"
      @subjectIndex="getSubjectIndex($event)"
    />
    <div class="content-tab">
      <div class="content-header">
        <div class="video-tabs">
          <li class="video-tab col s12 m3">
            <a
              :class="{ active: selectedTab === 1 }"
              class="video-selector"
              @click="selectedConceptTab"
              >Concept Videos</a
            >
          </li>
          <li class="video-tab col s12 m3">
            <a
              :class="{ active: selectedTab === 2 }"
              class="video-selector"
              @click="selectedExampleTab"
              >Example Videos</a
            >
          </li>
          <li class="video-tab col s12 m3">
            <a
              :class="{ active: selectedTab === 3 }"
              class="video-selector"
              @click="selectedInstituteTab"
              >Institute Videos</a
            >
          </li>
        </div>
        <div class="grid-list-icon" @click="gridListView">
          <span class="material-icons">{{ mainGallery ? "apps" : "view_list" }}</span>
        </div>
      </div>
      <div class="concept-video learn-video" v-if="selectedTab === 1">
        <div class="row">
          <div class="col s12 video-content-box-1" v-if="mainGallery">
            <div class="video-list-container">
              <div
                class="video-list"
                v-for="video in conceptVideo"
                :key="video.id"
                @click="videoTrigger(video.url, video.title)"
                :class="`sub-index-${selectedSubjectIndex}`"
              >
                <div class="player-class">
                  <!-- <vimeo-player :video-url="video.url"  :player-width="250" :player-height="110"></vimeo-player> -->
                  <iframe
                    referrerpolicy="origin"
                    v-if="video.url.includes('vimeo')"
                    :src="video.url"
                    frameborder="0"
                    width="250"
                    height="(250/2)"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p class="list-title">{{ video.title }}</p>
              </div>
            </div>
          </div>
          <div class="video-gallery" v-if="openCurrentVideo">
            <div class="main-video">
              <div class="video wrapper">
                <div class="h_iframe">
                  <!-- <vimeo-player :video-url="currentVideoUrl" frameborder="0"  allow=" fullscreen; picture-in-picture" allowfullscreen :player-height="(playerWidth/2)"  :player-width="playerWidth"></vimeo-player> -->
                  <iframe
                    referrerpolicy="origin"
                    v-if="currentVideoUrl.includes('vimeo')"
                    :src="currentVideoUrl"
                    frameborder="0"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <h3 class="title">{{ currentVideoTitle }}</h3>
              </div>
            </div>
            <div class="video-list">
              <div
                class="vid"
                v-for="video in conceptVideo"
                :key="video.id"
                :class="{ active: currentVideoTitle === video.title }"
                :style="
                  currentVideoTitle === video.title && isPlaylistActive
                    ? `background-color: ${backgroundColor}`
                    : '#f9a825'
                "
                @click="
                  currentVideoUrl = video.url;
                  currentVideoTitle = video.title;
                "
              >
                <!-- <vimeo-player :video-url="video.url" frameborder="0" :player-width="280" :player-height="140"></vimeo-player> -->
                <iframe
                  referrerpolicy="origin"
                  v-if="video.url.includes('vimeo')"
                  :src="video.url"
                  frameborder="0"
                  width="280"
                  height="140"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h3 class="title">{{ video.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="example-video learn-video" v-if="selectedTab === 2">
        <div class="row">
          <div class="col s12 video-content-box-1" v-if="mainGallery">
            <div class="video-list-container">
              <div
                class="video-list"
                style="padding: 5px"
                v-for="video in exampleVideo"
                :key="video.id"
                @click="videoTrigger(video.url, video.title)"
                :class="`sub-index-${selectedSubjectIndex}`"
              >
                <!-- <vimeo-player v-if="video.url.includes('vimeo')" :video-url="video.url" frameborder="0" :player-width="250" :player-height="110"></vimeo-player> -->
                <iframe
                  referrerpolicy="origin"
                  v-if="video.url.includes('vimeo')"
                  :src="video.url"
                  frameborder="0"
                  width="250"
                  height="110"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <video
                  v-if="video.url.includes('dataimagestorage')"
                  controls="true"
                  controlslist="nodownload"
                  width="250"
                  height="110"
                  loop="true"
                >
                  <source :src="video.url" type="video/mp4" />
                </video>
                <iframe
                  v-if="video.url.includes('youtube')"
                  width="250"
                  height="110"
                  :src="video.url"
                >
                </iframe>
                <p class="list-title">{{ video.title }}</p>
              </div>
            </div>
          </div>
          <div class="video-gallery" v-if="openCurrentVideo">
            <div class="main-video">
              <div class="video wrapper">
                <div class="h_iframe">
                  <!-- <vimeo-player v-if="currentVideoUrl.includes('vimeo')" :video-url="currentVideoUrl" frameborder="0" allow=" fullscreen; picture-in-picture" :player-height="(playerWidth/2)" :player-width="playerWidth" allowfullscreen></vimeo-player> -->
                  <iframe
                    referrerpolicy="origin"
                    v-if="currentVideoUrl.includes('vimeo')"
                    :src="currentVideoUrl"
                    frameborder="0"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <video
                    v-if="currentVideoUrl.includes('dataimagestorage')"
                    controls="true"
                    controlslist="nodownload"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    loop="true"
                  >
                    <source :src="currentVideoUrl" type="video/mp4" />
                  </video>
                  <iframe
                    v-if="currentVideoUrl.includes('youtube')"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    :src="currentVideoUrl"
                  >
                  </iframe>
                </div>
                <h3 class="title">{{ currentVideoTitle }}</h3>
              </div>
            </div>
            <div class="video-list">
              <div
                class="vid"
                v-for="video in exampleVideo"
                :key="video.id"
                :class="{ active: currentVideoTitle === video.title }"
                :style="
                  currentVideoTitle === video.title && isPlaylistActive
                    ? `background-color: ${backgroundColor}`
                    : '#f9a825'
                "
                @click="
                  currentVideoUrl = video.url;
                  currentVideoTitle = video.title;
                "
              >
                <!-- <vimeo-player v-if="video.url.includes('vimeo')" :video-url="video.url" frameborder="0" :player-width="280" :player-height="140"></vimeo-player> -->
                <iframe
                  referrerpolicy="origin"
                  v-if="video.url.includes('vimeo')"
                  :src="video.url"
                  frameborder="0"
                  width="280"
                  height="140"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <video
                  v-if="video.url.includes('dataimagestorage')"
                  controls="true"
                  controlslist="nodownload"
                  width="280"
                  height="140"
                  loop="true"
                >
                  <source :src="video.url" type="video/mp4" />
                </video>
                <iframe
                  v-if="video.url.includes('youtube')"
                  width="280"
                  height="140"
                  :src="video.url"
                >
                </iframe>
                <h3 class="title">{{ video.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="institute-video learn-video" v-if="selectedTab === 3">
        <div class="row">
          <div class="col s12 video-content-box-1" v-if="mainGallery">
            <div class="video-list-container">
              <div
                class="video-list"
                style="padding: 5px"
                v-for="video in instituteVideo"
                :key="video.id"
                @click="videoTrigger(video.url, video.title)"
                :class="`sub-index-${selectedSubjectIndex}`"
              >
                <!-- <vimeo-player v-if="video.url.includes('vimeo')" :video-url="video.url" frameborder="0" :player-width="250" :player-height="110"></vimeo-player> -->
                <iframe
                  referrerpolicy="origin"
                  v-if="video.url.includes('vimeo')"
                  :src="video.url"
                  frameborder="0"
                  width="250"
                  height="110"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <video
                  v-if="video.url.includes('dataimagestorage')"
                  controls="true"
                  controlslist="nodownload"
                  width="250"
                  height="110"
                  loop="true"
                >
                  <source :src="video.url" type="video/mp4" />
                </video>
                <iframe
                  v-if="video.url.includes('youtube')"
                  width="250"
                  height="110"
                  :src="video.url"
                >
                </iframe>
                <p class="list-title">{{ video.title }}</p>
              </div>
            </div>
          </div>
          <div class="video-gallery" v-if="openCurrentVideo">
            <div class="main-video">
              <div class="video wrapper">
                <div class="h_iframe">
                  <!-- <vimeo-player v-if="currentVideoUrl.includes('vimeo')" :video-url="currentVideoUrl" frameborder="0"  allow=" fullscreen; picture-in-picture" allowfullscreen :player-height="(playerWidth/2)" :player-width="playerWidth"></vimeo-player> -->
                  <iframe
                    referrerpolicy="origin"
                    v-if="currentVideoUrl.includes('vimeo')"
                    :src="currentVideoUrl"
                    frameborder="0"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <video
                    v-if="currentVideoUrl.includes('dataimagestorage')"
                    controls="true"
                    controlslist="nodownload"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    loop="true"
                  >
                    <source :src="currentVideoUrl" type="video/mp4" />
                  </video>
                  <iframe
                    v-if="currentVideoUrl.includes('youtube')"
                    :width="playerWidth"
                    :height="playerWidth / 2"
                    :src="currentVideoUrl"
                  >
                  </iframe>
                </div>
                <h3 class="title">{{ currentVideoTitle }}</h3>
              </div>
            </div>
            <div class="video-list">
              <div
                class="vid"
                v-for="video in instituteVideo"
                :key="video.id"
                :class="{ active: currentVideoTitle === video.title }"
                :style="
                  currentVideoTitle === video.title && isPlaylistActive
                    ? `background-color: ${backgroundColor}`
                    : '#f9a825'
                "
                @click="
                  currentVideoUrl = video.url;
                  currentVideoTitle = video.title;
                "
              >
                <!-- <vimeo-player v-if="video.url.includes('vimeo')" :video-url="video.url" frameborder="0" :player-width="280" :player-height="140"></vimeo-player> -->
                <iframe
                  referrerpolicy="origin"
                  v-if="video.url.includes('vimeo')"
                  :src="video.url"
                  frameborder="0"
                  width="280"
                  height="140"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <video
                  v-if="video.url.includes('dataimagestorage')"
                  controls="true"
                  controlslist="nodownload"
                  width="280"
                  height="140"
                  loop="true"
                >
                  <source :src="video.url" type="video/mp4" />
                </video>
                <iframe
                  v-if="video.url.includes('youtube')"
                  width="280"
                  height="140"
                  :src="video.url"
                >
                </iframe>
                <h3 class="title">{{ video.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnHeader from "./LearnHeader.vue";
import Doubtapi from "../Doubtapi";

export default {
  name: "LearnVideos",
  data() {
    return {
      userDetail: "",
      chapterData: "",
      chapterId: null,
      courseId: null,
      selectedTab: 1,
      conceptVideo: "",
      exampleVideo: "",
      instituteVideo: "",
      currentVideoUrl: "",
      currentVideo: {
        url: "",
      },
      currentVideoTitle: "",
      mainGallery: true,
      openCurrentVideo: false,
      subjectChange: false,
      backgroundColor: "",
      isPlaylistActive: false,
      selectedSubjectIndex: "",
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    currentVideoUrl(newUrl) {
      this.currentVideo.url = newUrl;
    },
  },
  components: {
    LearnHeader,
  },
  computed: {
    playerWidth() {
      const screenWidth = window.innerWidth;
      const maxPlayerWidth = 840;
      return Math.min(screenWidth - 40, maxPlayerWidth);
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    getUserDetail(user) {
      this.user = user;
    },
    getSubjectIndex(subjectIndex) {
      this.selectedSubjectIndex = subjectIndex;
    },
    getChapterData(chapterdata) {
      this.chapterData = chapterdata;
      this.chapterId = this.chapterData.ChapterId;
      this.courseId = this.chapterData.CourseId;
      this.conceptVideo = "";
      this.exampleVideo = "";
      this.subjectChange = false;
      this.$store.dispatch("showLoader", true);
      Doubtapi.getConceptVideos(
        {
          chapterId: this.chapterId,
          courseId: this.courseId,
        },
        (response) => {
          this.conceptVideo = response.data ? response.data : [];
          this.$store.dispatch("showLoader", false);
          if (this.selectedTab === 1) {
            if (this.openCurrentVideo === true) {
              this.subjectChange = true;
              if (this.conceptVideo[0].url !== "") {
                this.currentVideoUrl = this.conceptVideo[0].url;
              }
              if (this.conceptVideo[0].title !== "") {
                this.currentVideoTitle = this.conceptVideo[0].title;
              }
            }
          }
        },
      );

      this.$store.dispatch("showLoader", true);
      Doubtapi.getExampleVideos(
        {
          chapterId: this.chapterId,
          courseId: this.courseId,
        },
        (response) => {
          this.exampleVideo = response.data ? response.data : [];
          this.$store.dispatch("showLoader", false);
          if (this.selectedTab === 2) {
            if (this.openCurrentVideo === true) {
              this.subjectChange = true;
              if (this.exampleVideo[0].url !== "") {
                this.currentVideoUrl = this.exampleVideo[0].url;
              }
              if (this.exampleVideo[0].title !== "") {
                this.currentVideoTitle = this.exampleVideo[0].title;
              }
            }
          }
        },
      );

      this.$store.dispatch("showLoader", true);
      Doubtapi.getInstituteVideos(
        {
          chapterId: this.chapterId,
          courseId: this.courseId,
          affiliationId: this.user.AffiliationId,
        },
        (response) => {
          this.instituteVideo = response.data ? response.data : [];
          this.$store.dispatch("showLoader", false);
          if (this.selectedTab === 3) {
            if (this.openCurrentVideo === true) {
              this.subjectChange = true;
              if (this.instituteVideo[0].url !== "") {
                this.currentVideoUrl = this.instituteVideo[0].url;
              }
              if (this.instituteVideo[0].title !== "") {
                this.currentVideoTitle = this.instituteVideo[0].title;
              }
            }
          }
        },
      );
    },
    selectedConceptTab() {
      if (this.selectedTab !== 1) {
        this.selectedTab = 1;
      }
      if (this.currentVideoUrl !== "") {
        this.currentVideoUrl = "";
      }
      if (this.currentVideoTitle !== "") {
        this.currentVideoTitle = "";
      }
      if (this.mainGallery === false) {
        this.mainGallery = true;
      }
      if (this.openCurrentVideo === true) {
        this.openCurrentVideo = false;
      }
      if (this.subjectChange === true) {
        this.subjectChange = false;
      }
    },
    selectedExampleTab() {
      if (this.selectedTab !== 2) {
        this.selectedTab = 2;
      }
      if (this.currentVideoUrl !== "") {
        this.currentVideoUrl = "";
      }
      if (this.currentVideoTitle !== "") {
        this.currentVideoTitle = "";
      }
      if (this.mainGallery === false) {
        this.mainGallery = true;
      }
      if (this.openCurrentVideo === true) {
        this.openCurrentVideo = false;
      }
      if (this.subjectChange === true) {
        this.subjectChange = false;
      }
    },
    selectedInstituteTab() {
      if (this.selectedTab !== 3) {
        this.selectedTab = 3;
      }
      if (this.currentVideoUrl !== "") {
        this.currentVideoUrl = "";
      }
      if (this.currentVideoTitle !== "") {
        this.currentVideoTitle = "";
      }
      if (this.mainGallery === false) {
        this.mainGallery = true;
      }
      if (this.openCurrentVideo === true) {
        this.openCurrentVideo = false;
      }
      if (this.subjectChange === true) {
        this.subjectChange = false;
      }
    },
    videoTrigger(url, title) {
      this.currentVideoUrl = url;
      this.currentVideoTitle = title;
      this.mainGallery = false;
      this.openCurrentVideo = true;
      this.isPlaylistActive = true;
    },
    gridListView() {
      if (this.mainGallery === true) {
        this.mainGallery = false;
      } else {
        this.mainGallery = true;
      }
      if (this.openCurrentVideo === true) {
        this.openCurrentVideo = false;
      } else {
        this.openCurrentVideo = true;
      }
      if (this.selectedTab === 1) {
        if (this.openCurrentVideo === true) {
          this.subjectChange = true;
          if (this.conceptVideo[0].url !== "") {
            this.currentVideoUrl = this.conceptVideo[0].url;
          }
          if (this.conceptVideo[0].title !== "") {
            this.currentVideoTitle = this.conceptVideo[0].title;
          }
        }
      }
      if (this.selectedTab === 2) {
        if (this.openCurrentVideo === true) {
          if (this.exampleVideo[0].url !== "") {
            this.currentVideoUrl = this.exampleVideo[0].url;
          }
          if (this.exampleVideo[0].title !== "") {
            this.currentVideoTitle = this.exampleVideo[0].title;
          }
        }
      }
      if (this.selectedTab === 3) {
        if (this.openCurrentVideo === true) {
          if (this.instituteVideo[0].url !== "") {
            this.currentVideoUrl = this.instituteVideo[0].url;
          }
          if (this.instituteVideo[0].title !== "") {
            this.currentVideoTitle = this.instituteVideo[0].title;
          }
        }
      }
    },
    getBackgroundColor(bgcolor) {
      this.backgroundColor = bgcolor;
    },
  },
};
</script>

<style scoped>
.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.video-tabs {
  background-color: transparent;
  width: fit-content;
  margin: 0;

  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 17px;
  list-style: none;
}

.content-header .grid-list-icon {
  width: fit-content;
  position: relative;
  top: 11px;
  left: -15px;
}

.content-header .grid-list-icon .material-icons {
  cursor: pointer;
}

.video-tabs .video-tab {
  height: auto;
  text-align: left;
  line-height: unset;
  line-height: 30px;
}

.video-tabs .video-tab a {
  margin: 0px 40px 0 0;
  padding: 0;
  color: rgba(29, 30, 30, 0.5);
  text-align: left;
  position: relative;
  font-weight: normal;
}

.video-tabs .video-tab a.active {
  color: #000;
}

.video-tabs .video-tab a.active::after {
  background: #584f52;
  width: 10px;
}

.video-tabs .video-tab a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  z-index: 9;
  background: rgba(29, 30, 30, 0.5);
  transition: all 0.3s ease;
}

.video-tabs .video-tab a:hover::after {
  width: 10px;
}

.learn-video {
  margin-top: 15px;
}

.video-content-box-1.col {
  padding: 0;
  height: calc(100vh - 319px);
}

.video-content-box-2.col {
  padding-right: 0;
}

.video-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  max-height: 60vh;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: 0 0px -2px rgb(0 0 0 / 10%);
  background-color: #fff;
  padding: 1px;
  margin-bottom: 15px;
}

.video-list-container::-webkit-scrollbar {
  width: 7px;
}

.video-list-container::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.video-list-container::-webkit-scrollbar-thumb {
  background-color: #6a2f85;
  border-radius: 5px;
}

.video-list-container .video-list {
  display: flex;
  align-items: center;
  /* height: 120px; */
  gap: 15px;
  padding-right: 5px;
  background-color: #e3e3e3;
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
  transition: all 0.4s ease-in;
}

.video-list-container .video-list:hover .list-title {
  color: #fff;
}

/* Video List Hover */
.video-list-container .video-list:hover {
  background: #f9a825;
}
.video-list-container .video-list.sub-index-1:hover {
  background-color: #f9a825;
  color: #fff;
}
.video-list-container .video-list.sub-index-2:hover {
  background-color: #4cb4aa;
  color: #fff;
}
.video-list-container .video-list.sub-index-3:hover {
  background-color: #64b5f6;
  color: #fff;
}
.video-list-container .video-list.sub-index-4:hover {
  background-color: #e47272;
  color: #fff;
}
.video-list-container .video-list.sub-index-5:hover {
  background-color: #7885ca;
  color: #fff;
}
.video-list-container .video-list.sub-index-6:hover {
  background-color: #ffd54f;
  color: #fff;
}
.video-list-container .video-list.sub-index-7:hover {
  background-color: #4ccfe0;
  color: #fff;
}

.video-list-container .video-list:last-child {
  margin-bottom: 0;
}

.video-list-container .video-list .list-video {
  width: 40%;
  border-radius: 5px;
  height: 110px;
}

.video-list-container .video-list .list-title {
  font-size: 17px;
  color: #444;
  font-weight: bold;
  transition: all 0.4s ease-in;
}

.video-gallery {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 15px;
  align-items: flex-start;
}

.video-gallery .main-video {
  border-radius: 5px;
}

.video-gallery .main-video .vp-center {
  justify-content: flex-start !important;
}

.video-gallery .main-video .video iframe {
  width: 100%;
  border-radius: 5px;
}

.video-gallery .main-video .video video {
  width: 100%;
  border-radius: 5px;
}

.video-gallery .main-video .title {
  color: #303030;
  font-size: 20px;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  margin: 10px 0;
}

.video-gallery .video-list {
  background-color: #fff;
  border-radius: 5px;
  max-height: 80vh;
  width: 100%;
  overflow-y: scroll;
}

.video-gallery .video-list::-webkit-scrollbar {
  width: 7px;
}

.video-gallery .video-list::-webkit-scrollbar-track {
  background-color: rgb(237, 233, 233);
  border-radius: 5px;
}

.video-gallery .video-list::-webkit-scrollbar-thumb {
  background-color: #6a2f85;
  border-radius: 5px;
}

.video-gallery .video-list .vid {
  display: flex;
  background: #f7f7f7;
  border-radius: 5px;
  margin: 5px;
  text-align: left;
  flex-direction: column;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 10px;
}

.video-gallery .video-list .vid:hover {
  background-color: #e1e1e1;
}

.video-gallery .video-list .vid.active {
  background-color: #efa721;
}

.video-gallery .video-list .vid.active .title {
  color: #fff;
}

.video-gallery .video-list .vid .title {
  color: #333;
  font-size: 15px;
  margin: 2px 0;
}

.video-gallery .video-list .vid video {
  width: 100%;
  border-radius: 5px;
}

/* .h_iframe {
    position: relative;
    padding-top: 56%;
}

.h_iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

@media screen and (max-width: 992px) {
  .video-tabs {
    padding: 0 0.75rem;
  }

  .video-content-box-1.col {
    padding: 0 0.75rem;
    margin-bottom: 10px;
  }

  .video-content-box-2.col {
    padding: 0 0.75rem;
  }

  .video-gallery {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .video-gallery {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .video-list-container {
    grid-template-columns: 1fr;
  }

  .video-gallery .video-list .vid {
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .video-list-container .video-list {
    padding-left: 5px;
    flex-direction: column;
  }
}
</style>
